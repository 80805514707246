import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Service } from '../../Service/ApiService';
import { CommonService } from '../../Service/CommonService';
import Spinner from '../Common/Spinner';
import RestoreFromTrashOutlinedIcon from "@mui/icons-material/RestoreFromTrashOutlined";

interface ChildProps {
    openPopUp: boolean;
    setOpenPopUp: (value: boolean) => void;
    restoreUser: any;
    reloadTable: any;
    selectionModel: any;
    isSingleClickMode: boolean;
}

const RestoreUser: React.FC<ChildProps> = ({ openPopUp, restoreUser, reloadTable, setOpenPopUp, selectionModel, isSingleClickMode }) => {
    const [show, setShow] = useState(false);
    const [selectedIds, setSelectedIds] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isSingleClickMode) {
            setSelectedIds(restoreUser.map((user: { id: any }) => user.id));
        } else {
            setSelectedIds(selectionModel.map((user: { id: any }) => user.id));
        }
    }, [restoreUser, selectionModel, isSingleClickMode]);

    useEffect(() => {
        setShow(openPopUp);
    }, [openPopUp]);

    const handleClose = () => {
        setShow(false);
        setOpenPopUp(false);
    };

    const handleSubmit = () => {
        setIsLoading(true);
        Service.removeUserProfile(selectedIds, false)
            .then(() => {
                setIsLoading(false);
                CommonService.Toast.fire({
                    title: "User Restored successfully!",
                    icon: 'success',
                });
                reloadTable();
            })
            .catch(error => {
                setIsLoading(false);
                console.log(error);
            });

        handleClose();
    };

    const userCount = isSingleClickMode ? restoreUser.length : selectionModel.length;
    const userList = isSingleClickMode ? restoreUser : selectionModel;

    return (
        <>
            <button onClick={() => setShow(true)} className="white-outline-btn d-inline-flex justify-content-between align-items-center ms-1">
                <RestoreFromTrashOutlinedIcon sx={{ me: 2, color: "#1010104D" }} />
                <button disabled={userCount === 0} style={{ border: 'none', background: 'transparent', cursor: userCount === 0 ? 'not-allowed' : 'pointer' }}>
                    Restore
                </button>
                <span className="review--number text-secondary d-inline-flex align-items-center justify-content-center rounded-circle bg-white ms-2">
                {isSingleClickMode ? 0 : userCount}
                </span>
            </button>

            <Modal show={show} onHide={handleClose} className='modal__wd modal__wd--submitSellReq'>
                <Modal.Header className='flex-column align-items-start'>
                    <Modal.Title>Restore {userCount > 1 ? "Users" : "User"}</Modal.Title>
                    <p className="modal-text">Are you sure you want to restore this {userCount > 1 ? "users" : "user"}?</p>
                </Modal.Header>

                {/* Display selected user emails */}
                <Modal.Body style={{ backgroundColor: "#34404A", color: "white" }}>
                    {userCount > 0 ? (
                        <ul>
                            {userList.map((user: any, index: number) => (
                                <li key={index}>{user.email}</li>
                            ))}
                        </ul>
                    ) : (
                        <p>No users selected.</p>
                    )}
                </Modal.Body>

                <Modal.Footer className='justify-content-center bg-transparent'>
                    <div className="btn-group row d-flex justify-content-between w-100">
                        <div className="col-6 ps-0">
                            <Button className="secondary-btn w-100" onClick={handleClose} disabled={isLoading}>
                                No
                            </Button>
                        </div>
                        <div className="col-6 pe-0">
                            <Button className="primary-btn w-100" onClick={handleSubmit} disabled={isLoading}>
                                {isLoading && <Spinner />} Yes
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default RestoreUser;
