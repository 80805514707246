import { ListFormat } from 'typescript';
import AxiosApiService from './AxiosService';
import { Url } from '../Constants/Url';

export const MessageApiService = {
    getActiveMessagesByRequestType,saveMessage,getGroupsMessage, getAdminMessageGroups
}

async function getActiveMessagesByRequestType(params: { requestId: string; requestType: string,hasSeller: boolean, sellerCompanyId:String; }) {
    return await AxiosApiService.getWithParams<Object>(Url.GET_ACTIVE_MESSAGES_BY_TYPE, {
        params: {
            requestId: params.requestId,
            requestType: params.requestType,
            hasSeller: params.hasSeller,
            sellerCompanyId: params.sellerCompanyId,
        }
    });
}

async function saveMessage(message: any) {
    return await AxiosApiService.post<any>(Url.SAVE_MESSAGES, message);
}

async function getGroupsMessage(tab: any) {
    return await AxiosApiService.get<any>(Url.GET_GROUP_MESSAGES + '/' + tab);
}

async function getAdminMessageGroups(requestId: string, requestType: string) {
    return await AxiosApiService.getWithParams<any>(Url.GET_ADMIN_MESSAGE_GROUP, {
        params: {
            requestId: requestId,
            requestType: requestType
        }
    });
}