import React, { createContext, useContext, useEffect, useState } from 'react';
import notificationWebSocketService from './notificationWebSocketService';

const WebSocketContext = createContext();

export const NotificationProvider = ({ children, userId }) => {
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if (userId) {
      notificationWebSocketService.connect(userId);
      setConnected(true);

      return () => {
        notificationWebSocketService.disconnect();
        setConnected(false);
      };
    }
  }, [userId]);

  return (
    <WebSocketContext.Provider value={{ connected, notificationWebSocketService }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useNotificationWebSocket = () => useContext(WebSocketContext);

