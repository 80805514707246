import React, { useEffect, useRef, useState } from "react";
import { Service } from "../../Service/ApiService";
import "../Dynamic/CompanyDashboard.scss";
import CompanyIcn from "../../assets/images/icons/Company.svg";
// import InventoryIcn from "../../assets/images/icons/Inventory.svg";
import InventoryIcn from "../../assets/images/icons/InventoryNew.svg";
import TotalBarrels from "../../assets/images/icons/TotalBarrels.svg";
// import UsersIcn from "../../assets/images/icons/Users.svg";
import UsersIcn from "../../assets/images/icons/UsersNew.svg";
import ReservedBarrelIcn from "../../assets/images/icons/Reserved_Barrels.svg";
// import OrderReqIcn from "../../assets/images/icons/Order_Request.svg";
import OrderReqIcn from "../../assets/images/icons/Order_Request_New.svg";
import WishListIcon from "../../assets/images/icons/WishList.svg";
import OrderValueIcn from "../../assets/images/icons/Order_Value.svg";
// import OfferReqIcn from "../../assets/images/icons/Offer_Request.svg";
import OfferReqIcn from "../../assets/images/icons/Offer_Request_New.svg";
// import SampleReqIcn from "../../assets/images/icons/Sample_Request.svg";
import SampleReqIcn from "../../assets/images/icons/Sample_Request_New.svg";
// import SellReqIcn from "../../assets/images/icons/Sell_Request.svg";
import SellReqIcn from "../../assets/images/icons/Sell_Request_New.svg";
import CompanyStoreIcon from "../../assets/images/icons/CompanyStoreIcon.svg";
import ApproveCart from "../../assets/images/icons/ApproveCart.svg";
import AvailableBarrels from "../../assets/images/icons/AvailabelForSell.svg";
import RejectCart from "../../assets/images/icons/RejectCart.svg";
import NotAvailableBarrels from "../../assets/images/icons/NotAvailableForSale.svg";
import Info from "../../assets/images/icons/Info.svg";
import User from "../../assets/images/icons/User.svg";
import DeviceMobileCamera from "../../assets/images/icons/DeviceMobileCamera.svg";
import EnvelopeSimpleOpen from "../../assets/images/icons/EnvelopeSimpleOpen.svg";
import MapPin from "../../assets/images/icons/MapPin.svg";
import CalendarCheck from "../../assets/images/icons/CalendarCheck.svg";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import Cookies from "universal-cookie";
import { Button, Dropdown } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import RecentRequest from "./RecentRequest";
import ListGroup from "react-bootstrap/ListGroup";
import UnreadMessage from "./UnreadMessage";
import { format } from "date-fns";
import { IconButton, Skeleton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useRoute } from "../Common/route";
import { DisplayGrid } from "./DisplayGrid";
import { OrderApiService } from "../../Service/OrderApiService";
import { InventoryApiService } from "../../Service/InventoryApiService";
import { CommonService } from "../../Service/CommonService";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { GridColDef, GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import StatusDropdown from "../Common/StatusDropdown";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import AlterImage from "../../assets/images/alter_image.png";
import MailOutlineSharpIcon from "@mui/icons-material/MailOutlineSharp";
import Constant from "../../Constant";
import { SupportApiService } from "../../Service/SupportApiService";

const WarehouseCompanyDashboard: React.FC = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const route = useRoute();
  const history = useHistory();
  const [openRequestCount, setOpenRequestCount] = useState<any>({});
  const { id } = useParams<{ id: string }>();
  const userName = new Cookies().get("userName");
  const [companyDetails, setCompanyDetails] = useState<any>({});
  const [formattedDate, setFormattedDate] = useState<string>("");
  const [tableData, setTableData] = useState<any[]>([]);
  const [title, setTitle] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  let columns: GridColDef[] = [];
  const [activeTab, setActiveTab] = useState("");
  const displayGridRef = useRef<HTMLDivElement>(null);
  const params = id === undefined ? CommonService.getCompanyId() : id;
  const [sellRequestCount, setSellRequestCount] = useState();
  const [isTableFetched, setIsTableFetched] = useState<Boolean>(false);
  const [totalCount, setTotalCount] = useState(0);
  const [requestType, setRequestType] = useState("");
  const [isOpenRequestLoading, setIsOpenRequestLoading] = useState(false);
  const [sortField, setSortField] = useState<string>("");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  // Message Box 
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  // const handleIconClick = () => {
  //   setIsMessageBoxVisible((prev) => !prev); // Toggle visibility
  // };
  const handleValueChange = (isVisible: boolean) => {
    setIsMessageBoxVisible(isVisible); // Update parent state with the value from the child
    console.log("Message box visibility:", isVisible);
  };

  const [filter, setFilter] = useState<ServiceRequestSearchFilter>({
      name: null,
      status: null,
      createdDate: null,
      requestNumber: null,
      requestType: null,
      companyName: null
    });

  const handleScrollToGrid = () => {
    if (displayGridRef.current) {
      displayGridRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  const fetchCompanysOpenRequestCountByCompanyId = async () => {
    setIsOpenRequestLoading(true);
    const response: any =
      await Service.fetchCompaniesOpenRequestCountByCompanyId(
        params,
        CommonService.getDashboardPrefrence()
      );
    setOpenRequestCount(response.data);
    setIsOpenRequestLoading(false);
  };

  const fetchCompanyById = async () => {
    const response: any = await Service.fetchCompanyById(params);
    setCompanyDetails(response.data);
    setFormattedDate(
      format(new Date(response.data.createdDate), "MMMM dd, yyyy")
    );
  };

  useEffect(() => {
    fetchCompanysOpenRequestCountByCompanyId();
    fetchCompanyById();
    //loadData("order");
    setIsMessageBoxVisible(false);
    console.log("inside use effect", isMessageBoxVisible);
  }, [location.pathname]);

  //add tooltip
  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  const toTitleCase = (str: string) => {
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };


    const handleSellRequestRoute = (routePath: string) => {
      if (CommonService.isAdmin()) {
          history.push(`/master/${routePath}`, {
              data: "Sell Request"  // Your data here
          });
      } else {
          history.push(`/user/${routePath}`, {
              data: "Sell Request"  // Your data here
          });
      }
  };

  const loadData = async (value: any, page: any, size: any, sortField: any, sortDirection: any) => {
    // setRequestType(value);
    // Reset data and fetch fresh data when request type changes
    setTableData([]);
    setTotalCount(0);

    try {
      columns = [];
      if (value === "user") users(page, size, sortField, sortDirection);
      else if (value === "order") openOrderRequests(page, size, sortField, sortDirection);
      else if (value === "sell") openSellRequests(page, size, sortField, sortDirection);
      else if (value === "sample") openSampleRequests(page, size, sortField, sortDirection);
      else if (value === "offer") openOfferRequests(page, size, sortField, sortDirection);
      else if (value === "inventory_change") openInventoryChangeRequests();
      else if (value === "inventory-listing") inventoryListing(page, size, sortField, sortDirection);
      else if(value === "wish-list") fetchWishListDetails(page, size);
      else if (
        value === "inventory" ||
        value === "available-for-sell" ||
        value === "not-available-for-sell" 
      )
        inventories(page, size, value, sortField, sortDirection);

      setRequestType(value);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchWishListDetails = async (page:any, size:any) => {

    try {
      setIsLoading(true);
      const response = await OrderApiService.getWishListDetails(CommonService.getUserId(), page, size);
      setTableData(Object.values(response.data.content)); // Convert object to array
      setTotalCount(response.data.totalElements);
      setIsTableFetched(true);
      setTitle("Wish_list");
      setActiveTab("wish-list");
      setIsLoading(false);
      handleScrollToGrid();

    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }

  }

  const users = async (page: any, size: any, sortField: any, sortDirection: any) => {
    try {
      setIsLoading(true);
      const response: any = await Service.getAllApprovedUsers(
        CommonService.isUser()
          ? CommonService.getCompanyId()
          : currentPath.includes("/master/company-dashboard")
            ? id
            : null,
        page,
        size,
        {},
        sortField, 
        sortDirection,
        null
      );

      setTableData(Object.values(response.data.content)); // Convert object to array
      setTotalCount(response.data.totalElements);
      setIsTableFetched(true);
      setTitle("Users");
      setActiveTab("user");
      setIsLoading(false);
      handleScrollToGrid();
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const openOrderRequests = async (page: any, size: any, sortField: any, sortDirection: any) => {
    try {
      setIsLoading(true);
      const response: any =
        await OrderApiService.getCompanyWiseOrderRequestsPagination(
          params, true, CommonService.getDashboardPrefrence(), page, size, {}, sortField, sortDirection, null);

      setTableData(Object.values(response.data.content)); // Convert object to array
      setTotalCount(response.data.totalElements);
      setIsTableFetched(true);
      setTitle("Open_order_requests");
      setActiveTab("order");
      setIsLoading(false);
      handleScrollToGrid();
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const openSellRequests = async (page: any, size: any, sortField: any, sortDirection: any) => {
    try {

      const updatedFilter = { ...filter, requestType: "Sell Request" }; // Default filter always applied

      setIsLoading(true);
            const response: any = await SupportApiService.getAllServiceRequests(
              CommonService.isAdmin() ? id : CommonService.getCompanyId(),
              page,
              size,
              updatedFilter,
              sortField, 
              sortDirection
            );

      setTableData(Object.values(response.data.content)); // Convert object to array
      setTotalCount(response.data.totalElements);
      setIsTableFetched(true);
      setTitle("Open_sell_requests");
      setActiveTab("sell");
      setIsLoading(false);
      handleScrollToGrid();
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const openSampleRequests = async (page: any, size: any, sortField: any, sortDirection: any) => {
    try {
      setIsLoading(true); // Set the loading flag to true before the API call
      const response = await OrderApiService.getCompanyWiseSampleRequests(
        params,
        true,
        CommonService.getDashboardPrefrence(),
        page,
        size,
        {},
        sortField, sortDirection,
        null
      ); // Applies the status filter only if isFetchOpenRequests is true;

      setTableData(Object.values(response.data.content)); // Convert object to array

      //also set the total count
      setTotalCount(response.data.totalElements);

      setIsTableFetched(true);
      setTitle("Open_sample_requests");
      setActiveTab("sample");
      setIsLoading(false);
      handleScrollToGrid();
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const openOfferRequests = async (page: any, size: any, sortField: any, sortDirection: any) => {
    try {
      setIsLoading(true);
      const response = await OrderApiService.getCompanyWiseOfferRequests(
        params,
        true,
        CommonService.getDashboardPrefrence(),
        page,
        size,
        {},
        sortField, sortDirection,
        null,
      ); // Applies the status filter only if isFetchOpenRequests is true;

      setTableData(Object.values(response.data.content)); // Convert object to array
      setTotalCount(response.data.totalElements);
      setIsTableFetched(true);
      setTitle("Open_offer_requests");
      setActiveTab("offer");
      setIsLoading(false);
      handleScrollToGrid();
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const openInventoryChangeRequests = async () => {
    try {
      setIsLoading(true);
      const response: any =
        await InventoryApiService.getInventoryChangeRequests(params, true);

      setTableData(Object.values(response.data)); // Convert object to array
      setIsTableFetched(true);
      setTitle("Open_inventory_change_requests");
      setActiveTab("inventory_change");
      setIsLoading(false);
      handleScrollToGrid();
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const inventoryListing = async (page: any, size: any, sortField: any, sortDirection: any) => {
    try {
      setIsLoading(true); // Set the loading flag to true before the API call
      const response: any = await InventoryApiService.getActiveInventoryListings(params, page, size, {}, sortField, sortDirection, null);

      const processedData = response.data.content.map((item, index) => ({
        ...item,
        id: item.id || `${item.title}-${index}`, // Use existing ID or create a unique one
      }));
      setTableData(processedData);
      setTotalCount(response.data.totalElements);setIsTableFetched(true);
      setTitle("inventory_listing");
      setActiveTab("inventory-listing");
      setIsLoading(false);
      handleScrollToGrid();
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const inventories = async (page: any, size: any, value: any, sortField: any, sortDirection: any) => {
    try {
      setIsLoading(true);
      const response: any = await InventoryApiService.getMyInventories(null, params, value, page, size, {}, sortField, sortDirection, null, 'barrels');
      switch (value) {
        case "inventory":
          setTitle("Barrels");
          setActiveTab("inventories");
          break;

        case "available-for-sell":
          setTitle("Available_for_sell");
          setActiveTab("available-for-sell");
          break;

        case "not-available-for-sell":
          setTitle("Not_available_for_sell");
          setActiveTab("not-available-for-sell");
          break;

        case "wish-list":
            setTitle("Wish_list");
            setActiveTab("wish-list");
            break;
          
        default:
          // Handle other cases if needed
          break;
      }

      if (response.data?.content) {
        const formattedData = response.data.content.map((row: any, index: number) => ({
          id: row.id || `temp-id-${index}`, // Ensure a unique ID
          ...row
        }));
  
        setTableData(Object.values(response.data.content)); // Convert object to array
        setTotalCount(response.data.totalElements);
        setIsTableFetched(true);
        setIsLoading(false);
      } else {
        setTableData([]); // Prevent old data from lingering
        setTotalCount(0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  // Handle pagination changes
  const handlePageChange = (newPaginationModel: GridPaginationModel) => {
    setPaginationModel(prev => ({
      ...prev,
      page: newPaginationModel.page,
      pageSize: newPaginationModel.pageSize
    }));
  
    // Maintain sorting state while changing pages
    if(requestType === "sample"){
      openSampleRequests(newPaginationModel.page, newPaginationModel.pageSize, sortField, sortDirection);
    } else if(requestType === "inventory" || requestType === "available-for-sell" || requestType === "not-available-for-sell") {
      inventories(newPaginationModel.page, newPaginationModel.pageSize, '', sortField, sortDirection);
    } else if(requestType === "inventory-listing") {
      inventoryListing(newPaginationModel.page, newPaginationModel.pageSize, sortField, sortDirection);
    } else if (requestType === "order") {
      openOrderRequests(newPaginationModel.page, newPaginationModel.pageSize, sortField, sortDirection);
    } else if (requestType === "offer") {
      openOfferRequests(newPaginationModel.page, newPaginationModel.pageSize, sortField, sortDirection);
    } else if (requestType === "user") {
      users(newPaginationModel.page, newPaginationModel.pageSize, sortField, sortDirection);
    } else if (requestType === "sell") {
      openSellRequests(newPaginationModel.page, newPaginationModel.pageSize, sortField, sortDirection);
    } 
  };
  
  const handleSortingChange = (sortModel: GridSortModel) => {

    if(!sortModel.length){
      return;
    }

    const newSortField = sortModel.length ? sortModel[0].field : null;
    const newSortDirection = sortModel.length ? sortModel[0].sort : null;

    setSortField(newSortField);
    setSortDirection(newSortDirection);

    // Call the API without resetting page
      if(requestType === "sample"){
        openSampleRequests(paginationModel.page, paginationModel.pageSize, newSortField, newSortDirection);
      } else if(requestType === "inventory" || requestType === "available-for-sell" || requestType === "not-available-for-sell") {
        inventories(paginationModel.page, paginationModel.pageSize, '', newSortField, newSortDirection);
      } else if(requestType === "inventory-listing") {
        inventoryListing(paginationModel.page, paginationModel.pageSize, newSortField, newSortDirection);
      } else if (requestType === "order") {
        openOrderRequests(paginationModel.page, paginationModel.pageSize, newSortField, newSortDirection);
      } else if (requestType === "offer") {
        openOfferRequests(paginationModel.page, paginationModel.pageSize, newSortField, newSortDirection);
      } else if (requestType === "user") {
        users(paginationModel.page, paginationModel.pageSize, newSortField, newSortDirection);
      } else if (requestType === "sell") {
        openSellRequests(paginationModel.page, paginationModel.pageSize, newSortField, newSortDirection);
      } 
  };


  const handleRoute = (routePath: string) => {
    if (CommonService.isAdmin()) {
      route(`/master/my-warehouse/${routePath}/` + params);
    } else {
      route(`/user/my-warehouse/${routePath}`);
    }
  };

  switch (title) {
    case "Users":
      columns.push(
        {
          field: "firstName",
          headerName: "User",
          flex: 1,
          minWidth:190,
          sortable: true,
          valueGetter: (value: any, row: any) =>
            `${row.firstName || ""} ${row.lastName || ""}`,
          headerClassName: "table-header",
          renderCell: (params: any) => (
            <div className="d-flex gap-2 align-items-center">
              {/* <div className="user-img-wrapper">
              <img src={AlterImage} alt="CompanyIcn" />
            </div> */}
              <span>
                <CellWithTooltip value={params.value} />
              </span>
            </div>
          ),
        },
        {
          field: "email",
          headerName: "Email",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          sortable: true,
          renderCell: (params) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "phoneNumber",
          headerName: "Phone",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          sortable: true,
          renderCell: (params) => <CellWithTooltip value={params.value} />,
        },
        ...(CommonService.isAdmin()
          ? [
            {
              field: "ecommCompanyName",
              headerName: "Company Name",
              headerClassName: "table-header",
              flex: 1,
              minWidth: 190,
              sortable: true,
              renderCell: (params) => (
                <CellWithTooltip value={params?.row?.ecommCompanyName} />
              ),
            }
          ]
          : []),
        {
          field: "location",
          headerName: "Location",
          flex: 1,
          minWidth:190,
          sortable: true,
          valueGetter: (value: any, row: any) => {
            const city = row?.city;
            const state = row?.state;
            const zipcode = row?.zipcode;
            const cityWithComma = city ? city + ", " : "";
            const stateWithSpace = state ? state + " " : "";
            return `${cityWithComma}${stateWithSpace}${zipcode || ""}`;
          },
          renderCell: (params) => <CellWithTooltip value={params.value} />,
          headerClassName: "table-header",
        }
      );
      break;

    case "Open_order_requests":
      columns.push(
        {
          field: "requestNumber",
          headerName: "Request Number",
          flex: 1,
          minWidth:190,
          sortable: true,
          headerClassName: "table-header",
          renderCell: (params: any) => {
            const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";

            return (
              <a href="#">
                <Link
                  to={`${linkPrefix}/order-detail/${params.row.id}`}
                  className="link"
                >
                  <CellWithTooltip value={params.row.requestNumber} />
                </Link>
              </a>
            );
          },
        },
        {
          field: "createdDate",
          headerName: "Requested On",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          sortable: true,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          valueGetter: (value: any, row: any) => {
            const dateTimeString = row.createdDate ? row.createdDate : null;

            if (dateTimeString) {
              //call the method which formate the time
              return CommonService.isUser() &&
                !CommonService.isSameCompany(row.companyId)
                ? CommonService.formatStrictDate(dateTimeString)
                : CommonService.formatDateTime(dateTimeString);
            }
          },
        },
        {
          field: "user",
          headerName: "Requested By",
          flex: 1,
          minWidth:190,
          sortable: true,
          valueGetter: (value: any, row: any) =>
            `${row.firstName || ""} ${row.lastName || ""}`,
          headerClassName: "table-header",
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "email",
          headerName: "Email",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          sortable: true,
          valueGetter: (value: any, row: any) => `${row.email || ""} `,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "quantity",
          headerName: "Total Quantity",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          sortable: true,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          valueGetter: (value: any, row: any) =>
            `${row.quantity || ""} barrels`,
        },
        ...(CommonService.isAdmin()
        ? [{
          field: "finalAmount",
          headerName: "Total Amount",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          sortable: true,
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.value}
              locale="en-US"
              currency="USD"
            />
          ),}]
        : []),
        {
          field: "status",
          headerName: "Status",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          sortable: true,
          renderCell: (params: any) => (
            <div>
              <StatusDropdown
                currentStatus={toTitleCase(params.row.status)}
                onStatusChange={(newStatus) => { }}
                disableCondition={true}
                allowedLabels={[]}
              />
              {params.row.status === "REJECTED" && (
                <Tooltip
                  title={`Rejection Note: ${params.row.rejectionNote}`}
                  arrow
                >
                  <StickyNote2Icon
                    className="note-icon cursor-pointer"
                    style={{ color: "gray" }}
                  />
                </Tooltip>
              )}
            </div>
          ),
        }
      );
      break;

    case "Open_sell_requests":
      columns.push(
        {
          field: "requestNumber",
          headerName: "Request Number",
          flex: 1,
          minWidth:190,
          sortable: true,
          headerClassName: "table-header",
          renderCell: (params: any) => {
            const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";

            return (
              <a href="#">
                <Link
                  to={`${linkPrefix}/view-customer-service-request/${params.row.id}`}
                  className="link"
                >
                  <CellWithTooltip value={params.row.requestNumber} />
                </Link>
              </a>
            );
          },
        },
        {
          field: "name",
          headerName: "Submitted By",
          flex: 1,
          minWidth:190,
          sortable: true,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          headerClassName: "table-header",
        },
        // {
        //   field: "ecommUser.email",
        //   headerName: "Email",
        //   headerClassName: "table-header",
        //   flex: 1,
        //   minWidth:190,
        //   valueGetter: (value: any, row: any) =>
        //     `${row.ecommUser.email || ""} `,
        //   renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        // },
        ...(CommonService.isAdmin()
        ? [
          {
            field: "companyName",
            headerName: "Company Name",
            headerClassName: "table-header",
            flex: 1,
            minWidth:190,
            sortable: true,
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
            
          }
        ]
        : []),
        {
          field: "createdDate",
          headerName: "Submitted On",
          flex: 1,
          minWidth:190,
          sortable: true,
          valueGetter: (value: any, row: any) => {
            const dateTimeString = row.createdDate ? row.createdDate : null;
            if (dateTimeString) {
              return CommonService.formatDateTime(dateTimeString);
            }
          },
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          headerClassName: "table-header",
        },
        // {
        //   field: "fileName",
        //   headerName: "Inventory File",
        //   headerClassName: "table-header",
        //   flex: 1,
        //   minWidth:190,
        //   renderCell: (params) => {
        //     const csvFileName = params.value;
        //     if (csvFileName) {
        //       const parts = csvFileName.split("_");
        //       const fileNameToShow = parts.slice(1).join("_");
        //       const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";

        //       return <CellWithTooltip value={fileNameToShow} />;
        //     } else {
        //       return "";
        //     }
        //   },
        // },
        // {
        //   field: "sellRequestName",
        //   headerName: "Name of Sell Request",
        //   headerClassName: "table-header",
        //   flex: 1,
        //   minWidth:190,
        //   renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        //   valueGetter: (value: any, row: any) =>
        //     `${row.sellRequestName || "-"} `,
        // },
        {
          field: "status",
          headerName: "Status",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          sortable: true,
          renderCell: (params: any) => (
            <div>
              <StatusDropdown
                currentStatus={toTitleCase(params.row.status)}
                onStatusChange={(newStatus) => {}}
                disableCondition={true}
                allowedLabels={[]}
              />
              {(params.row.status === "REJECTED" || params.row.status === "CANCELLED") && (
                <Tooltip
                  title={`Reason: ${params.row.cancellationReason}`}
                  arrow
                >
                  <StickyNote2Icon
                    className="note-icon cursor-pointer"
                    style={{ color: "gray" }}
                  />
                </Tooltip>
              )}
            </div>
          ),
        }
      );

      break;

    case "Open_sample_requests":
      columns.push(
        {
          field: "requestNumber",
          headerName: "Request Number",
          flex: 1,
          minWidth:190,
          sortable: true,
          valueGetter: (value: any, row: any) => `${row.requestNumber || "-"}`,
          headerClassName: "table-header",
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "inventoryName",
          headerName: "Inventory",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          sortable: true,
          renderCell: (params: any) => {
            if (params.value) {
              const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";

              return (
                <a href="#">
                  <Link
                    to={`${linkPrefix}/view-sample-request/${params.row.id}`}
                    className="link"
                  >
                    <CellWithTooltip value={params.value} />
                  </Link>
                </a>
              );
            } else {
              return "";
            }
          },
        },
        {
          field: "requestedOn",
          headerName: "Requested On",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          sortable: true,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          valueGetter: (value: any, row: any) => {
            const dateTimeString = row.createdDate ? row.createdDate : null;

            if (dateTimeString) {
              //call the method which formate the time
              return CommonService.isUser() &&
                !CommonService.isSameCompany(row.companyId)
                ? CommonService.formatStrictDate(dateTimeString)
                : CommonService.formatDateTime(dateTimeString);
            }
          },
        },
        {
          field: "requestedBy",
          headerName: "Requested By",
          flex: 1,
          minWidth:190,
          sortable: true,
          valueGetter: (value: any, row: any) =>
            `${row.firstName || ""} ${row.lastName || ""}`,
          headerClassName: "table-header",
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        ...(CommonService.isAdmin()
          ? [{
            field: "companyName",
            headerName: "Company Name",
            flex: 1,
            minWidth: 190,
            sortable: true,
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          }]
          : []),
        {
          field: "size",
          headerName: "Sample Size",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          sortable: true,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "quantity",
          headerName: "Total Quantity",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          sortable: true,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "purpose",
          headerName: "Purpose",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          sortable: true,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "status",
          headerName: "Status",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          sortable: true,
          renderCell: (params: any) => (
            <div>
              <StatusDropdown
                currentStatus={toTitleCase(params.row.status)}
                onStatusChange={(newStatus) => { }}
                disableCondition={true}
                allowedLabels={[]}
              />
              {params.row.status === "REJECTED" && (
                <Tooltip
                  title={`Rejection Note: ${params.row.rejectionNote}`}
                  arrow
                >
                  <StickyNote2Icon
                    className="note-icon cursor-pointer"
                    style={{ color: "gray" }}
                  />
                </Tooltip>
              )}
            </div>
          ),
        }
      );
      break;

    case "Open_offer_requests":
      columns.push(
        {
          field: "requestNumber",
          headerName: "Request Number",
          flex: 1,
          minWidth:190,
          sortable: true,
          valueGetter: (value: any, row: any) => `${row.requestNumber || "-"}`,
          headerClassName: "table-header",
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "inventoryName",
          headerName: "Inventory",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          sortable: true,
          //renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          renderCell: (params) => {
            if (params.value) {
              const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";

              return (
                <a href="#">
                  <Link
                    to={`${linkPrefix}/view-offer-request/${params.row.id}`}
                    className="link"
                  >
                    <CellWithTooltip value={params.value} />
                  </Link>
                </a>
              );
            } else {
              return "";
            }
          },
        },
        {
          field: "createdDate",
          headerName: "Requested On",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          sortable: true,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          valueGetter: (value: any, row: any) => {
            const dateTimeString = row.createdDate ? row.createdDate : null;

            if (dateTimeString) {
              //call the method which formate the time
              return CommonService.isUser() &&
                !CommonService.isSameCompany(row.companyId)
                ? CommonService.formatStrictDate(dateTimeString)
                : CommonService.formatDateTime(dateTimeString);
            }
          },
        },
        {
          field: "user",
          headerName: "Requested By",
          flex: 1,
          minWidth:190,
          sortable: true,
          valueGetter: (value: any, row: any) =>
            `${row.firstName || ""} ${row.lastName || ""}`,
          headerClassName: "table-header",
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "email",
          headerName: "Email",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          sortable: true,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "quantity",
          headerName: "Total Quantity",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          sortable: true,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "pricePerBarrel",
          headerName: "Offer Price",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          sortable: true,
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.value}
              locale="en-US"
              currency="USD"
            />
          ),
        },
        ...(CommonService.isAdmin()
        ? [{
          field: "totalAmount",
          headerName: "Total Price",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          sortable: true,
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.value}
              locale="en-US"
              currency="USD"
            />
          ),}]
        : []),
        {
          field: "status",
          headerName: "Status",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          sortable: true,
          renderCell: (params: any) => (
            <div>
              <StatusDropdown
                currentStatus={toTitleCase(params.row.status)}
                onStatusChange={(newStatus) => { }}
                disableCondition={true}
                allowedLabels={[]}
              />
              {params.row.status === "REJECTED" && (
                <Tooltip
                  title={`Rejection Note: ${params.row.rejectionNote}`}
                  arrow
                >
                  <StickyNote2Icon
                    className="note-icon cursor-pointer"
                    style={{ color: "gray" }}
                  />
                </Tooltip>
              )}
            </div>
          ),
        }
      );
      break;

    case "Open_inventory_change_requests":
      columns.push(
        {
          field: "productName",
          headerName: "Inventory",
          flex: 1,
          minWidth:190,
          renderCell: (params) => {
            const productName = params.value;
            if (productName) {
              const rolePrefix = CommonService.isAdmin() ? "master" : "user";
              // const linkPrefix = currentPath === '/user/inventory-change-requests' || currentPath === '/master/inventory-change-requests'
              //  ? 'compare-inventory' : 'edit-inventory';

              return (
                <a href="#">
                  <Link
                    to={`/${rolePrefix}/compare-inventory/${params.row.id}`}
                    className="link"
                  >
                    {productName}
                  </Link>
                </a>
              );
            } else {
              return "";
            }
          },
          //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
          headerClassName: "table-header",
        },
        {
          field: "facility",
          headerName: "Facility",
          // flex: 1
          //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
          headerClassName: "table-header",
        },
        {
          field: "age",
          headerName: "Age",
          flex: 0.5,
          valueGetter: (value: any, row: any) =>
            `${row.spiritAgeYear || ""}years ${row.spiritAgeMonth || ""}months`,
          headerClassName: "table-header",
        },
        {
          field: "initialQuantity",
          headerName: "Initial Quantity",
          // // flex: 1
          //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
          headerClassName: "table-header",
        },
        {
          field: "quantity",
          headerName: "Quantity for Sales",
          // // flex: 1
          valueGetter: (value: any, row: any) =>
            row.isAvailableForSell ? row.quantity : 0,
          headerClassName: "table-header",
        },
        {
          field: "isNotAvailableForSell",
          headerName: "Quantity not for Sell",
          headerClassName: "table-header",
          cellClassName: "info-dropdown",
        },
        {
          field: "lotNo",
          headerName: "Lot ID",
          headerClassName: "table-header",
        },
        {
          field: "price",
          headerName: "Price",
          headerClassName: "table-header",
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.value}
              locale="en-US"
              currency="USD"
            />
          ),
        },
        {
          field: "platformFee",
          headerName: "Fees",
          headerClassName: "table-header",
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.value}
              locale="en-US"
              currency="USD"
            />
          ),
        },
        {
          field: "total",
          headerName: "Total",
          // flex: 1
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.row.price * params.row.platformFee}
              locale="en-US"
              currency="USD"
            />
          ),
        }
      );
      break;

    case "Barrels":
    case "Available_for_sell":
    case "Not_available_for_sell":
    case "Wish_list":
      columns.push(
        {
          field: "productName",
          headerName: "Inventory",
          flex: 1,
          sortable: true,
          minWidth:190,
          headerClassName: "table-header",
        },
        {
          field: "facility",
          headerName: "Facility",
          sortable: true,
          // flex: 1
          //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
          headerClassName: "table-header",
        },
        {
          field: "age",
          headerName: "Age",
          sortable: true,
          flex: 0.5,
          valueGetter: (value: any, row: any) => {

            if (!row.fillDate) return ""; // Return empty string if fillDate is null or undefined

            const currentDate = new Date();
            const fillDate = new Date(row.fillDate); // Use the fillDate field
        
            // Calculate the difference in years and months
            let years = currentDate.getFullYear() - fillDate.getFullYear();
            let months = currentDate.getMonth() - fillDate.getMonth();
        
            // Adjust for negative months (if current month is earlier than the fill month)
            if (months < 0) {
              years--;
              months += 12;
            }
        
            // Format the result as 'X years Y months'
            return `${years} years ${months} months`;
          },
        },
        // {
        //   field: "quantity",
        //   headerName: "Quantity",
        //   flex: 1,
        //   minWidth:190,
        //   valueGetter: (value: any, row: any) =>
        //     row.isAvailableForSell ? row.quantity : 0,
        //   headerClassName: "table-header",
        // },
        {
          field: "lotNo",
          headerName: "Lot ID",
          headerClassName: "table-header",
          flex: 1,
          sortable: true,
          minWidth:190,
        },
        {
          flex: 1,
          minWidth:190,
          field: "price",
          sortable: true,
          headerName: "Price",
          headerClassName: "table-header",
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.value}
              locale="en-US"
              currency="USD"
            />
          ),
        },
      );
      break;

    case "inventory_listing":
        columns.push(
          {
            field: "title",
            headerName: "Inventory",
            flex: 1,
            sortable: true,
            minWidth:190,
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          },
          {
            field: "price",
            headerName: "Price/Barrel",
            flex: 1,
            sortable: true,
            minWidth:190,
            valueGetter: (value: any, row: any) => row.price ? `$${parseFloat(row.price).toFixed(2)}` : "$0.00",
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
            headerClassName: "table-header",
  
          },
          {
            field: "fee",
            headerName: "Commission Fee/Barrel",
            flex: 1,
            sortable: true,
            minWidth:190,
            valueGetter: (value: any, row: any) => {
              if (!row) return "$0.00";
          
              const fee = row.fee ?? 0; 
              const feeType = row.feeType ?? "FLAT";
          
              return feeType === "PERCENTAGE" ? `${parseFloat(fee).toFixed(2)}%` : `$${parseFloat(fee).toFixed(2)}`;
            },
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
            headerClassName: "table-header",
          },
          {
            field: "availableBarrels",
            headerName: "Available Barrels",
            flex: 1,
            sortable: true,
            minWidth:190,
            valueGetter: (value: any, row: any) => {
              const count = row.availableBarrels ?? 0;
              return `${count} ${count === 1 || count === 0 ? "Barrel" : "Barrels"}`;
            },
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
            headerClassName: "table-header",
          },
          {
            field: "reservedBarrels",
            headerName: "Reserved Barrels",
            flex: 1,
            sortable: true,
            minWidth:190,
            valueGetter: (value: any, row: any) => {
              const count = row.reservedBarrels ?? 0;
              return `${count} ${count === 1 || count === 0 ? "Barrel" : "Barrels"}`;
            },
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
            headerClassName: "table-header",
          },
          {
            field: "soldBarrels",
            headerName: "Sold Barrels",
            flex: 1,
            sortable: true,
            minWidth:190,
            valueGetter: (value: any, row: any) => {
              const count = row.soldBarrels ?? 0;
              return `${count} ${count === 1 || count === 0 ? "Barrel" : "Barrels"}`;
            },
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
            headerClassName: "table-header",
          },
          {
            field: "fillDate",
            headerName: "Age",
            flex: 1,
            sortable: true,
            minWidth: 157,
            valueGetter: (value: any, row: any) => {
                const currentDate = new Date();
                const fillDate = new Date(row.fillDate); // Use the fillDate field
  
                // Calculate the difference in years and months
                let years = currentDate.getFullYear() - fillDate.getFullYear();
                let months = currentDate.getMonth() - fillDate.getMonth();
  
                // Adjust for negative months (if current month is earlier than the fill month)
                if (months < 0) {
                    years--;
                    months += 12;
                }
  
                // Format the result as 'X years Y months'
                return `${years} years ${months} months`;
            },
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          },
          {
            field: "facility",
            headerName: "Facility",
            headerClassName: "table-header",
            flex: 1,
            sortable: true,
            minWidth:190,
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          },
          {
            field: "lotNo",
            headerName: "Lot IDs",
            headerClassName: "table-header",
            flex: 1,
            sortable: false,
            minWidth:190,
            renderCell: (params: any) => {
              const lotIds = params.value ? params.value.split(", ") : [];
              return (
                <div className="lot-id-list-wrapper d-flex flex-nowrap gap-2 h-100 align-items-center overflow-x-auto">
                    {lotIds.map((lot, index) => (
                        <div className="lot-id-item px-2 py-1 border rounded">
                            {lot}
                        </div>
                    ))}
                </div>
              )
            }
          },
        );
        break;  
    default:
      // Default case if no other cases match
      break;
  }

  const handleDashboardPrefrence = (e: string) => {
    setIsTableFetched(false); // for manage data of owner and buyer
    setActiveTab("");

    localStorage.setItem("dashboardPrefrence", e);

    if (CommonService.isAdmin())
      e == Constant.Profile.OWNER
        ? route(`/master/company-dashboard-as-owner/` + id)
        : route(`/master/company-dashboard-as-buyer/` + id);
    else
      e == Constant.Profile.OWNER
        ? route(`/user/view-as-owner`)
        : route(`/user/view-as-buyer`);
  };

  return (
    <div className="company-dashboard">
      <div className="container-fluid pt-1 pb-3">
        <div className="company-dashboard__user d-flex">
          {CommonService.isAdmin() && (
            <>
              <div
                className="back__button my-3 mx-3"
                onClick={() => route("/master/company-list")}
              >
                <IconButton
                  aria-label="ArrowBackIcon"
                  sx={{ backgroundColor: "#FFF", mb: 1 }}
                >
                  <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
                </IconButton>
              </div>

              <h4 className="company-dashboard__user--title my-3 px-2 lh-base">
                {companyDetails?.companyName}
              </h4>
              <div className="company-dashboard__user--companyInfo my-3 px-2">
                <Dropdown>
                  <Dropdown.Toggle variant="info">
                    <img src={Info} alt="Info" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <ListGroup>
                      <ListGroup.Item>
                        <div className="company-details d-flex align-items-center">
                          <img src={CompanyStoreIcon} alt="CompanyStoreIcon" />
                          <span className="company-name ms-2">
                            {companyDetails?.companyName &&
                              companyDetails.companyName.trim() !== ""
                              ? companyDetails.companyName
                              : "-"}
                          </span>
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <div className="icon">
                          <img src={User} alt="User" />
                        </div>
                        <div className="info d-flex flex-column mx-3">
                          <span className="sub-title">Contact Person</span>
                          <span className="detail">
                            {companyDetails?.contactPerson &&
                              companyDetails.contactPerson.trim() !== ""
                              ? companyDetails.contactPerson
                              : "-"}
                          </span>
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <div className="icon">
                          {/* <SmartphoneOutlinedIcon sx={{ fontSize: '40px', color: '#8F9195' }} /> */}
                          <img
                            src={DeviceMobileCamera}
                            alt="DeviceMobileCamera"
                          />
                        </div>
                        <div className="info d-flex flex-column mx-3">
                          <span className="sub-title">Contact Number</span>
                          <span className="detail">
                            {companyDetails?.contactNumber &&
                              companyDetails.contactNumber.trim() !== ""
                              ? companyDetails.contactNumber
                              : "-"}
                          </span>
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <div className="icon">
                          {/* <EmailOutlinedIcon sx={{ fontSize: '40px', color: '#8F9195' }} /> */}
                          <img
                            src={EnvelopeSimpleOpen}
                            alt="EnvelopeSimpleOpen"
                          />
                        </div>
                        <div className="info d-flex flex-column mx-3">
                          <span className="sub-title">Email</span>
                          <span className="detail">
                            {companyDetails?.email &&
                              companyDetails.email.trim() !== ""
                              ? companyDetails.email
                              : "-"}
                          </span>
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <div className="icon">
                          {/* <LocationOnOutlinedIcon sx={{ fontSize: '40px', color: '#8F9195' }} /> */}
                          <img src={MapPin} alt="MapPin" />
                        </div>
                        <div className="info d-flex flex-column mx-3">
                          <span className="sub-title">Location</span>
                          <span className="detail">
                            {companyDetails?.city}
                            {companyDetails?.city ?? "-"}{" "}
                            {companyDetails?.state} {companyDetails?.zipcode}
                          </span>
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <div className="icon">
                          {/* <EventAvailableOutlinedIcon sx={{ fontSize: '40px', color: '#8F9195' }} /> */}
                          <img src={CalendarCheck} alt="CalendarCheck" />
                        </div>
                        <div className="info d-flex flex-column mx-3">
                          <span className="sub-title">Joining Date</span>
                          <span className="detail">{formattedDate}</span>
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </>
          )}

          <div className="company-dashboard-user__nav--tabs-wrapper d-flex align-items-center ms-auto my-3 mb-2 me-3">
            {/* <span className="ms-3"> */}
            <Button
              aria-current="page"
              onClick={() => handleDashboardPrefrence(Constant.Profile.BUYER)}
              className={`${
                CommonService.getDashboardPrefrence() === Constant.Profile.OWNER
                  ? "nav-btn"
                  : "active nav-btn"
              }`}
            >
              <Typography
                variant="body2"
                component={"span"}
                sx={{
                  // color: CommonService.getDashboardPrefrence() === Constant.Profile.BUYER ? "black" : "#9E874A",
                  fontSize: "16px",
                  fontWeight: 800,
                  cursor: "pointer",
                }}
              >
                {CommonService.isUser() ? "View as Buyer" : "Buyer Profile"}
              </Typography>
            </Button>
            {/* </span> */}
            {/* <span className="ms-3"> */}
            <Button
              aria-current="page"
              onClick={() => handleDashboardPrefrence(Constant.Profile.OWNER)}
              className={`${
                CommonService.getDashboardPrefrence() === Constant.Profile.OWNER
                  ? "active nav-btn"
                  : "nav-btn"
              }`}
            >
              <Typography
                variant="body2"
                component={"span"}
                sx={{
                  // color: CommonService.getDashboardPrefrence() === Constant.Profile.OWNER ? "black" : "#9E874A",
                  fontSize: "16px",
                  fontWeight: 800,
                  cursor: "pointer",
                }}
              >
                {CommonService.isUser() ? "View as Owner" : "Owner Profile"}
              </Typography>
            </Button>
            {/* </span> */}
          </div>
        </div>
        <div className="company-dashboard__inner pt-2">
          <div className="row">
            <div className="col-12">
              <div
                className={`company-dashboard__data ${
                  CommonService.getDashboardPrefrence() === Constant.Profile.OWNER ||
                  !CommonService.isUser()
                    ? "owner-data"
                    : "buyer-data"
                  }`}
                style={{ display: isOpenRequestLoading ? "unset" : "grid" }}
              >
                {isOpenRequestLoading ? (
                  <>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={190}
                      style={{ width: "100%" }}
                    />
                  </>
                ) : (
                  <>
                    <div
                      className={`company-dashboard__data--card cursor-pointer ${activeTab === "order" ? "active" : ""
                        }`}
                      onClick={() => loadData("order", 0, 10, "CreatedModifiedDate", sortDirection)}
                    >
                      <img
                        src={OrderReqIcn}
                        alt="CompanyIcn"
                        width={70}
                        height={65}
                      />
                      <span
                        className="explore d-inline-flex align-items-center"
                        onClick={() => handleRoute("active-order")}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: 400,
                            marginRight: "-4px",
                            cursor: "pointer",
                          }}
                        >
                          More
                        </Typography>
                        <ChevronRightRoundedIcon />
                      </span>
                      <div className="d-flex flex-column">
                        <span className="count">
                          {openRequestCount["orderRequestCount"]}
                        </span>
                        <span className="details">Open Order Requests</span>
                      </div>
                    </div>
                    <div
                      className={`company-dashboard__data--card cursor-pointer ${activeTab === "offer" ? "active" : ""
                        }`}
                      onClick={() => loadData("offer", 0, 10, "CreatedModifiedDate", sortDirection)}
                    >
                      <img
                        src={OfferReqIcn}
                        alt="CompanyIcn"
                        width={61}
                        height={60}
                      />
                      <span
                        className="explore d-inline-flex align-items-center"
                        onClick={() => handleRoute("offer-request")}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: 400,
                            marginRight: "-4px",
                            cursor: "pointer",
                          }}
                        >
                          More
                        </Typography>
                        <ChevronRightRoundedIcon />
                      </span>
                      <div className="d-flex flex-column">
                        <span className="count">
                          {openRequestCount["offerRequestCount"]}
                        </span>
                        <span className="details">Open Offer Requests</span>
                      </div>
                    </div>
                    <div
                      className={`company-dashboard__data--card cursor-pointer ${activeTab === "sample" ? "active" : ""
                        }`}
                      onClick={() => loadData("sample", 0, 10, "companyName", sortDirection)}
                    >
                      <img
                        src={SampleReqIcn}
                        alt="CompanyIcn"
                        width={61}
                        height={60}
                      />
                      <span
                        className="explore d-inline-flex align-items-center"
                        onClick={() => handleRoute("sample-request")}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: 400,
                            marginRight: "-4px",
                            cursor: "pointer",
                          }}
                        >
                          More
                        </Typography>
                        <ChevronRightRoundedIcon />
                      </span>
                      <div className="d-flex flex-column">
                        <span className="count">
                          {openRequestCount["sampleRequestCount"]}
                        </span>
                        <span className="details">Open Sample Requests</span>
                      </div>
                    </div>
                    {CommonService.getDashboardPrefrence() == Constant.Profile.OWNER && (
                      <>
                        <div
                          className={`company-dashboard__data--card cursor-pointer ${activeTab === "sell" ? "active" : ""
                            }`}
                          onClick={() => loadData("sell", 0, 10, sortField, sortDirection)}
                        >
                          <img
                            src={SellReqIcn}
                            alt="CompanyIcn"
                            width={65}
                            height={60}
                          />
                          <span
                            className="explore d-inline-flex align-items-center"
                            onClick={() => handleSellRequestRoute("customer-service")}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "12px",
                                fontWeight: 400,
                                marginRight: "-4px",
                                cursor: "pointer",
                              }}
                            >
                              More
                            </Typography>
                            <ChevronRightRoundedIcon />
                          </span>
                          <div className="d-flex flex-column">
                            <span className="count">
                              {openRequestCount["sellRequestCount"]}
                            </span>
                            <span className="details">Open Sell Requests</span>
                          </div>
                        </div>

                        <div
                        className={`company-dashboard__data--card cursor-pointer ${
                          activeTab === "inventory-listing" ? "active" : ""
                        }`}
                        onClick={() => loadData("inventory-listing", 0, 10, "title", sortDirection)}
                      >
                      <img
                        src={InventoryIcn}
                        alt="CompanyIcn"
                        width={68}
                        height={60}
                      />
                      <span className="explore d-inline-flex align-items-center"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleRoute("inventory-listing");
                        }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: 600,
                            marginRight: "-4px",
                            cursor: "pointer",
                          }}
                        >
                          More
                        </Typography>
                        <ChevronRightRoundedIcon />
                      </span>
                      <div className="d-flex flex-column">
                        <span className="count">
                          <CurrencyFormatter
                            amount={
                              openRequestCount["inventorieListingRequestCount"]
                                ? openRequestCount["inventorieListingRequestCount"]
                                : 0
                            }
                            locale="en-US"
                            currency="INR"
                          />
                        </span>
                        <span className="details">Total Inventory</span>
                      </div>
                    </div>

                        <div
                          className={`company-dashboard__data--card card-span cursor-pointer ${activeTab === "inventories" ? "active" : ""
                            }`}
                          onClick={() => loadData("inventory", 0, 10, "productName", sortDirection)}
                        >
                          <img
                            src={TotalBarrels}
                            alt="CompanyIcn"
                            width={68}
                            height={60}
                          />
                          {/* <ExpandCircleDownOutlinedIcon /> */}
                          <span className="explore d-inline-flex align-items-center"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleRoute("inventory");
                            }}>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "12px",
                                fontWeight: 400,
                                marginRight: "-4px",
                                cursor: "pointer",
                              }}
                            >
                              More
                            </Typography>
                            <ChevronRightRoundedIcon />
                          </span>
                          <div className="d-flex flex-column">
                            <span className="count">
                              {openRequestCount["inventories"]
                                ? CommonService.numberFormatter(
                                  openRequestCount["inventories"]
                                )
                                : 0}
                            </span>
                            <span className="details">Total Barrels</span>
                          </div>
                        </div>
                        <div
                          className={`company-dashboard__data--card cursor-pointer ${activeTab === "available-for-sell" ? "active" : ""
                            }`}
                          onClick={() => loadData("available-for-sell", 0, 10, sortField, sortDirection)}
                        >
                          <img src={AvailableBarrels} alt="AvailableBarrels" width={34} />
                          <span className="explore d-inline-flex align-items-center"
                            onClick={() => handleRoute("available-for-sell")}>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "12px",
                                fontWeight: 400,
                                marginRight: "-4px",
                              }}
                            >
                              More
                            </Typography>
                            <ChevronRightRoundedIcon />
                          </span>
                          <div className="d-flex flex-column">
                            <span className="count">
                              {openRequestCount["avilableForSales"]
                                ? CommonService.numberFormatter(
                                  openRequestCount["avilableForSales"]
                                )
                                : 0}
                            </span>
                            <span className="details mt-1">
                              {" "}
                              Listed For Sell{" "}
                            </span>
                          </div>
                        </div>
                        <div
                          className={`company-dashboard__data--card cursor-pointer ${activeTab === "not-available-for-sell" ? "active" : ""
                            }`}
                          onClick={() => loadData("not-available-for-sell", 0, 10, sortField, sortDirection)}
                        >
                          <img src={NotAvailableBarrels} alt="NotAvailableBarrels" width={34} />
                          <span className="explore d-inline-flex align-items-center"
                            onClick={() => handleRoute("not-available-for-sell")}>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "12px",
                                fontWeight: 400,
                                marginRight: "-4px",
                                cursor: "pointer",
                              }}
                            >
                              More
                            </Typography>
                            <ChevronRightRoundedIcon />
                          </span>
                          <div className="d-flex flex-column">
                            <span className="count">
                              {openRequestCount["notAvilableForSales"]
                                ? CommonService.numberFormatter(
                                  openRequestCount["notAvilableForSales"]
                                )
                                : 0}
                            </span>
                            <span className="details mt-1">
                              Unlisted For Sell
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                    <div
                      className={`company-dashboard__data--card cursor-pointer ${activeTab === "user" ? "active" : ""
                        }`}
                      onClick={() => loadData("user", 0, 10, sortField, sortDirection)}
                    >
                      <img
                        src={UsersIcn}
                        alt="CompanyIcn"
                        width={67}
                        height={65}
                      />
                      <span
                        className="explore d-inline-flex align-items-center"
                        onClick={() => {
                          const basePath = CommonService.isAdmin()
                            ? "/master"
                            : "/user";
                          route(`${basePath}/my-warehouse/user-list/${params}`);
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: 400,
                            marginRight: "-4px",
                            cursor: "pointer",
                          }}
                        >
                          More
                        </Typography>
                        <ChevronRightRoundedIcon />
                      </span>
                      <div className="d-flex flex-column">
                        <span className="count">
                          {openRequestCount["users"]}
                        </span>
                        <span className="details">Users</span>
                      </div>
                    </div>
                    {CommonService.getDashboardPrefrence() == Constant.Profile.BUYER &&
                      CommonService.isUser() && (
                        <div
                          className={`company-dashboard__data--card cursor-pointer ${activeTab === "wish-list" ? "active" : ""
                            }`}
                          onClick={() => loadData("wish-list", 0, 10, sortField, sortDirection)}
                        >
                          <img
                            src={WishListIcon}
                            alt="wishlistIcn"
                            width={61}
                            height={60}
                          />
                          <span
                            className="explore d-inline-flex align-items-center"
                            onClick={() => route("/user/wish-list")}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "12px",
                                fontWeight: 400,
                                marginRight: "-4px",
                                cursor: "pointer",
                              }}
                            >
                              More
                            </Typography>
                            <ChevronRightRoundedIcon />
                          </span>
                          <div className="d-flex flex-column">
                            <span className="count">
                              {openRequestCount["wishListCount"]}
                            </span>
                            <span className="details">Wishlist</span>
                          </div>
                        </div>
                      )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="mt-3">
            <div className="dashboard--msg-table--container position-relative" style={{ backgroundColor: "#fff" }}>
              <div className={`dashboard-custom-table-outter ${isMessageBoxVisible ? "msg-box-open" : ""}`} >
                {!isOpenRequestLoading ? (
                  <>
                    {isTableFetched && (
                      <div
                        ref={displayGridRef}
                        className="dashboard__inner mt-3"
                      >
                        <DisplayGrid
                          tableData={tableData}
                          columns={columns}
                          localeText={localeText}
                          title={title}
                          totalRows={totalCount}
                          onPageChange={handlePageChange}
                          isLoading={isLoading}
                          paginationModel={paginationModel}
                          onSortChange={handleSortingChange} 
                        />
                      </div>
                    )}
                    {!isTableFetched && (
                      <div className="">
                        <RecentRequest />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      className="mt-3"
                      height={616}
                      style={{ width: "100%" }}
                    />
                  </>
                )}
              </div>
              <div className="position-absolute top-0 end-0 unread-msg-wrapper" style={{ maxWidth: isMessageBoxVisible ? "490px" : "48px" }}>
                {/* <div className="dashboard__messageBox--title align-items-center">
                    {isMessageBoxVisible && <h4 className="mb-0 lh-base">Unread Messages</h4>}
                    <div className="msg-count position-relative cursor-pointer ms-auto" onClick={handleIconClick}>
                      <MailOutlineSharpIcon sx={{ color: "#fff", height: 40, width: 40 }} />
                      <span className="count position-absolute top-0 start-100 translate-middle badge">
                        2
                      </span>
                    </div>
                  </div> */}
                {/* {isMessageBoxVisible && (<div> */}
                {/* {!isOpenRequestLoading ? ( */}
                <UnreadMessage
                  inventoryCount={openRequestCount["totalInventories"]}
                  onValueChange={handleValueChange} />
                {/* ) : ( */}
                {/* <>
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  className="mt-3"
                  height={800}
                  style={{ width: "100%" }}
                />
              </> */}
                {/* )} */}
                {/* </div>)} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarehouseCompanyDashboard;
