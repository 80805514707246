import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { CommonService } from "../../Service/CommonService";
import "../Dynamic/InventoryCSV.scss";
import SearchIcon from "../../assets/images/icons/search.svg";
import { InventoryApiService } from "../../Service/InventoryApiService";
import UploadCSV from "../popups/UploadCSV";
import { CookieService } from "../../Service/CookieService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Grid, IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import SearchBar from "../Static/SearchBar";
import { useHistory, useLocation } from "react-router";
import ViewDocs from "../popups/ViewDocs";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { VisibilityOutlined } from "@mui/icons-material";
import SingleReInvite from "../popups/SingleReInvite";
import { Link } from "react-router-dom";
import UploadDocs from "../popups/uploadDocs";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import ContainerMessageBox from "./ContainerMessageBox";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { ButtonGroup } from "react-bootstrap";
import { HistoryStepper } from "../Common/HistoryStepper";
import SingleApproveCSV from "../popups/SingleApproveCSV";
import SingleRejectCSV from "../popups/SingleRejectCSV";
import SinglePublishCSV from "../popups/SinglePublishCSV";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import Swal from "sweetalert2";
import StatusDropdown from "../Common/StatusDropdown";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import QuestionIcon from '../../assets/images/icons/Question.svg';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
import Constant from "../../Constant";

const ViewSellRequest: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };
  const history = useHistory();
  const [search, setSearch] = useState("");
  const { id } = useParams<{ id: string }>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [historyData, setHistoryData] = useState<any[]>([]);
  const [inventoryDocs, setInventoryDocs] = useState<any[]>([]);
  const [inventorysellReq, setInventorysellReq] = useState<any>(null);
  const [inventoryCsvWithDocs, setInventoryCsvWithDocs] = useState<any>(null);
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
  const [pdfURL, setPdfURL] = useState<string | null>(null);
  const [isCsvApproved, setCsvApproved] = useState<boolean>(false);
  const [isCsvRejected, setCsvRejected] = useState<Boolean>(false);
  const [status, setStatus] = useState<any>();
  const [fileName, setFileName] = useState<string>();
  const [isMessageBoxOpen, setMessageBoxOpen] = useState<boolean>(false);
  const [isHandleShow,  setHandleShow] = useState<any>(false);
  const [unreadMessageCount, setUnreadMessageCount] = React.useState<number>(0);
  
  const fetchData = async () => {
    try {
      setIsLoading(true);
      setHandleShow(false);
      getInventorySellRequestWithDocs();
      const response: any = await InventoryApiService.getCsvBySellReqId(id);
      setTableData(response.data);
      setIsLoading(true);
      const documents: any = await InventoryApiService.getCsvDocBySellReqId(id);
      setInventoryDocs(documents.data);
      setIsLoading(false);
      const history: any = await InventoryApiService.getCsvHistoryBySellReqId(
        id
      );
      setHistoryData(history.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const fetchUnreadMessageCount = async () => {
    try{
      const response = await InventoryApiService.fetchUnreadMessageCount(id);
      setUnreadMessageCount(response.data);
      
    } catch(error) {
      console.log("error is occurred while api calling")
    }

   }

  const getInventorySellRequestWithDocs = async () => {
    try {
      const response: any = await InventoryApiService.getSellReqById(id);
      setFileName(response.data?.orgFileName);
      setInventorysellReq(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const statusDetailsRef = useRef({ id: "", status: "", fileName: "", feeType: "",platformFee: "" });
  
  //handle message box click
  const handleMessageBoxClick = () => {

    if (isMessageBoxOpen) {
      setMessageBoxOpen(false);
    } else {
      setMessageBoxOpen(true);
      setUnreadMessageCount(0);
    }
  }

  const handleStatusChange = async (status: string) => {
    console.log(status);

    if (status != "Accepted" && status != "Rejected" && status != "Published") {
      const orderStatus = status?.replace(/ /g, "_");

      //create the object to send
      const sd = {
        id: id,
        status: orderStatus?.toUpperCase(),
        fileName: fileName,
        feeType: inventorysellReq?.feeType,
        platformFee:  inventorysellReq?.platformFee,

      };

      console.log("this is calling and this is the data " + sd.feeType);
      statusDetailsRef.current = sd;
      InventoryApiService.updateInventoryCSV(statusDetailsRef.current).then(
        (response) => {
          CommonService.Toast.fire({
            title: "Sell request status updated successfully",
            icon: "success",
          });
          fetchData();
        }
      );
    } else {
      setHandleShow(true);
    }
  };

  //formate the status
  function formatStatus(status?: string): string {
    let formattedStatus = "";
    if (status !== undefined) {
      formattedStatus = status
        ?.split(/[_\\]/)
        .map(
          (word) => word.charAt(0)?.toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    }

    return formattedStatus;
  }

  useEffect(() => {
    fetchData();
    fetchUnreadMessageCount();
  }, []);

  const toTitleCase = (str: string) => {
    return str
      ?.replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char?.toUpperCase()); // Capitalize the first letter of each word
  };

  const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";

  const downloadCSV = async (event: any, params: any) => {
    try {
      const requestDto = {
        id: params.row.id,
        siteId: inventorysellReq.siteId,
        companyId: inventorysellReq.ecommUser.companyId,
        fileName: params.row.fileName,
      };

      const response: any = await InventoryApiService.downloadCSV(requestDto);
      // Assuming response.data is the blob data
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = params.row.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const downloadZipWithDocs = async () => {
    try {
      const response: any = await InventoryApiService.downloadZipWithDocs(id);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "documents.zip");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleVisibilityClick = (event: any, params: any) => {
    // Logic to handle the click event for Visibility button
    event.stopPropagation(); // Prevent event from bubbling up to the row
    const url = `${linkPrefix}/view-csv/${params.row.id}`;
    window.open(url, "_blank"); // Open the URL in a new tab
  };

  const downloadDoc = async (item: any) => {
    const requestDto = {
      id: item.id,
      siteId: inventorysellReq.siteId,
      companyId: inventorysellReq.ecommUser.companyId,
      fileName: item.fileName,
    };

    try {
      const response: any = await InventoryApiService.downloadDoc(requestDto);
      // Assuming response.data is the blob data
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = item.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectionModelChange = (newSelection: GridRowId[]) => {
    setSelectionModel(newSelection);
    console.log(newSelection.length);
  };

  const formatDateString = (dateString: any) => {
    if (dateString) {
      // Parse the input date string
      const date = new Date(dateString.replace(" ", "T"));

      // Define arrays for month names and add leading zero function
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const addLeadingZero = (num: any) => (num < 10 ? `0${num}` : num);

      // Extract date parts
      const year = date.getFullYear();
      const month = monthNames[date.getMonth()];
      const day = addLeadingZero(date.getDate());
      const hours = addLeadingZero(date.getHours());
      const minutes = addLeadingZero(date.getMinutes());
      const seconds = addLeadingZero(date.getSeconds());

      // Format the date string
      return `${month} ${day}, ${year} ${hours}:${minutes}:${seconds}`;
    } else return "";
  };

  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  const columnsForCsv: GridColDef[] = [
    {
      field: "createdDate",
      headerName: "Submitted On",
      flex: 1,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.createdDate ? row.createdDate : null;

        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.formatDateTime(dateTimeString);
        }
      },
      headerClassName: "table-header",
    },
    {
      field: "user",
      headerName: "Submitted By",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        `${row.firstName || ""} ${row.lastName || ""}`,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "fileName",
      headerName: "File Name",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => {
        const csvFileName = params.value;
        if (csvFileName) {
          const parts = csvFileName.split("_");
          const fileNameToShow = parts.slice(1).join("_");
          const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";

          return (
            <a href="#">
              <Link
                to={`${linkPrefix}/view-csv/${params.row.id}`}
                className="link"
              >
                {fileNameToShow}
              </Link>
            </a>
          );
        } else {
          return "";
        }
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params: any) => (
        <div>
          <Tooltip title={params.row.rejectionNote} arrow>
            {/* <Button
              style={{
                background:
                  params.row.status === "APPROVED"
                    ? "#F99600" // Green for 'approved'
                    : params.row.status === "REJECTED"
                    ? "red" // Green for 'approved'
                    : params.row.status === "PENDING_REVIEW"
                    ? "#e8eaeb" // Gray for 'submitted'
                    : params.row.status === "PUBLISHED"
                    ? "green"
                    : "",
                color:
                  params.row.status === "PENDING_REVIEW" ||
                  params.row.status === "APPROVED"
                    ? "#000000DE"
                    : "white", // Gray for 'submitted'
                border: "1px transparent",
                padding: "revert", // Default color if none of the conditions are met
              }}
              size="sm"
            >
              {" "}
              {toTitleCase(params.row.status)}
            </Button> */}
            <StatusDropdown
              currentStatus={params.row.status}
              disableCondition={true}
              onStatusChange={(newStatus) => { }}
              allowedLabels={[]}
            />
          </Tooltip>
          {params.row.status === "REJECTED" && (
            <Tooltip
              title={`Rejection Note: ${params.row.rejectionNote}`}
              arrow
            >
              <StickyNote2Icon
                className="note-icon cursor-pointer"
                style={{ color: "gray" }}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      sortable: false,
      width: 200,
      headerClassName: "table-header",
      renderCell: (params: any) => (
        <div>
          <Tooltip title="View file" arrow>
            <IconButton
              onClick={(event) => handleVisibilityClick(event, params)}
            >
              <VisibilityOutlined />
            </IconButton>
          </Tooltip>

          <Tooltip title="Download file" arrow>
            <IconButton onClick={(event) => downloadCSV(event, params)}>
              <CloudDownloadOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const columnsForDoc: GridColDef[] = [
    {
      field: "createdDate",
      headerName: "Submitted On",
      flex: 1,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.createdDate ? row.createdDate : null;
        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.formatDateTime(dateTimeString);
        }
      },
      headerClassName: "table-header",
    },
    {
      field: "fileName",
      headerName: "File Name",
      flex: 1,
      valueGetter: (value: any, row: any) => `${row.fileName || ""}`,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "user",
      headerName: "Submitted By",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        `${row.firstName || ""} ${row.lastName || ""}`,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      sortable: false,
      width: 200,
      headerClassName: "table-header",
      renderCell: (params: any) => {
        //check file have .pdf extension or not
        const isPdf = params.row.fileName.toLowerCase().endsWith(".pdf");

        return (
          <div>
            {/* if file contains the .pdf extension then show the view button */}
            {isPdf && (
              <Tooltip title="View" arrow>
                <IconButton
                  onClick={() =>
                    CommonService.showPdf(
                      params.row.id,
                      inventorysellReq.companyId,
                      inventorysellReq.siteId,
                      params.row.fileName,
                      Constant.RequestType.SELL_REQUEST,
                    )
                  }
                >
                  <VisibilityOutlined />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="Download document" arrow>
              <IconButton onClick={() => downloadDoc(params.row)}>
                <CloudDownloadOutlinedIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  function routeBack() {
    if (CommonService.isUser()) history.push("/user/inventory-sell-requests");
    else history.push("/master/inventory-sell-requests");
  }

  return (
    <div className="usersList">
      <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center mb-2">
        {CommonService.isAdmin() && (
          <div className="back__button me-3" onClick={routeBack}>
            <IconButton
              aria-label="ArrowBackIcon"
              sx={{ backgroundColor: "#FFF", mb: 1 }}
            >
              <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
            </IconButton>
          </div>
        )}

        {CommonService.isAdmin() && (
          <div className="header--title mb-2 me-3">
            <h1 className="title mb-0">
              Inventory Sell Request
              <i className="bi bi-heart-fill"></i>
            </h1>
          </div>
        )}

        <div className="header--title d-flex flex-wrap me-auto mb-xxl-0">
          <div className="company__details me-4 me-xl-4 mb-3">
            <Typography
              variant="h6"
              sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
            >
              Company name
            </Typography>
            <Typography
              variant="h3"
              sx={{
                color: "#333333",
                fontSize: { xl: "18px", xs: "16px" },
                fontWeight: 700,
              }}
            >
              {inventorysellReq?.ecommUser?.companyName}
            </Typography>
          </div>
          <div className="person__details me-4 me-xl-4 mb-3">
            <Typography
              variant="h6"
              sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
            >
              Submitted by
            </Typography>
            <Typography
              variant="h3"
              sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
            >
              {inventorysellReq?.ecommUser?.firstName}{" "}
              {inventorysellReq?.ecommUser?.lastName}
            </Typography>
          </div>

          {inventorysellReq?.sellRequestName && (<div className="time__details me-4 me-xl-4 mb-3">
            <Typography
              variant="h6"
              sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
            >
              Name of Sell Request
            </Typography>
            <Typography
              variant="h3"
              sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
            >
              {inventorysellReq?.sellRequestName}
            </Typography>
          </div>)}
          
          <div className="time__details me-4 me-xl-4 mb-3">
            <Typography
              variant="h6"
              sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
            >
              Submitted on
            </Typography>
            <Typography
              variant="h3"
              sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
            >
              {CommonService.formatDateTime(inventorysellReq?.createdDate)}
            </Typography>
          </div>
          {inventorysellReq?.feeType && inventorysellReq?.platformFee && (<div className="person__details me-4 me-xl-4 mb-3">
            <Typography
              variant="h6"
              sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
            >
              Fees (Per Barrel)
            </Typography>
            <Typography
              variant="h3"
              sx={{ color: "#B08D18", fontSize: "18px", fontWeight: 700 }}
            >
              {inventorysellReq?.feeType === 'FLAT'
                ? `$${inventorysellReq?.platformFee}`
                : `${inventorysellReq?.platformFee}%`
              }
            </Typography>
          </div>)}
        </div>

        <div className="header--menu ms-auto mb-xxl-0">
          <div className="header--menu-items row d-flex justify-content-end">
            <IconButton aria-label="message" size="small"
              onClick={handleMessageBoxClick}
              sx={{ borderRadius: "50%", backgroundColor: isMessageBoxOpen ? "#97AEB8" : "#FFF", color: isMessageBoxOpen ? "#FFF" : "#1C1B1F", height: '40px', width: '40px', padding: "8px" }}>
              <MarkunreadOutlinedIcon />
              {unreadMessageCount > 0  && (
                    <div className="msg-count-wrapper">{unreadMessageCount}</div>
                  )}
            </IconButton>
            <div className="col-auto menu-items__btnGroup d-inline-flex mb-3">
              {/* <DropdownButton
                as={ButtonGroup}
                key={"up"}
                id={`dropdown-button-drop-${"up"}`}
                drop={"start"}
                title={formatStatus(inventorysellReq?.status)}
                className="dropdown-status"
                variant={getVariant(inventorysellReq?.status)}
                onSelect={(eventKey) => handleStatusChange(eventKey as string)}
                disabled={
                  (CommonService.isUser()&&
                    inventorysellReq?.status === "PENDING_REVIEW") ||
                  (CommonService.isAdmin() &&
                    inventorysellReq?.status === "APPROVED") ||
                  (CommonService.isUser() &&
                    inventorysellReq?.status === "FINALIZED") ||
                  (inventorysellReq?.status === "REJECTED" &&
                    tableData[0].status === "REJECTED") ||
                  inventorysellReq?.status === "PUBLISHED"
                }
              >
             
                {inventorysellReq?.status === "PENDING_REVIEW" &&
                  CommonService.isAdmin() && (
                    <Dropdown.Item
                      eventKey="1"
                      className="dropdown-item-accepted"
                    >
                      <SingleApproveCSV
                        fetchData={fetchData}
                        inventoryCsv={inventorysellReq}
                        isFromDropdown={true}
                      />
                    </Dropdown.Item>
                  )}
                {inventorysellReq?.status === "REJECTED" &&
                  tableData[0].status === "PENDING_REVIEW" && (
                    <Dropdown.Item
                      eventKey="2"
                      className="dropdown-item-pendingReview"
                    >
                      <PendingActionsIcon fontSize="small" sx={{ mr: 1 }} />{" "}
                      Pending Review
                    </Dropdown.Item>
                  )}
                {inventorysellReq?.status === "PENDING_REVIEW" &&
                  CommonService.isAdmin() && (
                    <Dropdown.Item
                      eventKey="3"
                      className="dropdown-item-rejected"
                    >
                      <SingleRejectCSV
                        fetchData={fetchData}
                        rejectUser={inventorysellReq}
                        isFromDropdown={true}
                      />
                    </Dropdown.Item>
                  )}
                {inventorysellReq?.status === "APPROVED" &&
                  CommonService.isUser() && (
                    <Dropdown.Item
                      eventKey="3"
                      className="dropdown-item-rejected"
                    >
                      <SinglePublishCSV
                        fetchData={fetchData}
                        inventoryCsv={inventorysellReq}
                        isFromDropdown={true}
                      />
                    </Dropdown.Item>
                  )}
                {inventorysellReq?.status === "FINALIZED" &&
                  CommonService.isAdmin() && (
                    <Dropdown.Item
                      eventKey="3"
                      className="dropdown-item-rejected"
                    >
                      <SinglePublishCSV
                        fetchData={fetchData}
                        inventoryCsv={inventorysellReq}
                        isFromDropdown={true}
                      />
                    </Dropdown.Item>
                  )}
              </DropdownButton>  */}

              {/* <StatusDropdown
      currentStatus={inventorysellReq?.status}
      onStatusChange={handleStatusChange}
      disableCondition={
        (CommonService.isUser() && inventorysellReq?.status === "PENDING_REVIEW") ||
        (CommonService.isAdmin() && inventorysellReq?.status === "APPROVED") ||
        (CommonService.isUser() && inventorysellReq?.status === "FINALIZED") ||
        (inventorysellReq?.status === "REJECTED" && tableData[0].status === "REJECTED") ||
        inventorysellReq?.status === "PUBLISHED"}
      
      allowedLabels={["Approved","Rejected"]}
      
    />*/}

              {/* {inventorysellReq?.status != "ACCEPTED" &&
                inventorysellReq?.status != "FINALIZED" &&
                inventorysellReq?.status != "PUBLISHED" &&
                inventorysellReq?.status != "REJECTED" && 
                inventorysellReq?.status != "PENDING_REVIEW" && 
                inventorysellReq?.status != "CANCELLED" &&
                (
                  <>
                    <StatusDropdown
                      currentStatus={inventorysellReq?.status}
                      onStatusChange={(newStatus) => {
                        setStatus(newStatus);
                        handleStatusChange(newStatus);
                      }}
                      disableCondition={CommonService.isUser()}
                      allowedLabels={[
                        "Rejected",
                        "Accepted",
                        "Under Review",
                        "Cancelled",
                      ]}
                    />

                    {status === "Accepted" && isHandleShow && (
                      <SingleApproveCSV
                        fetchData={fetchData}
                        inventoryCsv={inventorysellReq}
                        isFromDropdown={true}
                        popupShow={isHandleShow}
                      />
                    )}

                    {status == "Rejected" && isHandleShow && (
                      <SingleRejectCSV
                        fetchData={fetchData}
                        rejectUser={inventorysellReq}
                        isFromDropdown={true}
                        popupShow={isHandleShow}
                      />
                    )}
                  </>
                )}

              {inventorysellReq?.status === "PENDING_REVIEW" && (
                <StatusDropdown
                  currentStatus= {inventorysellReq?.status}
                  onStatusChange={(newStatus) => {
                    setStatus(newStatus)
                    handleStatusChange(newStatus)
                  }}
                  disableCondition = {CommonService.isAdmin()}
                  allowedLabels={['Cancelled']}
                  />
              )}  

              {inventorysellReq?.status === "ACCEPTED" && (
                <>
                  <StatusDropdown
                    currentStatus={inventorysellReq?.status}
                    onStatusChange={(newStatus) => {
                      setStatus(newStatus);
                      handleStatusChange(newStatus);
                    }}
                    disableCondition={CommonService.isAdmin()}
                    allowedLabels={["Published"]}
                  />

                  {status == "Published" && isHandleShow && (
                    <SinglePublishCSV
                      fetchData={fetchData}
                      inventoryCsv={inventorysellReq}
                      isFromDropdown={true}
                      popupShow={isHandleShow}
                    />
                  )}
                </>
              )}

              {inventorysellReq?.status == "FINALIZED" && (
                <>
                  <StatusDropdown
                    currentStatus={inventorysellReq?.status}
                    onStatusChange={(newStatus) => {
                      setStatus(newStatus);
                      handleStatusChange(newStatus);
                    }}
                    disableCondition={CommonService.isUser()}
                    allowedLabels={["Published"]}
                  />
                  {status == "Published" && isHandleShow && (
                    <SinglePublishCSV
                      fetchData={fetchData}
                      inventoryCsv={inventorysellReq}
                      isFromDropdown={true}
                      popupShow={isHandleShow}
                    />
                  )}
                </>
              )}

              {(inventorysellReq?.status == "REJECTED" ||
                inventorysellReq?.status == "PUBLISHED") && (
                <StatusDropdown
                  currentStatus={inventorysellReq?.status}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={
                    CommonService.isUser() || CommonService.isAdmin()
                  }
                  allowedLabels={["Published"]}
                />
              )} */}

              {(inventorysellReq?.status === "PENDING_REVIEW" || inventorysellReq?.status === "UNDER_REVIEW") && (
                <>
                  {CommonService.isUser() && inventorysellReq?.status === "PENDING_REVIEW" &&  (<StatusDropdown
                    currentStatus={inventorysellReq?.status}
                    onStatusChange={(newStatus) => {
                      setStatus(newStatus);
                      handleStatusChange(newStatus);
                    }}
                    disableCondition={false}
                    allowedLabels={['Cancelled']}
                  />)}

{CommonService.isUser() && inventorysellReq?.status === "UNDER_REVIEW" &&  (<StatusDropdown
                    currentStatus={inventorysellReq?.status}
                    onStatusChange={(newStatus) => {
                      setStatus(newStatus);
                      handleStatusChange(newStatus);
                    }}
                    disableCondition={false}
                    allowedLabels={['Cancelled']}
                  />)}

                  {CommonService.isAdmin() && (
                    <>
                      <StatusDropdown
                        currentStatus={inventorysellReq?.status}
                        onStatusChange={(newStatus) => {
                          setStatus(newStatus);
                          handleStatusChange(newStatus);
                        }}
                        disableCondition={false}
                        allowedLabels={['Accepted', 'Rejected', 'Under Review']}
                      />

                      {status === "Accepted" && isHandleShow && (
                        <SingleApproveCSV
                          fetchData={fetchData}
                          inventoryCsv={inventorysellReq}
                          isFromDropdown={true}
                          popupShow={isHandleShow}
                        />
                      )}

                      {status === "Rejected" && isHandleShow && (
                        <SingleRejectCSV
                          fetchData={fetchData}
                          rejectUser={inventorysellReq}
                          isFromDropdown={true}
                          popupShow={isHandleShow}
                        />
                      )}
                    </>
                  )}
                </>
              )}

              {inventorysellReq?.status === "ACCEPTED" && (
                <>

                  <StatusDropdown
                    currentStatus={inventorysellReq?.status}
                    onStatusChange={(newStatus) => {
                      setStatus(newStatus);
                      handleStatusChange(newStatus);
                    }}
                    disableCondition={CommonService.isAdmin()}
                    allowedLabels={["Finalized"]}
                  />

                  {status === "Finalized" && isHandleShow && (
                    <SinglePublishCSV
                      fetchData={fetchData}
                      inventoryCsv={inventorysellReq}
                      isFromDropdown={true}
                      popupShow={isHandleShow}
                    />
                  )}
                </>
              )}

              {inventorysellReq?.status === "FINALIZED" && (
                <>
                  <StatusDropdown
                    currentStatus={inventorysellReq?.status}
                    onStatusChange={(newStatus) => {
                      setStatus(newStatus);
                      handleStatusChange(newStatus);
                    }}
                    disableCondition={CommonService.isUser()}
                    allowedLabels={["Published"]}
                  />
                  {status === "Published" && isHandleShow && (
                    <SinglePublishCSV
                      fetchData={fetchData}
                      inventoryCsv={inventorysellReq}
                      isFromDropdown={true}
                      popupShow={isHandleShow}
                    />
                  )}
                </>
              )}

              {(inventorysellReq?.status === "CANCELLED" ||
                inventorysellReq?.status === "REJECTED" ||
                inventorysellReq?.status === "PUBLISHED") && (
                  <StatusDropdown
                    currentStatus={inventorysellReq?.status}
                    onStatusChange={() => { }}
                    disableCondition={true}
                    allowedLabels={[]}
                  />
                )}


            </div>
          </div>
        </div>
      </div>

      <div className="inventory__sell--wrapper d-flex flex-column flex-lg-row justify-content-between">
        <div className="flex-grow-1">
          <div className="user-table-container user-table-container-inventoryFiles bg-white">
            <div className="userTable inventoryFilesTable row d-flex m-0">
              <div className="col-xl-6 px-0">
                <div className="title-bar d-flex justify-content-between align-items-center">
                  <h3 className="mb-0">List of Inventory Files</h3>
                  <UploadDocs
                    fetchData={fetchData}
                    csvId={id}
                    uploadCSV={true}
                    makeDisable={
                      inventorysellReq?.status === "REJECTED" ||
                        inventorysellReq?.status === "PENDING_REVIEW"
                        ? false
                        : true
                    }
                  />
                </div>
                <div
                  className="inventory-file-list-table"
                  style={{ height: 350, width: "100%" }}
                >
                  {isLoading ? (
            <>
              <Skeleton
                animation="wave"
                variant="rounded"
                // height={550}
                style={{ height: "100%" }}
              />
            </>
          ) : (<><DataGrid
            localeText={localeText}
            rows={tableData}
            columns={columnsForCsv}
            className="data-grid"
            hideFooterPagination
          /></>)}
                  
                </div>
              </div>
              <div className="col-xl-6 px-0">
                <div className="title-bar">
                  <h3 className="mt-2 mb-4">Additional Info</h3>
                  <hr className="my-0" />
                </div>
                <List>
                  <ListItem>
                    <ListItemAvatar sx={{ minWidth: 'unset', }}>
                      <Avatar
                        alt="Image"
                        src={QuestionIcon}
                      />
                    </ListItemAvatar>
                    <ListItemText primary={`Is there a requirement to relocate the barrels?`} sx={{ ml: 1, color: '#8F9195', fontWeight: 600, fontSize: '16px' }} />
                    <Typography variant="body2" sx={{ color: '#333333', fontSize: '16px', textWrap: 'nowrap' }}>
                    {inventorysellReq?.isRelocationRequired ? (
                          <>
                              <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                          </>
                      ) : (
                          <>
                              <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                          </>
                    )}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar sx={{ minWidth: 'unset', }}>
                      <Avatar
                        alt="Image"
                        src={QuestionIcon}
                      />
                    </ListItemAvatar>
                    <ListItemText primary={`Is there a requirement to change account?`} sx={{ ml: 1, color: '#8F9195', fontWeight: 600, fontSize: '16px' }} />
                    <Typography variant="body2" sx={{ color: '#333333', fontSize: '16px', textWrap: 'nowrap' }}>
                      {inventorysellReq?.isAccountTransfer ? (
                            <>
                                <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                            </>
                        ) : (
                            <>
                                <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                            </>
                      )}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar sx={{ minWidth: 'unset', }}>
                      <Avatar
                        alt="Image"
                        src={QuestionIcon}
                      />
                    </ListItemAvatar>
                    <ListItemText primary={`Are inventories stored at 3rd party facility?`} sx={{ ml: 1, color: '#8F9195', fontWeight: 600, fontSize: '16px' }} />
                    <Typography variant="body2" sx={{ color: '#333333', fontSize: '16px', textWrap: 'nowrap' }}>
                      {inventorysellReq?.isThirdPartyStorage ? (
                            <>
                                <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                            </>
                        ) : (
                            <>
                                <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                            </>
                      )}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar sx={{ minWidth: 'unset', }}>
                      <Avatar
                        alt="Image"
                        src={QuestionIcon}
                      />
                    </ListItemAvatar>
                    <ListItemText primary={`Are inventories available to make an offer?`} sx={{ ml: 1, color: '#8F9195', fontWeight: 600, fontSize: '16px' }} />
                    <Typography variant="body2" sx={{ color: '#333333', fontSize: '16px', textWrap: 'nowrap' }}>
                      {inventorysellReq?.isMakeAnOffer ? (
                            <>
                                <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                            </>
                        ) : (
                            <>
                                <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                            </>
                      )}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar sx={{ minWidth: 'unset', }}>
                      <Avatar
                        alt="Image"
                        src={QuestionIcon}
                      />
                    </ListItemAvatar>
                    <ListItemText primary={`Does the company own the uploaded inventory?`} sx={{ ml: 1, color: '#8F9195', fontWeight: 600, fontSize: '16px' }} />
                    <Typography variant="body2" sx={{ color: '#333333', fontSize: '16px', textWrap: 'nowrap' }}>
                      {inventorysellReq?.areYouOwner ? (
                            <>
                                <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                            </>
                        ) : (
                            <>
                                <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                            </>
                      )}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar sx={{ minWidth: 'unset', }}>
                      <Avatar
                        alt="Image"
                        src={QuestionIcon}
                      />
                    </ListItemAvatar>
                    <ListItemText primary={`Owner name`} sx={{ ml: 1, color: '#8F9195', fontWeight: 600, fontSize: '16px' }} />
                    <Typography variant="body2" sx={{ color: '#333333', fontSize: '16px', minWidth: '50px', textAlign: 'center' }}>
                      {inventorysellReq?.ownerName || '-'}
                    </Typography>
                  </ListItem>
                </List>
              </div>
              <div className="col-xl-6 px-0">
                <div className="title-bar d-flex justify-content-between align-items-center">
                  <h3 className="mb-0">List of Documents</h3>
                  <UploadDocs
                    fetchData={fetchData}
                    csvId={id}
                    uploadCSV={false}
                    requestType={"SELL_REQUEST"}
                  />
                </div>
                <div
                  className="inventory-doc-table"
                  style={{ height: 350, width: "100%" }}
                >
                  {isLoading ? (
            <>
              <Skeleton
                animation="wave"
                variant="rounded"
                // height={550}
                style={{ height: "100%" }}
              />
            </>
          ) : (<><DataGrid
            localeText={localeText}
            rows={inventoryDocs}
            columns={columnsForDoc}
            className="data-grid"
            hideFooterPagination
          /></>)}
                </div>
              </div>
              <div className="col-xl-6">
                <div className="recentActivity">
                  <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                    <h3 className="mb-2">Recent Activity</h3>
                  </div>
                  <div>
                    <HistoryStepper historyData={historyData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="msgContainerBox">
        {isMessageBoxOpen && (<ContainerMessageBox requestType={"SELL_REQUEST"} sellerCompanyId={null} isSeller={false}/>)}
        </div>
        {/* <div className="row m-0">
          <div className="col-xxl-4 pe-0">
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ViewSellRequest;
function fetchData() {
  throw new Error("Function not implemented.");
}
