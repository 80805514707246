import React, { useEffect } from "react";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import SailingRoundedIcon from "@mui/icons-material/SailingRounded";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import SyncAltOutlinedIcon from "@mui/icons-material/SyncAltOutlined";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import NoteOutlinedIcon from "@mui/icons-material/NoteOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import UpdateSharpIcon from "@mui/icons-material/UpdateSharp";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import RocketOutlinedIcon from "@mui/icons-material/RocketOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import HandshakeSharpIcon from "@mui/icons-material/HandshakeSharp";
// import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import ExpandMore from '../../assets/images/icons/ExpandMore.svg';
import ReplayIcon from "@mui/icons-material/Replay";
import CloseIcon from "@mui/icons-material/Close";
import UnderReviewBySeller from "../../assets/images/icons/UnderReviewBySellerIcon.svg";
import SellerAccepted from "../../assets/images/icons/SellerAcceptedIcon.svg";
import SellerRejected from "../../assets/images/icons/SellerRejectedIcon.svg";
import UnderReviewByWDTP from "../../assets/images/icons/UnderReviewByWDTPIcon.svg";
import InvoiceRaised from "../../assets/images/icons/InvoiceRaisedIcon.svg";
import Transit from '@mui/icons-material/TransferWithinAStation'
import SendIcon from '@mui/icons-material/Send';
import UserPlus from "../../assets/images/icons/UserPlus.svg";
import UserCheck from "../../assets/images/icons/UserCheck.svg";
import UserSend from "../../assets/images/icons/UserSend.svg";
import AdminAcceptedIcon from "../../assets/images/icons/AdminAccepted.svg";
import AdminRejectedIcon from "../../assets/images/icons/AdminRejected.svg";
import AcceptRejectIcon from "../../assets/images/icons/AcceptRejectIcon.svg";
import ActionRequiredIcon from "../../assets/images/icons/ActionRequired.svg";
import InProgress from "../../assets/images/icons/InProgress.svg";
import { SupportApiService } from "../../Service/SupportApiService";
import { CommonService } from "../../Service/CommonService";
import Spinner from "./Spinner";


// Create a simple global event system for dropdowns
const dropdownEventSystem = {
  currentOpenDropdownId: null,
  listeners: new Set<Function>(),
  
  setOpenDropdown(id: string | null) {
    this.currentOpenDropdownId = id;
    this.notifyListeners();
  },
  
  subscribe(listener: Function) {
    this.listeners.add(listener);
    return () => this.listeners.delete(listener);
  },
  
  notifyListeners() {
    this.listeners.forEach(listener => listener(this.currentOpenDropdownId));
  }
};

interface StatusOption {
  key: string;
  label: string;
  icon: React.ReactNode;
  action: string;
}

interface StatusDropdownProps {
  currentStatus: string;
  onStatusChange: (newStatus: string) => void;
  disableCondition: boolean;
  allowedLabels: string[];
  serviceTypeId?: any;
}

const StatusDropdown: React.FC<StatusDropdownProps> = ({
  currentStatus,
  onStatusChange,
  disableCondition,
  allowedLabels,
  serviceTypeId,
}) => {
  // Generate a unique ID for this dropdown instance
  const dropdownId = React.useRef(
    `dropdown-${Math.random().toString(36).substring(2, 9)}`
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const [dynamicLabels, setDynamicLabels] = React.useState<string[]>(allowedLabels);
  const [isLoading, setIsLoading] = React.useState(false);


  // Subscribe to the global dropdown event system
  useEffect(() => {
    const unsubscribe = dropdownEventSystem.subscribe((openDropdownId: string | null) => {
      // Close this dropdown if another one opens
      if (openDropdownId !== dropdownId.current && isOpen) {
        setIsOpen(false);
      }
    });

    return unsubscribe;
  }, [isOpen]);

  // useEffect (() => {

  //   if(serviceTypeId) {
  //     getAllStatus();
  //   }
  // },[])

  // Handle dropdown toggle
  const handleToggle = (nextIsOpen: boolean) => {
    if (nextIsOpen) {
      // Notify the system that this dropdown is now open
      dropdownEventSystem.setOpenDropdown(dropdownId.current);
    }

    if (serviceTypeId && !isOpen) {
      getAllStatus();
    }
    setIsOpen(nextIsOpen);
  };

  const statusOptions: StatusOption[] = [
    {
      key: "Invited",
      action: "Invite",
      label: "Invited",
      icon: <SendIcon sx={{ color: "#328fa8" }} fontSize="small" />,
    },
    {
      key: "Pending Review",
      action: "Pending Review",
      label: "Pending Review",
      icon: (
        <HourglassEmptyOutlinedIcon
          sx={{ color: "#737376" }}
          fontSize="small"
        />
      ),
    },
    {
      key: "Submit for Review",
      action: "Submit for Review",
      label: "Submit for Review",
      icon: (
        <UploadFileOutlinedIcon sx={{ color: "#952698" }} fontSize="small" />
      ),
    },
    {
      key: "Approved",
      action: "Approve",
      label: "Approved",
      icon: <VerifiedOutlinedIcon sx={{ color: "#FF8B03" }} fontSize="small" />,
    },
    {
      key: "Shipped",
      action: "Ship",
      label: "Shipped",
      icon: <SailingRoundedIcon sx={{ color: "#28C1B8" }} fontSize="small" />,
    },
    {
      key: "Contract Sign",
      action: "Sign Contract",
      label: "Contract Sign",
      icon: (
        <StickyNote2OutlinedIcon sx={{ color: "#9025B6" }} fontSize="small" />
      ),
    },
    {
      key: "Pending for Contract",
      action: "Pending for Contract",
      label: "Pending for Contract",
      icon: <NoteOutlinedIcon sx={{ color: "#80A72D" }} fontSize="small" />,
    },
    {
      key: "Under Review",
      action: "Under Review",
      label: "Under Review",
      icon: <UpdateSharpIcon sx={{ color: "#D849B0" }} fontSize="small" />,
    },
    {
      key: "Accepted",
      action: "Accept",
      label: "Accepted",
      icon: <ThumbUpOffAltIcon sx={{ color: "#5fb73a" }} fontSize="small" />,
    },
    {
      key: "Finalized",
      action: "Finalize",
      label: "Finalized",
      icon: <FlagOutlinedIcon sx={{ color: "#39678A" }} fontSize="small" />,
    },
    {
      key: "Published",
      action: "Publish",
      label: "Published",
      icon: (
        <RocketLaunchOutlinedIcon sx={{ color: "#7E9C97" }} fontSize="small" />
      ),
    },
    {
      key: "Unpublished",
      action: "Unpublish",
      label: "Unpublished",
      icon: <RocketOutlinedIcon sx={{ color: "#E48486" }} fontSize="small" />,
    },
    {
      key: "In Progress",
      action: "In Progress",
      label: "In Progress",
      icon: <img src={InProgress} />,
    },
    {
      key: "Uploaded",
      action: "Upload",
      label: "Uploaded",
      icon: <FlagOutlinedIcon sx={{ color: "#39678A" }} fontSize="small" />,
    },
    {
      key: "Contract Sent",
      action: "Send Contract",
      label: "Contract Sent",
      icon: (
        <EventNoteOutlinedIcon sx={{ color: "#D3A54D" }} fontSize="small" />
      ),
    },
    {
      key: "Contract Executed",
      action: "Execute Contract",
      label: "Contract Executed",
      icon: <HandshakeSharpIcon sx={{ color: "#B89273" }} fontSize="small" />,
    },
    {
      key: "Invoice Sent",
      action: "Send Invoice",
      label: "Invoice Sent",
      icon: (
        <UploadFileOutlinedIcon sx={{ color: "#9BB078" }} fontSize="small" />
      ),
    },
    {
      key: "Payment Received",
      action: "Payment Receive",
      label: "Payment Received",
      icon: (
        <MonetizationOnOutlinedIcon
          sx={{ color: "#586C53" }}
          fontSize="small"
        />
      ),
    },
    {
      key: "Ownership Transferred",
      action: "Transfer Ownership",
      label: "Ownership Transferred",
      icon: <SyncAltOutlinedIcon sx={{ color: "#4687D4" }} fontSize="small" />,
    },
    {
      key: "Under Review by Seller",
      action: "Under Review by Seller",
      label: "Under Review by Seller",
      icon: <img src={UnderReviewBySeller} />,
    },
    {
      key: "Seller Accepted",
      action: "Accept",
      label: "Seller Accepted",
      icon: <img src={SellerAccepted} />,
    },
    {
      key: "Seller Rejected",
      action: "Reject",
      label: "Seller Rejected",
      icon: <img src={SellerRejected} />,
    },
    {
      key: "Under Review by WDTP",
      action: "Under Review by WDTP",
      label: "Under Review by WDTP",
      icon: <img src={UnderReviewByWDTP} />,
    },
    {
      key: "Invoice Raised",
      action: "Raise Invoice",
      label: "Invoice Raised",
      icon: <img src={InvoiceRaised} />,
    },
    {
      key: "Delivered",
      action: "Deliver",
      label: "Delivered",
      icon: (
        <LocalShippingOutlinedIcon sx={{ color: "#25B634" }} fontSize="small" />
      ),
    },
    {
      key: "In Transit",
      action: "In Transit",
      label: "In Transit",
      icon: <Transit />,
    },
    {
      key: "Cancelled",
      action: "Cancel",
      label: "Cancelled",
      icon: <CancelOutlinedIcon sx={{ color: "#B10E1C" }} fontSize="small" />,
    },
    {
      key: "Send to Owner",
      action: "Send to Owner",
      label: "Send to Owner",
      icon: <img src={UserSend} />,
    },
    {
      key: "Accept for Owner",
      action: "Accept for Owner",
      label: "Accept for Owner",
      icon: <img src={UserCheck} />,
    },
    {
      key: "Reject for Owner",
      action: "Reject for Owner",
      label: "Reject for Owner",
      icon: <img src={UserPlus} />,
    },
    {
      key: "Admin Rejected",
      action: "Admin Rejected",
      label: "Admin Rejected",
      icon: <img src={AdminRejectedIcon} />,
    },
    {
      key: "Admin Accepted",
      action: "Admin Accepted",
      label: "Admin Accepted",
      icon: <img src={AdminAcceptedIcon} />,
    },
    {
      key: "Accepted Rejected",
      action: "Accepted | Rejected",
      label: "Accepted | Rejected",
      icon: <img src={AcceptRejectIcon} />,
    },
    {
      key: "Action Required",
      action: "Action Required",
      label: "Action Required",
      icon: <img src={ActionRequiredIcon} />,
    },
    {
      key: "Open",
      action: "Open",
      label: "Open",
      icon: <ReplayIcon sx={{ transform: "rotate(-90deg)" }} />,
    },
    {
      key: "Re Processing",
      action: "Re-Processing",
      label: "Re-Processing",
      icon: <img src={InProgress} />,
    },
    {
      key: "Rejected",
      action: "Reject",
      label: "Rejected",
      icon: <NotInterestedIcon sx={{ color: "#EC2323" }} fontSize="small" />,
    },
    {
      key: "Closed",
      action: "Close",
      label: "Closed",
      icon: <CloseIcon sx={{ marginLeft: "-4px" }} />,
    },
    {
      key: "Open",
      action: "Reopen",
      label: "Reopen",
      icon: <img src={AdminAcceptedIcon} />,
    },
  ];

  const styles = {
    scrollableMenu: {
      height: "auto",
      maxHeight: "300px",
      overflowX: "hidden",
      overflowY: "auto",
    } as React.CSSProperties,
    scrollbarStyles: `
      .scrollable-menu::-webkit-scrollbar {
        width: 6px;
      }
      .scrollable-menu::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 3px;
      }
      .scrollable-menu::-webkit-scrollbar-thumb:hover {
        background-color: #555;
      }
    `,
  };

  const getVariant = (status: string) => {
    switch (status?.toUpperCase().replace(/ /g, "_")) {
      case "APPROVED":
        return "approved";
      case "PENDING_REVIEW":
        return "pending-review";
      case "REJECTED":
        return "rejected";
      case "ACCEPTED":
        return "accepted";
      case "CANCELLED":
        return "cancelled";
      case "SHIPPED":
        return "shipped";
      case "DELIVERED":
        return "delivered";
      case "PUBLISHED":
        return "published";
      case "SUBMIT_FOR_REVIEW":
        return "submit-for-review";
      case "OWNERSHIP_TRANSFERRED":
        return "ownership-transferred";
      case "CONTRACT_SIGN":
        return "contract-sign";
      case "PENDING_FOR_CONTRACT":
        return "pending-for-contract";
      case "UNDER_REVIEW":
        return "under-review";
      case "FINALIZED":
        return "finalized";
      case "UNPUBLISHED":
        return "Unpublished";
      case "CONTRACT_SENT":
        return "contract-sent";
      case "CONTRACT_EXECUTED":
        return "contract-executed";
      case "INVOICE_SENT":
        return "invoice-sent";
      case "PAYMENT_RECEIVED":
        return "payment-received";
      case "UNDER_REVIEW_BY_SELLER":
        return "under-review-by-seller";
      case "SELLER_ACCEPTED":
        return "seller-accepted";
      case "SELLER_REJECTED":
        return "seller-rejected";
      case "UNDER_REVIEW_BY_WDTP":
        return "under-review-by-wdtp";
      case "INVOICE_RAISED":
        return "invoice-raised";
      case "IN_TRANSIT":
        return "in-transit";
      case "INVITED":
        return "invite";
      case "ACTION_REQUIRED":
        return "action-required";
      case "ADMIN_ACCEPTED":
        return "admin-accepted";
      case "ADMIN_REJECTED":
        return "admin-rejected";
      case "ACCEPTED_REJECTED":
        return "accepted-rejected";
      case "ACCEPT_FOR_OWNER":
        return "accept-for-owner";
      case "REJECT_FOR_OWNER":
        return "reject-for-owner";
      case "SEND_TO_OWNER":
        return "send-to-owner";
      case "CLOSED":
        return "closed";
      case "IN_PROGRESS":
        return "in-progress";
      case "RE_PROCESSING":
        return "re-processing";
      case "UPLOADED":
        return "uploaded";
      case "UPLOAD_FAILED":
        return "upload-failed";
      default:
        return "none";
    }
  };

  //it with give the current icon
  const getStatusIcon = (status: string) => {
    const option = statusOptions.find(
      (opt) =>
        opt.key?.toUpperCase().replace(/ /g, "_") ===
        status?.toUpperCase().replace(/ /g, "_")
    );
    return option ? option.icon : null;
  };

  //filter the label which is coming from props
  const getCurrentLabel = (status: string) => {
    const option = statusOptions.find(
      (opt) =>
        opt.key?.toUpperCase().replace(/[\s-]/g, "_") ===
        status?.toUpperCase().replace(/[\s-]/g, "_")
    );
    return option ? option.label : status;
  };

  // const filteredOptions = statusOptions?.filter(
  //   (option) =>
  //     option.key?.toUpperCase().replace(/[\s-]/g, "_") !==
  //       currentStatus?.toUpperCase().replace(/[\s-]/g, "_") &&
  //     allowedLabels.includes(option.label)
  // );

  // Use dynamicLabels for filtering
  const filteredOptions = statusOptions?.filter(
    (option) =>
      option.key?.toUpperCase().replace(/[\s-]/g, "_") !==
        currentStatus?.toUpperCase().replace(/[\s-]/g, "_") &&
      ((dynamicLabels.length > 0 && serviceTypeId)
        ? dynamicLabels.includes(option.label)
        :allowedLabels.includes(option.label))
  );

  // Function to fetch status options
  const getAllStatus = async () => {

    if(dynamicLabels.length > 0 ){
      return ;
    }
    try {
      setIsLoading(true);
      const response = await SupportApiService.getAllStatusByServiceTypeId(
        serviceTypeId,
        currentStatus
      );
      setDynamicLabels(CommonService.transformStatusList(response?.data || []));
    } catch (error) {
      console.log("Error occurred while fetching statuses:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <style>{styles.scrollbarStyles}</style>
      <Dropdown as={ButtonGroup} show={isOpen} onToggle={handleToggle} className="align-items-center">
        {/* {isLoading && <Spinner/>} */}
        {/* <Spinner/> */}
        <Dropdown.Toggle
          variant={getVariant(currentStatus)}
          disabled={disableCondition}
          className="dropdown-status d-flex align-items-center"
        >
          {getStatusIcon(currentStatus)}{" "}
          <span>
            {getCurrentLabel(currentStatus)}{" "}
            {!disableCondition && (
              <img src={ExpandMore} alt="CompanyIcn" width={12} height={7} />
            )}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="scrollable-menu" style={styles.scrollableMenu}>
        {isLoading ? (
          <Dropdown.Item className="d-flex align-items-center">
            <Spinner/> Loading...
          </Dropdown.Item>
        ) : (
          filteredOptions.map((option) => (
            <Dropdown.Item
              key={option.key}
              onClick={() => onStatusChange(option.key)}
              className={`dropdown-item-${option.label
                .toLowerCase()
                .replace(/ /g, "-")}`}
            >
              {option.icon} {option.action}
            </Dropdown.Item>
          ))
        )}
        
      </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default StatusDropdown;
