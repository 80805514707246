import React, { useEffect, useState } from "react";
import { OrderApiService } from "../../Service/OrderApiService";
import { CommonService } from "../../Service/CommonService";
import useNavigate, { useHistory } from 'react-router-dom';

interface SameInventoryOrdersProps {
  inventoryId: string;
  requestId: string;
}

const SameInventoryOrders: React.FC<SameInventoryOrdersProps> = ({ inventoryId, requestId }) => {
  const [orders, setOrders] = useState<any[]>([]);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      setIsLoading(true);
      try {
        const response = await OrderApiService.getSameInventoryOrders(inventoryId, requestId);
        setOrders(response.data);
      } catch (error) {
        console.error("Error occurred while fetching orders:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (inventoryId && requestId) {
      fetchOrders();
    }
  }, [inventoryId, requestId]);

  
  const navigateToOrderDetails = (orderId: string, requestType: string) => {
    const isAdmin = CommonService.isAdmin();
    const baseRoute = isAdmin ? 'master' : 'user';
    
    // Determine the detail type based on request type
    let detailType = '';
    if (requestType === 'Order Request') {
      detailType = 'order-detail';
    } else if (requestType === 'Offer Request') {
      detailType = 'view-offer-request';
    } else {
      console.error('Unknown request type:', requestType);
      return; // Early return for unknown request types
    }
    
    // Construct the path and navigate
    const path = `/${baseRoute}/${detailType}/${orderId}`;
      // Open in new tab
    const url = window.location.origin + path;
    window.open(url, '_blank');

    };

  return (
    <div className="same-order-table-wrapper table-responsive">

      
      <table>
        <thead>
          <tr>
            <th>Request Number</th>
            <th>Request Type</th>
            <th>Company Name</th>
            <th>Requested By</th>
            <th>Requested On</th>
            <th>Total Quantity</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={6} className="text-center p-4">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <p className="mt-2">Loading orders...</p>
              </td>
            </tr>
          ) : orders.length > 0 ? (
            orders.map((order, index) => (
              <tr key={index}>
                <td>
                  <span 
                    className="order-link"
                    onClick={(event) => {
                      event.stopPropagation();
                      navigateToOrderDetails(order?.orderId, order?.requestType)
                    }}
                  >
                    {order.requestNumber}
                  </span>
                </td>
                <td>{order.requestType || "-"}</td>
                <td>{order.companyName || "-"}</td>
                <td>{order.requestedBy || "-"}</td>
                <td>{CommonService.formatDateTime(order.requestedOn)}</td>
                <td>{`${order.quantity} barrels` || "-"}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6} className="text-center">
                No orders found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      
    </div>
  );
};

export default SameInventoryOrders;
