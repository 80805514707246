import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Container, Typography } from '@mui/material';
import { Spinner } from 'react-bootstrap';
import { CommonService } from '../../Service/CommonService';
import Offcanvas from 'react-bootstrap/Offcanvas';
import '../Dynamic/UserList.scss';
import { InventoryApiService } from '../../Service/InventoryApiService';
import CKContent from '../Common/CKcontent';

interface ProductProps {
    handleViewClose: () => void;
    inventoryId: any;
}

const ViewProductDetail: React.FC<ProductProps> = ({ handleViewClose, inventoryId }) => {
    const parentRef = useRef<HTMLDivElement>(null);
    const child1Ref = useRef<HTMLDivElement>(null);
    const child2Ref = useRef<HTMLDivElement>(null);
    const child3Ref = useRef<HTMLDivElement>(null);

    const [parentHeight, setParentHeight] = useState<number | null>(null);
    const [child1Height, setChild1Height] = useState<number | null>(null);
    const [child2Height, setChild2Height] = useState<number | null>(null);
    const [remainingHeight, setRemainingHeight] = useState<number | null>(null);
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
    const [isLoading, setLoading] = useState<boolean>(true);

    const calculateHeights = () => {
        const width = window.innerWidth;
        setScreenWidth(width);

        if (parentRef.current) {
            const pHeight = parentRef.current.clientHeight;
            setParentHeight(pHeight);

            if (width >= 1200 && child1Ref.current) {
                // Case for screen width >= 1200px
                const c1Height = child1Ref.current.clientHeight;
                setChild1Height(c1Height);
                setRemainingHeight(pHeight - c1Height - 40);
                console.log(child1Height)
                console.log(child2Height)
                console.log(parentHeight)
                console.log(remainingHeight)
            } else if (width < 1200 && child1Ref.current && child2Ref.current) {
                // Case for screen width < 1200px
                const c1Height = child1Ref.current.clientHeight;
                const c2Height = child2Ref.current.clientHeight;
                setChild1Height(c1Height);
                setChild2Height(c2Height);
                setRemainingHeight(pHeight - (c1Height + c2Height) - 40);
                console.log(child1Height)
                console.log(child2Height)
                console.log(parentHeight)
                console.log(remainingHeight)
            }
        }
    };

    // Run calculations when loading is false and on resize
    useEffect(() => {
        if (!isLoading) {
            calculateHeights();
            window.addEventListener("resize", calculateHeights);
        }

        return () => {
            window.removeEventListener("resize", calculateHeights);
        };
    }, [isLoading]);
    
    const [productDetails, setProductDetails] = useState<any>(null);

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                setLoading(true);
                const response = await InventoryApiService.getProductDetailsByInventoryId(inventoryId);
                setProductDetails(response.data);
            } catch (error) {
                console.error("Error occurred while fetching product details:", error);
            } finally {
                setLoading(false);
            }
        };

        if (inventoryId) {
            fetchProductDetails();
        }
    }, [inventoryId]);

    const calculateAge = (dateString: string): string => {
        const currentDate = new Date();
        const givenDate = new Date(dateString);
    
        let years = currentDate.getFullYear() - givenDate.getFullYear();
        let months = currentDate.getMonth() - givenDate.getMonth();
    
        if (months < 0) {
            years--;
            months += 12;
        }
    
        return `${years} years ${months} months`;
    };

    return (
        <>
            <div className={`${CommonService.isUser() ? "view-detail-for-user" : "view-detail-for-admin"}`} ref={parentRef} >
                <div className='view-detail-left' onClick={handleViewClose}></div>
                <div className='view-detail-inner' >
                {isLoading ? (
                    // 
                    <div className='h-100'>
                        <div className='header' ref={child1Ref}>
                            <div className='header-inner d-flex justify-content-end'>
                                <button onClick={handleViewClose} className='close-btn'><CloseIcon /></button>
                            </div>
                        </div>
                            <div className='view-detail-body h-100'>
                                <div className='h-100 w-100 d-flex flex-column align-items-center justify-content-center view-detail-loader'>
                                    {/* fetching product details .............. */}
                                </div>
                            </div>
                    </div> 
                ) : (
                    <div>
                        <div className='header' ref={child1Ref}>
                            <div className='header-inner d-flex justify-content-between align-items-start'>
                                <div>
                                    <p className='main-heading'>{productDetails?.title}</p>
                                    <p className='sub-heading'>By: {productDetails?.producer}</p>
                                </div>
                                <button onClick={handleViewClose} className='close-btn'><CloseIcon /></button>
                            </div>
                        </div>
                        <div className='view-detail-body'>
                            <div className='row m-0'>
                                <div className='col-xl-6 ps-0 pe-0 pe-lg-2'>
                                    <div className='left-inner h-100' ref={child2Ref}>
                                        <div className='left-heading'>
                                            <p>About {productDetails?.title} Sample Product</p>
                                        </div>
                                        <div className='left-content'>
                                        <CKContent
                                            content={productDetails?.description}
                                            className="custom-class"
                                        />
                                        </div>
                                        <div className='left-sub-heading mt-2'>
                                            <p>Grab your commemorative barrel today!</p>
                                        </div>
                                        <div className='left-sub-content'>
                                            <p>There are not many things more American than bourbon, and although most of it is produced in Kentucky, it can be produced all over the USA.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-6 pe-0 ps-0 ps-lg-2'>
                                    <div className='right-inner' ref={child3Ref} style={{ height: remainingHeight || 0}}>
                                        <table>
                                            <tbody>
                                            <tr>
                                                    <td className='key'>VOLUME</td>
                                                    <td className='value'>{`${productDetails?.volume} ${productDetails?.volumeUnit}`  || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>BARREL SIZE</td>
                                                    <td className='value'>{productDetails?.barrelSize || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>MASH BILL</td>
                                                    <td className='value'>{productDetails?.mashBill || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>PROOF</td>
                                                    <td className='value'>{productDetails?.proof ? `${productDetails.proof} pf` : 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>PROOF GALLONS</td>
                                                    <td className='value'>{productDetails?.proofGallons ? `${productDetails.proofGallons} pf-gal` : 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>SPIRIT</td>
                                                    <td className='value'>{productDetails?.ttbType || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>SPIRIT AGE</td>
                                                    <td className='value'>{calculateAge(productDetails?.fillDate) || 'N/A'}</td>
                                                </tr>   
                                                <tr>
                                                    <td className='key'>STAGE</td>
                                                    <td className='value'>{productDetails?.stage || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>FACILITY</td>
                                                    <td className='value'>{productDetails?.facility || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>LOT ID</td>
                                                    <td className='value'>{productDetails?.lotNo || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>BATCH NAME</td>
                                                    <td className='value'>{productDetails?.batchName || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>FILL DATE</td>
                                                    <td className='value'>{productDetails?.fillDate || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>BARREL #</td>
                                                    <td className='value'>{productDetails?.barrelNumber || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>BARREL PROFILE</td>
                                                    <td className='value'>{productDetails?.barrelProfile || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>TYPE</td>
                                                    <td className='value'>{productDetails?.ttbType || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>TASTING NOTE</td>
                                                    <td className='value'>{productDetails?.tastingNote || 'N/A'}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                )}
                </div>
            </div>
        </>
    )
}

export default ViewProductDetail;