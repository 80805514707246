 const Constant ={

     Role :{
        ADMIN :"ADMIN",
        SUPER_ADMIN : "SUPER_ADMIN",
        USER : "USER"
      },

      RequestType :{
        ORDER_REQUEST : "ORDER_REQUEST",
        SAMPLE_REQUEST : "SAMPLE_REQUEST",
        OFFER_REQUEST : "OFFER_REQUEST",
        SELL_REQUEST : "SELL_REQUEST",
        CUSTOMER_SERVICE_REQUEST : "CUSTOMER_SERVICE_REQUEST",
      },

      OrderStatus :{
        CART : "CART",
        PENDING_REVIEW : "PENDING_REVIEW",
        ACCEPTED : "ACCEPTED",
        REJECTED : "REJECTED",
        SHIPPED : "SHIPPED",
        CANCELLED : "CANCELLED",
        DELIVERED : "DELIVERED",
        SELLER_REJECTED: "SELLER_REJECTED",
        ADMIN_REJECTED: "ADMIN_REJECTED",
        IN_TRANSIT : "IN_TRANSIT",
        OWNERSHIP_TRANSFERRED : "OWNERSHIP_TRANSFERRED",
        CLOSED : "CLOSED"
      },

      SampleRequestStatus :{
        PENDING_REVIEW : "PENDING_REVIEW",
        UNDER_REVIEW : "UNDER_REVIEW",
        ACCEPTED : "ACCEPTED",
        REJECTED : "REJECTED", 
        APPROVED : "APPROVED",
        ACTION_REQUIRED : "ACTION_REQUIRED",
        IN_TRANSIT : "IN_TRANSIT",
        CANCELLED : "CANCELLED",          
      },

      CustomerServiceRequestStatus : {
        PENDING_REVIEW : "PENDING_REVIEW",
        UNDER_REVIEW : "UNDER_REVIEW",
        ACCEPTED : "ACCEPTED",
        REJECTED : "REJECTED",
        CANCELLED : "CANCELLED",
        CLOSED: "CLOSED",
        PUBLISHED: "PUBLISHED",
        OWNERSHIP_TRANSFERRED: "OWNERSHIP_TRANSFERRED",
        UPLOADED: "UPLOADED"
      },


      facility : {
        WHITEDOG_TRADING_STORAGE : "WhiteDog Trading Storage",
        WDTS : "WDTS",
      } ,

      Profile : {
        OWNER : 'Owner',
        BUYER : 'Buyer'
      },
    

      CustomerService : {
        PRIVATE_SELL : 'Private Sell',
        BULK_INVENTORY_UPLOAD : 'Bulk Inventory Upload',
        OWNERSHIP_TRANSFER: "Ownership Transfer",
        SELL_REQUEST: "Sell Request",
      },

      Field : {
        DESCRIPTION : 'Description',
        PRICE : 'Price',
        COMMISSION_FEE :  'Commission Fee',
        FEE_TYPE : 'Fee Type',
      },

      FIELD_TYPES : {
        COMPANY_DROPDOWN: "company_dropdown",
        USER_DROPDOWN: "user_dropdown",
        ADD_INVENTORY: "add_inventory",
        ADD_BARREL: "add_barrel",
        PRODUCT_DROPDOWN: "product_dropdown",
        FEE_TYPE_DROPDOWN: "fee_type_dropdown",
        PRICE: "price",
        QUANTITY: "quantity",
        NUMBER: "number",
        RADIO: "radio",
        TEXT: "text",
        COMMENT: "comment",
        RICH_TEXT: "rich_text",
        DATE: "date",
        UPLOAD_DOC: "upload_doc",
        UPLOAD_FILE: "upload_file",
        CURRENT_USER: "current_user",
        CURRENT_COMPANY: "current_company",
        SIZE_DROPDOWN: "size_dropdown",
        PURPOSE_DROPDOWN: "purpose_dropdown",
        CONTACT_NUMBER: "contact_number",
        EMAIL: "email",
        PRIVATE_BUYER_ORG: "private_buyer_org",
        PRIVATE_BUYER_USER: "private_buyer_user",
        COMMISSION_FEE: "commission_fee",
        // Add other field types here
      } 
}


export default Constant;