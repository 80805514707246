import { useEffect, useState } from 'react';
import { useRange } from 'react-instantsearch';
import { RangeSlider as SpectrumRangeSlider } from '@adobe/react-spectrum';

import './RangeSlider.css';

export function PriceRangeSlider(props) {
  const { start, range, canRefine, refine } = useRange(props);
  const { min, max } = range;
  const [value, setValue] = useState({ start: min, end: max });

  const from = min;
  const to = max;
  const stepSize = 10; // Adjust step dynamically

  useEffect(() => {
    console.log(from + "-------" + to);
    setValue({ start: from, end: to });
  }, [from, to]);
  return (
    <SpectrumRangeSlider
      // step={stepSize}
      label="Select price"
      minValue={min}
      maxValue={max}
      value={value}
      onChange={setValue}
      onChangeEnd={(range) => refine([range.start, range.end])}
      isDisabled={!canRefine}
    />
  );
}
