import { useEffect, useRef, useState } from "react";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { useHistory, useLocation } from "react-router";
import { CommonService, buildQueryParams } from "../../Service/CommonService";
import { SupportApiService } from "../../Service/SupportApiService";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import { Form, Modal } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import "../../sass/responsive.scss";
import "../Dynamic/SupportRequest.scss";
import StatusDropdown from "../Common/StatusDropdown";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TrashIcon from "../../assets/images/icons/Trash_Black.svg";
import Swal from "sweetalert2";
import DeleteItemIcon from "../../assets/images/icons/DeleteItem.png";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import EditIcon from '../../assets/images/icons/EditIcon.svg';
import Spinner from "../Common/Spinner";
import SupportFaqPanel from "./SupportFaqPanel";
import ExpandMore from '../../assets/images/icons/ExpandMore.svg';
import Constant from "../../Constant";


// First, define an interface for your state type
interface LocationState {
  data: string;  // or whatever type your data is
}

const SupportRequest: React.FC = () => {
  const history = useHistory();
  const [supportRequests, setSupportRequests] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [services, setServices] = useState([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const cursorPosition = useRef<number | null>(null);
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const [viewFaq, setViewFaq] = useState(false);
  const handleCloseFaq = () => setViewFaq(false);
  const handleShowFaq = () => setViewFaq(true);
  const [isRejected, setIsRejected] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [isSellRequestAccepted, setSellRequestAccepted] = useState(false);
  const serviceRequestUpdateStatus = useRef({
      customerServiceRequestId: "",
      reason: "",
      status: "",
      serviceName: "",
      dynamicFields: [],
      spendHourOnRequest: "",
      companyId: "",
      userId: "",
      fee: "",
      feeType: ""
  });
  const [sellRequestFee, setSellRequestFee] = useState('');
  const [sellRequestFeeType, setSellRequestFeeType] = useState('');
  const [feeError, setFeeError] = useState('');
  const [feeTypeError, setFeeTypeError] = useState('');
  const [spendHour, setSpendHour]  = useState("");
  const [reason,setReason] = useState(""); 
  const [isSellRequestFeeUpdated, setSellRequestFeeUpdated] = useState(false);
  
  const [sortField, setSortField] = useState("CreatedModifiedDate"); // Default sort
  const [sortDirection, setSortDirection] = useState("asc"); // Default ascending
  const [isBeforeCancel, setIsBeforeCancel] = useState(false);
  
  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  }

  // State to store calculated position
  // const [position, setPosition] = useState<{ top: number; left: number }>({
  //   top: 0,
  //   left: 0,
  // });

  // Function to calculate and update the position
  // const updatePosition = () => {
  //     if (!buttonRef.current) return;

  //     const rect = buttonRef.current.getBoundingClientRect();
  //     const buttonHeight = rect.height;
  //     const top = rect.top + window.scrollY + buttonHeight + 5; // Add height + 10px
  //     const left = rect.left + window.scrollX;

  //     setPosition({ top, left }); // Update position state
  // };


  const statusOptions = [
    { value: "PENDING_REVIEW", label: "Pending Review" },
    { value: "UNDER_REVIEW", label: "Under Review" },
    { value: "ACCEPTED", label: "Accepted" },
    { value: "UPLOADED", label: "Uploaded" },
    { value: "IN_PROGRESS", label: "In Progress" },
    { value: "PUBLISHED", label: "Published"},
    { value: "REJECTED", label: "Rejected" },
    { value: "CANCELLED", label: "Cancelled" },
    { value: "CLOSED", label: "Closed" },
    { value: "OWNERSHIP_TRANSFERRED", label: "Ownership Transferred"},
    {value: "FINALIZED", label: "Finalized"}
  ];

  const [filter, setFilter] = useState<ServiceRequestSearchFilter>({
    name: null,
    status: null,
    createdDate: null,
    requestNumber: null,
    requestType: null,
    companyName: null
  });
  const controllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    const state = history.location.state as LocationState;
    const receivedData = state?.data;
  
    const updatedFilter = receivedData 
      ? { ...filter, requestType: receivedData } 
      : filter;
  
    setFilter(updatedFilter);
  
    // Use the appropriate filter in the API calls
    loadAllServiceRequests(page, pageSize, updatedFilter, sortField, sortDirection);
    fetchAllServices();
    
    
  }, []);

  useEffect(() => {
  
    const handleClickOutside = (event: MouseEvent) => {
      if (
        divRef.current &&
        !divRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };
  
  
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      
    };
  }, [showDropdown]); // Only run when dropdown is toggled



 
  
  const loadAllServiceRequests = async (page: any, size: any, filter: any, sortField: any, sortDirection: any) => {
    
    const controller = new AbortController();
    const signal = controller.signal;
  
    try {
      setIsLoading(true);
      setSupportRequests([]);
   //   setTableDataWithIds([]);
      // setTotalCount(0);
      // Cancel the previous request if any

      if (controllerRef.current) {
        setIsLoading(true);
        controllerRef.current.abort();
      }
      
      // Store the current controller to cancel the request if needed
      controllerRef.current = controller;
  
      const response = await SupportApiService.getAllServiceRequests(
        CommonService.isUser() ? CommonService.getCompanyId() : null,
        page,
        size,
        filter,
        sortField,
        sortDirection,
        signal
      );
  
      setSupportRequests(response.data.content);
      setTotalCount(response.data.totalElements);
      setIsLoading(false);
    } catch (error) {
      // Handle cancellation error or any other error
      if (error.name === 'AbortError') {
        console.log('Previous request was canceled');
      } else {
        setIsLoading(false);
        console.error("Error fetching support requests:", error);
      }
    }
  };

  const fetchAllServices = async () => {
    try {
      setIsLoading(true);
      const response = await SupportApiService.getAllServiceType();
      setServices(response.data);
    } catch (error) {
      console.log("Error occurred while API calling:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString() || ""}>
        <span>{value?.toString() || "-"}</span>
      </Tooltip>
    );
  };

  const handleEditServiceRequest = (id:any) => {
    const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";
    history.push(`${linkPrefix}/edit-customer-service-request/${id}`);
 }


    const deleteServiceRequest = async(id:any) => {

        Swal.fire({
            html: `
            <style>
              .custom-swal .icon-container {
                  background-color: #ffebee;
                  border-radius: 50%;
                  width: 105px;
                  height: 105px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0 auto 20px;
                }
                .custom-swal .icon-container i {
                  color: #ff3e4e;
                  font-size: 24px;
                }
                .custom-swal h2 {
                  font-size: 20px;
                  margin-bottom: 10px;
                  margin-top: 20px;
                }
                .custom-swal p {
                  font-size: 14px;
                  color: #666;
                  margin-bottom: 20px;
                }
                .custom-swal .button-container {
                  margin-top: 40px;
                  display: flex;
                  justify-content: center;
                  gap: 20px;
                }
                .custom-swal button {
                  border: none;
                  padding: 10px 50px;
                  border-radius: 30px;
                  font-size: 14px;
                  cursor: pointer;
                }
                .custom-swal .cancel-btn {
                  background-color: #e0e0e0;
                  color: #333;
                }
                .custom-swal .delete-btn {
                  background-color: #ec2323;
                  color: white;
                }
                
            </style>
            <div class="custom-swal">
      
            <div class="icon-container" id="iconContainer">
              </div>
      
              <h2>Are you sure?</h2>
              <p>Remove service request</p>
              <div class="button-container">
                <button class="cancel-btn" id="cancelBtn">Cancel</button>
                <button class="delete-btn" id="deleteBtn">Remove</button>
              </div>
            </div>
          `,
            showConfirmButton: false,
            showCancelButton: false,
            showCloseButton: true,
            focusCancel: true,
            customClass: {
              popup: "custom-popup",
            },
            backdrop: `rgba(0,0,0,0.4)`,
            width: "500px",
            background: "#f8f8f8",
            didOpen: () => {
              // Add the image after the popup has been rendered
              const iconContainer = Swal.getPopup()!.querySelector("#iconContainer");
              if (iconContainer) {
                const img = document.createElement("img");
                img.src = DeleteItemIcon;
                img.width = 47;
                img.height = 51;
                iconContainer.appendChild(img);
              }
      
              // Add event listeners to the buttons
              const cancelBtn = Swal.getPopup()!.querySelector("#cancelBtn");
              const deleteBtn = Swal.getPopup()!.querySelector("#deleteBtn");
      
              cancelBtn?.addEventListener("click", () => {
                Swal.close();
              });
    
              deleteBtn?.addEventListener("click", async () => {
                // Remove the item from the cart locally
                
      
                
          try {
            const response = await SupportApiService.deleteCustomerServiceRequest(id);
    
             // After removing the service request, show this message
             setTimeout(() => {
                 CommonService.Toast.fire({
                 title: "Service request removed successfully",
                 icon: "success",
              });
              
                          }, 1000);
            loadAllServiceRequests(page, pageSize, filter, sortField, sortDirection);
            const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";
            history.push(`${linkPrefix}/customer-service`);
        } catch(error ) {
            console.error("Error deleting service request:", error);
        }
      
                Swal.close();
              });
            },
          });

    }

    //redirect to the add support request page
    const handleAddRequest = () => {
        if (CommonService.isAdmin())
            history.push("/master/add-customer-service-request");
        else
            history.push("/user/add-customer-service-request");
    };

    const handleRowClick = (params) => {
        const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";
        history.push(`${linkPrefix}/view-customer-service-request/${params.row.id}`);
    }

    // const handleFilterChange = (event: any, value: string) => {
    //   const isChecked = event.target.checked;
    
    //   // Toggle the selected status
    //   const updatedStatus = isChecked
    //     ? [...filter.status, value] // Add status
    //     : filter.status.filter((status) => status !== value); // Remove status
    
    //   // Update state
    //   setFilter({ ...filter, status: updatedStatus });
    
    //   // Reload service requests with updated filters
    //   loadAllServiceRequests(page, pageSize, { ...filter, status: updatedStatus });
    // };

  // const [filters, setFilters] = useState({
  //   requestType: "",
  //   requestNumber: "",
  //   createdDate: "",
  //   name: "",
  //   status: "",
  // });

  const handleFilterChange = (
    event: any,
    field: keyof ServiceRequestSearchFilter
  ) => {
    const value = event.target.value;
  
    // Prepare a temporary filter object with the updated field value
    const tempFilter = {
      ...filter, // Spread existing filter state
      [field]: value, // Update the specific field
    };
  
    // Store the cursor position
    cursorPosition.current = event.target.selectionStart;

    // Update the filter state
    setFilter(tempFilter);
    // setIsLoading(true);
    // Load the service requests with updated filters
    loadAllServiceRequests(page,pageSize, tempFilter, sortField, sortDirection);
  };

  const handleStatusChange = (event: any, value: string, status: string | null) => {
    const isChecked = event.target.checked;
  
    // Convert the status string to an array
    const statusArray = status ? status.split(",") : [];
  
    // Update the status list based on checkbox state
    const updatedStatusArray = isChecked
      ? [...statusArray, value] // Add status if checked
      : statusArray.filter((s) => s !== value); // Remove status if unchecked
  
    // Convert array back to a comma-separated string
    const updatedStatus = updatedStatusArray?.length > 0 ? updatedStatusArray.join(",") : null;
  
    // Update the filter state with the modified status string
    setFilter({ ...filter, status: updatedStatus });
  
    // Reload service requests with updated filters
    loadAllServiceRequests(page, pageSize, { ...filter, status: updatedStatus }, sortField, sortDirection);
  };

  const handleClose = () => {
    setShow(false);
    setError("");
    setReason("");
    setFeeError("");
    setFeeTypeError("");
    setSellRequestFee("");
    setSellRequestFeeType("");
    setSellRequestFeeUpdated(false)
    setSpendHour("");
    setIsRejected(false);
    setIsCancelled(false);
    setSellRequestAccepted(false);
    setIsBeforeCancel(false);
  };

  const handleSellRequestFeeChange = (e) => {
    setFeeError('');
    const value = e.target.value;
    setSellRequestFee(value);
  }

  const handleSellRequestFeeTypeChange = (e) => {
    setFeeTypeError('');
    const value = e.target.value;
    setSellRequestFeeType(value);
  }

  const handleReasonChange = (e:any) => {
    setError("");
    setReason(e.target.value);
  }

  const handleSpendHourChange = (e:any) => {
    setError("");
    setSpendHour(e.target.value);
  }

  const updateSellRequestFeeDetails = async () => {

    let isValid = true;

    //check entered value is valid or not
    if((Number(sellRequestFee)) < 0 || sellRequestFee?.trim().length < 1) {
      setFeeError((sellRequestFee?.trim().length < 1)? "Please enter commission fee" : "Commission Fee should be greater then or equal to zero")
      isValid = false;
    }
    if(sellRequestFeeType == '') {
      setFeeTypeError("Please select fee type")
      isValid = false;
    }


    if(!isValid) {
      return ;
    }

    setIsLoading(true);

    const updateSellRequestDetails = {
      customerServiceRequestId: serviceRequestUpdateStatus.current.customerServiceRequestId,
      fee: sellRequestFee,
      feeType: sellRequestFeeType
    };

    //everything is good then call the api for updating the fee details

    try{
      const response = await SupportApiService.updateSellRequestFeeDetails(updateSellRequestDetails);
      CommonService.Toast.fire({
        title: "Fee Details updated successfully",
        icon: "success",
      });

      // getCustomerServiceRequestDetails();

    } catch(error) {
      console.log("error occurred while api calling");
      
    } finally {
      setIsLoading(false);
      handleClose();
    }


  }

  // const handleUpdateStatus = async(newStatus:any, id:any, serviceName:any, companyId:any, userId:any, sellRequestFee:any, sellRequestFeeType:any) => {
    
  //   console.log("these are my fields " + serviceName, companyId, userId, sellRequestFee, sellRequestFeeType)
  //   const status = newStatus?.replace(/ /g, "_").toUpperCase();
    
  //   const currentStatus = {
  //     customerServiceRequestId: id,
  //     serviceName: serviceName,
  //     reason: "",
  //     status: status,
  //     spendHourOnRequest: "",
  //     companyId: companyId,
  //     userId: userId,
  //     // dynamicFields: customerServiceRequest.dynamicFields || [],
  //     fee: sellRequestFee,
  //     feeType: sellRequestFeeType
  //   };

  //   serviceRequestUpdateStatus.current = currentStatus;

  //   if(serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.ACCEPTED && serviceName == 'Sell Request') {
  //     setSellRequestAccepted(true);
  //     setIsCancelled(false);
  //     setIsRejected(false);
  //     handleShow();
  //   } else if (serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.CANCELLED) {
  //     setIsCancelled(true);
  //     setIsRejected(false);
  //     setSellRequestAccepted(false);
  //     handleShow();
  //   } else if (serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.REJECTED) {
  //     setIsRejected(true);
  //     setIsCancelled(false);
  //     setSellRequestAccepted(false);
  //     handleShow();
  //   } else if (serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.CLOSED) {
  //     setIsCancelled(false);
  //     setIsRejected(false);
  //     setSellRequestAccepted(false);
  //     handleShow();
  //   } else {
  //     //if status is not ACCEPTED and REJECTED then call method don't open any popup
  //     changeCustomerServiceRequestStatus(serviceName);
  //   }

  // }

  const handleUpdateStatus = async(newStatus:any, oldStatus:any, id:any, serviceName:any, companyId:any, userId:any, sellRequestFee:any, sellRequestFeeType:any) => {
    
    const status = newStatus?.replace(/ /g, "_").toUpperCase();
    
    const currentStatus = {
      customerServiceRequestId: id,
      serviceName: serviceName,
      reason: "",
      status: status,
      spendHourOnRequest: "",
      companyId: companyId,
      userId: userId,
      dynamicFields: [],
      fee: sellRequestFee,
      feeType: sellRequestFeeType
    };

    serviceRequestUpdateStatus.current = currentStatus;

    if(serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.ACCEPTED && serviceName == 'Sell Request') {
      setSellRequestAccepted(true);
      setIsCancelled(false);
      setIsRejected(false);
      handleShow();
    } else if (serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.CANCELLED) {
      setIsCancelled(true);
      setIsRejected(false);
      setSellRequestAccepted(false);

      if(oldStatus == Constant.CustomerServiceRequestStatus.PENDING_REVIEW || oldStatus == Constant.CustomerServiceRequestStatus.UNDER_REVIEW) {
        setIsBeforeCancel(true);
      }
      handleShow();
    } else if (serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.REJECTED) {
      setIsRejected(true);
      setIsCancelled(false);
      setSellRequestAccepted(false);
      handleShow();
    } else if (serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.CLOSED) {
      setIsCancelled(false);
      setIsRejected(false);
      setSellRequestAccepted(false);
      handleShow();
    } else {

      //now check if the status is uploaded then add the dynamic field in request 
      if(serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.UPLOADED || serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.PUBLISHED) {
         try {
           const response = await SupportApiService.getCustomerServiceRequest(id);
           serviceRequestUpdateStatus.current.dynamicFields = response.data?.dynamicFields;
           serviceRequestUpdateStatus.current.companyId = response.data?.companyId;
           serviceRequestUpdateStatus.current.userId = response.data?.userId;
         } catch (error) {
           console.log("error occurred while api calling ");
         }
      }
      //if status is not ACCEPTED and REJECTED then call method don't open any popup
      changeCustomerServiceRequestStatus();
    }

  }

  const changeCustomerServiceRequestStatus = async() => {


      if (serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.CANCELLED && reason.trim().length <= 0 && serviceRequestUpdateStatus.current.serviceName != 'Bulk Inventory Upload') {
        setError("Please enter cancellation reason");
        return;
      }
  
      if (serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.REJECTED && reason.trim().length <= 0) {
        setError("Please enter rejection reason");
        return;
      }
  
      if (serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.REJECTED && reason.trim().length > 1000) {
        setError("Rejection note can not be more than 1000 characters");
        return;
      }
  
      if (serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.CLOSED && spendHour.trim().length <= 0) {
        setError("Please enter spend time in hours");
        return;
      }
  
      if ((serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.ACCEPTED && serviceRequestUpdateStatus.current.serviceName == 'Sell Request') && ((Number(sellRequestFee)) < 0 || sellRequestFeeType == '') ) {
        
        if((Number(sellRequestFee)) < 0 || sellRequestFee?.trim().length < 1) {
          setFeeError((sellRequestFee?.trim().length < 1)? "Please enter commission fee" : "Commission Fee should be greater then or equal to zero")
        }
        if(sellRequestFeeType == '') {
          setFeeTypeError("Please select fee type")
        }
  
        return ;
      }
  
  
      setIsLoading(true);
      
        //now call the api and update the status
      try {
        //before calling the api we need to set the reason if status is cancelled and set spend hour if the status Closed
        if(serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.CANCELLED) {
          serviceRequestUpdateStatus.current.reason = reason;
        }
        if(serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.REJECTED) {
          serviceRequestUpdateStatus.current.reason = reason;
        }
        if(serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.CLOSED) {
          serviceRequestUpdateStatus.current.spendHourOnRequest = spendHour;
        }
        if ((serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.ACCEPTED && serviceRequestUpdateStatus.current.serviceName == 'Sell Request')) {
          serviceRequestUpdateStatus.current.fee = sellRequestFee;
          serviceRequestUpdateStatus.current.feeType = sellRequestFeeType;
        }
        const response = await SupportApiService.updateCustomerServiceRequestStatus(serviceRequestUpdateStatus.current);
        // getCustomerServiceRequestDetails();
        // fetchCustomerServiceRequestHistory();

        setIsLoading(false);
        handleClose();
        
        CommonService.Toast.fire({
          title: "Status updated successfully",
          icon: "success",
        });
        
        loadAllServiceRequests(page, pageSize, filter, sortField, sortDirection);
         
      } catch (error) {
        
        console.log("error occurred while api calling " + error?.response?.data?.errorMessage);
  
        // if(customerServiceRequest?.serviceName == 'Sell Request' || customerServiceRequest?.serviceName == 'Ownership Transfer') {
        //   // CommonService.Toast.fire({
        //   //   title: `${error?.response?.data?.errorMessage}`,
        //   //   icon: "error",
        //   // });
  
        //   setIsSelectedBarrelAreReserved(error?.response?.data?.errorMessage);
        // }
        
        setIsLoading(false);
      }
    
    }
  
  
  // Apply the filters to the rows
  //   const filteredRows = supportRequests.filter((row) => {
  //     return (
  //       row.requestType
  //         .toLowerCase()
  //         .includes(filters.requestType.toLowerCase()) &&
  //       row.requestNumber
  //         .toLowerCase()
  //         .includes(filters.requestNumber.toLowerCase()) &&
  //       row.createdDate
  //         .toLowerCase()
  //         .includes(filters.createdDate.toLowerCase()) &&
  //       row.name.toLowerCase().includes(filters.name.toLowerCase()) &&
  //       row.status.toLowerCase().includes(filters.status.toLowerCase())
  //     );
  //   });

  // Map filtered rows with unique IDs
//   const tableDataWithIds = supportRequests.map((rowData, index) => ({
//     ...rowData,
//     id: rowData.supportRequestId, // Assigning a unique ID based on the index
//   }));

  const columns: GridColDef[] = [
        {
            field: "requestNumber",
            headerName: "Request Number",
            headerClassName: "table-header border-0 my-2 h-100",
            minWidth: 200,
            flex:1,
            sortable: true,
            renderCell: (params) => <CellWithTooltip value={params.value} />,
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{width:"165px"}}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search Request"
                            //value={filter.requestNumber}
                            defaultValue={filter.requestNumber}
                            onFocus={(e) => {
                              // Restore the cursor position on focus
                              if (cursorPosition.current !== null) {
                                e.target.selectionStart = cursorPosition.current;
                              }
                            }}
                            onChange={(event) => handleFilterChange(event, "requestNumber")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
        }, 

        {
          field: "requestType",
          headerName: "Request Type",
          headerClassName: "table-header border-0 my-2 h-100",
          minWidth: 200,
          flex:1,
          sortable: true,
          renderCell: (params) => <CellWithTooltip value={params.value} />,
                renderHeader: (params) => (
                    <>
                        <div>
                            <Typography
                                variant="body2"
                                sx={{ color: "#6F6F6F", fontSize: "14px" }}
                            >
                                {params.colDef.headerName}
                            </Typography>
                            <div className="position-relative" style={{width:"165px"}}>
                                <Form.Select
                                    style={{ cursor: "pointer" }}
                                    value={filter.requestType}
                                    className="form-control border mt-1"
                                    onChange={(event) => handleFilterChange(event, "requestType")}
                                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                                >
                                    <option value="">Select Request Type</option>
                                   {services.map((service) => (
                                        <option key={service.id} value={service.serviceName}>
                                        {service.serviceName}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                    </>
                ),
            },
        // Conditionally include the 'Company' column for admins
    ...(CommonService.isAdmin()
      ? [
            {
                field: "companyName",
                headerName: "Company",
                headerClassName: "table-header border-0 my-2 h-100",
                minWidth: 200,
                flex:1,
                sortable: true,
                renderCell: (params) => <CellWithTooltip value={params.value} />,
                renderHeader: (params) => (
                    <div>
                        <Typography
                            variant="body2"
                            sx={{ color: "#6F6F6F", fontSize: "14px" }}
                        >
                            {params.colDef.headerName}
                        </Typography>
                        <div className="position-relative" style={{width:"165px"}}>
                            <input
                                type="text"
                                className="form-control border mt-1"
                                style={{ textIndent: "24px", boxShadow: "none" }}
                                placeholder="Search Company"
                                //value={filter.name}
                                defaultValue={filter.companyName}
                                onChange={(event) =>
                                    handleFilterChange(event, "companyName")
                                }
                                onClick={(event) =>
                                    event.stopPropagation()
                                } // Prevent event from bubbling up
                                onFocus={(e) => {
                                  // Restore the cursor position on focus
                                  if (cursorPosition.current !== null) {
                                    e.target.selectionStart = cursorPosition.current;
                                  }
                                }}
                            />
                            <SearchIcon
                                sx={{
                                    color: "#6F6F6F",
                                    position: "absolute",
                                    top: "50%",
                                    left: "8px",
                                    transform: "translateY(-50%)",
                                }}
                            />
                        </div>
                    </div>
                ),
            },
        ]
      : []),
        {
            field: "name",
            headerName: "User",
            headerClassName: "table-header border-0 my-2 h-100",
            minWidth: 200,
            flex:1,
            sortable: true,
            renderCell: (params) => <CellWithTooltip value={params.value} />,
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{width:"165px"}}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search User"
                            //value={filter.name}
                            defaultValue={filter.name}
                            onFocus={(e) => {
                              // Restore the cursor position on focus
                              if (cursorPosition.current !== null) {
                                e.target.selectionStart = cursorPosition.current;
                              }
                            }}
                            onChange={(event) => handleFilterChange(event, "name")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
        },
        {
            field: "createdDate",
            headerName: "Requested On",
            headerClassName: "table-header border-0 my-2 h-100",
            minWidth: 200,
            flex:1,
            sortable: true,
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
            valueGetter: (value: any, row: any) => {
                const dateTimeString = row.createdDate ? row.createdDate : null;
                if (dateTimeString) {
                    return CommonService.formatDateTime(dateTimeString);
                }
            },
            renderHeader: (params) => (
                <div style={{width:"165px"}}>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <input
                        type="date"
                        className="form-control border mt-1"
                        style={{ boxShadow: "none" }}
                        id="birthdate"
                        name="birthdate"
                        placeholder="Select Date"
                        //value={filter.createdDate}
                        defaultValue={filter.createdDate}
                        onChange={(event) => handleFilterChange(event, "createdDate")}
                        onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                    />
                </div>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            headerClassName: "table-header border-0 my-2 h-100",
            minWidth: 240,
            cellClassName: "status-badge",
            flex:1,
            sortable: true,
            renderCell: (params: any) => (
                <>
                    <div className="dropdown-nospacing" onClick={(e)=> e.stopPropagation()}>
                      {/* Show Spinner while status is UPLOADING */}
                      {(params.row.status === "UPLOADING" && params.row.requestType === "Bulk Inventory Upload") && (
                        <Spinner />
                      )}
                        <StatusDropdown
                            currentStatus={params.row.status === "UPLOADING" ? "Uploading"
                                          : params.row.status === "UPLOAD_FAILED" ? "Upload Failed"
                                          : params.row.status}
                            disableCondition={(params.row.status === Constant.CustomerServiceRequestStatus.CANCELLED || params.row.status === Constant.CustomerServiceRequestStatus.CLOSED || params.row.status === Constant.CustomerServiceRequestStatus.REJECTED) || (CommonService.isUser() && (params.row.status === Constant.CustomerServiceRequestStatus.OWNERSHIP_TRANSFERRED || params.row.status === Constant.CustomerServiceRequestStatus.UPLOADED || params.row.status === Constant.CustomerServiceRequestStatus.PUBLISHED))}
                            onStatusChange={(newStatus) => handleUpdateStatus(newStatus, params.row?.status, params.row?.id, params.row?.requestType, params.row?.companyId, params.row?.userId, params.row?.sellRequestFee, params.row?.sellRequestFeeType)}
                            allowedLabels={[]}
                            serviceTypeId={params.row.serviceTypeId}
                        />


              {(params.row.status === "CANCELLED"  || params.row.status == 'REJECTED') && params.row.cancellationReason && (
                <Tooltip
                  title={`Reason: ${params.row?.cancellationReason}`}
                  arrow
                >
                  <StickyNote2Icon
                    className="note-icon cursor-pointer"
                    style={{ color: "gray",marginLeft:3 }}
                  />
                </Tooltip>
              )}
                    </div>
                </>
            ),
            renderHeader: (params) => (
                <>
                    <div>
                        <Typography
                            variant="body2"
                            sx={{ color: "#6F6F6F", fontSize: "14px" }}
                        >
                            {params.colDef.headerName}
                        </Typography>


                        <div className="position-relative" style={{width:"200px"}}>



                        <div
                          className="form-control mb-1"
                          style={{
                            cursor: "pointer",
                            borderBottomColor: "#D1D1D1",
                            marginTop: "4px",
                            background: "#fff",
                            padding: "5px",
                            overflowX:"hidden",
                            paddingRight:"16px",
                            textOverflow:"ellipsis",
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            toggleDropdown();
                          }}
                          ref={buttonRef}
                        >
                           {filter.status?.length > 0 ? (CommonService.formatStatus(filter.status)).join(", ") : "Select Status"} 

                           {/* <ExpandMore/> */}
                           <img src={ExpandMore} className="position-absolute top-50 end-0 translate-middle" />
                        </div>



                            {/* <Form.Select
                                style={{ cursor: "pointer",borderBottomColor:"#D1D1D1",marginTop:"4px" }}
                                value={filter.status}
                                className="form-control mb-1"
                                onChange={(event) => handleFilterChange(event, "status")}
                                onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                            >
                                <option value="">Select Status</option>
                                <option value="PENDING_REVIEW">Pending Review</option>
                                <option value="ACCEPTED">Accepted</option>
                                <option value="REJECTED">Rejected</option>
                                <option value="CANCELLED">Cancelled</option>
                                <option value="CLOSED">Closed</option>
                                <option value="IN_PROGRESS">In Progress</option>
                                <option value="UNDER_REVIEW">Under Review</option>
                                <option value="UPLOADED">Uploaded</option>
                            </Form.Select> */}



                           {/* Custom Dropdown */}
                           {showDropdown && (
              <div
                className="dropdown-menu show status-multiselect-dropdown"
                onClick={(event) => event.stopPropagation()}
                ref={divRef}
              >
                {statusOptions.map((option) => (
                  <Form.Check
                    key={option.value}
                    id={`status-${option.value}`}
                    type="checkbox"
                    label={option.label}
                    value={option.value}
                    checked={filter.status?.split(",").includes(option.value) || false}
                    onChange={(event) => {
                      event.stopPropagation();
                      handleStatusChange(event, option.value, filter.status);
                    }}
                    className="cursor-pointer"
                  />
                ))}
              </div>
            )}
                            
                        </div>
                    </div>
                </>
            ),
        },

        {
            field: "action",
            headerName: "Action",
            sortable: false,
            minWidth: 120,
            flex:1,
            headerClassName: "table-header border-0 my-2 h-100",
            renderCell: (params: any) => (
              <>{!(params?.row?.roleName == 'SUPER_ADMIN' && CommonService.isUser()) && (params?.row?.status == "PENDING_REVIEW" || params?.row?.status == "UNDER_REVIEW") && (<div className="d-flex align-items-center gap-2 h-100" onClick={(e) => e.stopPropagation()}>
                <Tooltip title="Edit" arrow>
                  <IconButton
                    aria-label="message"
                    size="small"
                    onClick={() => handleEditServiceRequest(params?.row?.id)}
                    disabled={params?.row?.roleName == 'SUPER_ADMIN' && CommonService.isUser()}

                  >
                      <img src={EditIcon} className="cursor-pointer" alt="EditIcon" />
                  </IconButton>


                </Tooltip>
                <Tooltip title="Delete" arrow>
                  <IconButton
                    aria-label="message"
                    size="small"
                    onClick={() => deleteServiceRequest(params?.row?.id)}
                    disabled={params?.row?.roleName == 'SUPER_ADMIN' && CommonService.isUser()}
                  >
                    <img src={TrashIcon} height={22} width={22} />
                  </IconButton>
                </Tooltip>
              </div>)}
              
             {/* {(params?.row?.roleName == 'SUPER_ADMIN' && CommonService.isUser()) && (<Tooltip title="View" arrow>
                  <IconButton
                    aria-label="message"
                    size="small"

                  >
                    <VisibilityIcon
                      className="cursor-pointer"
                      sx={{
                        height: "20px",
                        width: "20px",
                      }} />
                  </IconButton>


                </Tooltip>)} */}
                </>
            ),
          },
    ];

    const onPaginationModelChange = (newPaginationModel) => {
      setPage(newPaginationModel.page);
      setPageSize(newPaginationModel.pageSize);
      loadAllServiceRequests(newPaginationModel.page, newPaginationModel.pageSize,filter, sortField, sortDirection);
    };
  
    const handleSortChange = (sortModel) => {
      if (sortModel.length > 0) {
          const newSortField = sortModel[0].field;
          const newSortDirection = sortModel[0].sort; // "asc" or "desc" directly from DataGrid
          
          setSortField(newSortField);
          setSortDirection(newSortDirection);
          loadAllServiceRequests(page, pageSize,filter, newSortField, newSortDirection);
      }
    };  

    return (

      
      
      <>
        <div className="usersList support-request-list-wrapper pt-0" style={{ paddingBottom: CommonService.isAdmin() ? 57 : "" }}>
            <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center p-3">
                {CommonService.isAdmin() && (<div className="d-flex align-items-center">
      <div className="back__button me-2"  onClick={() => history.goBack()}>
              <IconButton
                aria-label="ArrowBackIcon"
               
                sx={{ backgroundColor: "#FFF" }}
              >
                <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
              </IconButton>
            </div>
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            fontSize: "25px",
            fontWeight: 700,
            color: "#242424",
            lineHeight:"22px",
            marginBottom:"0px"
          }}
        >
          Customer Service
        </Typography>
        </div>

                )}
                
                
          <div className="ms-auto d-flex gap-2 align-items-center">
            <button className="primary-btn d-inline-flex me-1 ms-auto" onClick={handleAddRequest}>
              <AddSharpIcon sx={{ mr: 0.5 }} />
              New Request
            </button>
            <div>
              <button className="support-faq-btn" onClick={handleShowFaq}>
                FAQ’s
              </button>
            </div>
          </div>
                
                
            </div>
            <div className="user-table-container position-relative">
            {viewFaq &&(<SupportFaqPanel handleCloseFaq={handleCloseFaq}/>)}
                <div className="userTable">

                {/* {(isLoading) ? ( <><Skeleton
                      animation="wave"
                      variant="rounded" 
                      // height={550}
                      style={{height:"100%"}}
                    /></> ) : (<> */}
                    <DataGrid
                        rows={supportRequests}
                        columns={columns}
                        className="data-grid support-req--listing"
                        initialState={{
                          pagination: {
                              paginationModel: { page, pageSize },
                          },
                        }}
                        pagination
                        paginationModel={{page, pageSize }}
                        rowCount={totalCount}
                        paginationMode="server"
                        sortingMode="server"
                        onSortModelChange={handleSortChange}
                        onPaginationModelChange={onPaginationModelChange}
                        pageSizeOptions={[10, 20, 50, 100]}
                        disableRowSelectionOnClick={true}
                        loading={isLoading}
                        onRowClick={handleRowClick}
                        disableColumnResize
                    />
                    {/* </>)} */}

                    
                </div>
            </div>
            {/* {showDropdown && (
              <div
                className="dropdown-menu show"
                style={{
                  position: "fixed",
                  top: `${position.top}px`,
                  left: `${position.left}px`,
                  zIndex: "1050", // High enough to appear above table
                  background: "#fff",
                  border: "1px solid #ccc",
                  padding: "5px",
                }}
                onClick={(event) => event.stopPropagation()}
                ref={divRef}
              >
                {statusOptions.map((option) => (
                  <Form.Check
                    key={option.value}
                    id={`status-${option.value}`}
                    type="checkbox"
                    label={option.label}
                    value={option.value}
                    checked={filter.status?.split(",").includes(option.value) || false}
                    onChange={(event) => {
                      event.stopPropagation();
                      handleStatusChange(event, option.value, filter.status);
                    }}
                    className="cursor-pointer"
                  />
                ))}
              </div>
            )} */}

        </div >

        <Modal
                show={show}
                onHide={handleClose}
                className="modal__wd modal__wd--submitSellReq"
              >
                <Modal.Header className="flex-column align-items-start">
                  <Modal.Title>Update Customer Service Request Status</Modal.Title>
                  {isCancelled && (
                    <p className="modal-text">
                      Are you sure you want to cancel? {!isBeforeCancel ? "A cancellation charge may apply." : ""}
                    </p>
                  )}
                  {isRejected && (
                    <p className="modal-text">
                      Are you sure you want to reject? 
                    </p>
                  )}
                  {isSellRequestAccepted && (
                    <p className="modal-text">
                      Please enter commission fee and fee type 
                    </p>
                  )}
                  {!isCancelled && !isRejected && !isSellRequestAccepted && (
                    <p className="modal-text">
                      Close customer service request
                    </p>
                  )}
                </Modal.Header>
                <Modal.Body>
                  {isCancelled && (
                    <div className="form__inner">
                    <textarea
                        className={`form-control ${error ? "is_invalid" : ""}`}
                        aria-label="With textarea"
                        rows={8}
                        style={{ resize: "none" }}
                        value={reason}
                        onChange={handleReasonChange}
                        placeholder="Enter cancellation reason.."
                      />
                     
                      {error && <div className="error-message">{error}</div>}
                    </div>
                  )}
                  
        
                  {isRejected && (
                    <div className="form__inner">
                    <textarea
                        className={`form-control ${error ? "is_invalid" : ""}`}
                        aria-label="With textarea"
                        rows={8}
                        style={{ resize: "none" }}
                        value={reason}
                        onChange={handleReasonChange}
                        placeholder="Enter rejection reason.."
                      />
                     
                      {error && <div className="error-message">{error}</div>}
                    </div>
                  )}
        
              {isSellRequestAccepted && (
                    <><div className="form__inner">
                    <label style={{ color: "#333333", fontWeight: "700", marginBottom: "6px" }}>
                      Fee Type<span className="star-required text-danger">*</span>
                    </label>
                    <select
                      id="fee-type-dropdown"
                      value={sellRequestFeeType}
                      style={{ cursor: "pointer" }}
                      onChange={handleSellRequestFeeTypeChange}
                      className={`form-control no-spinner ${feeTypeError ? "is_invalid" : ""}`}
                    >
                      <option value="">Select Fee Type</option>
                      <option value="FLAT">Flat</option>
                      <option value="PERCENTAGE">Percentage</option>
                    </select>
                    {feeTypeError && <div className="error-message">{feeTypeError}</div>}
                  </div>
        
                  <div className="form__inner mt-2">
                  <label style={{ color: "#333333", fontWeight: "700", marginBottom: "6px" }}>
                    Commission Fee ($) /Barrel<span className="star-required text-danger">*</span>
                  </label>
                  <Form.Control
                    className={`form-control no-spinner ${feeError ? "is_invalid" : ""}`}
                    type="number"
                    value={sellRequestFee}
                    onChange={handleSellRequestFeeChange}
                    placeholder="Enter commission fee amount"
                  />
                  {feeError && <div className="error-message">{feeError}</div>}
                  </div></>
                )}
        
                  {!isCancelled && !isRejected && !isSellRequestAccepted && (
                    <div className="form__inner">
        
                      <label style={{color:" #333333",fontWeight: "700",marginBottom: "6px",}}>Time Spent (hours)<span className="star-required text-danger">*</span></label>
                       <Form.Control
                        className={`form-control no-spinner ${error ? "is_invalid" : ""}`}
                        type="number"
                        value={spendHour}
                        onChange={handleSpendHourChange}
                        placeholder="Enter hours"
                      />
                      {error && <div className="error-message">{error}</div>}
                    </div>
                  )}
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                  <div className="btn-group row d-flex justify-content-between w-100">
                    <div className="col-6 ps-0">
                      <button
                        className="secondary-btn w-100"
                        onClick={handleClose}
                        disabled={isLoading}
                      >
                        Cancel
                      </button>
                    </div>
        
                    <div className="col-6 pe-0">
                      <button
                        className="primary-btn w-100"
                        onClick={isSellRequestFeeUpdated ? updateSellRequestFeeDetails : changeCustomerServiceRequestStatus}
                        disabled={isLoading}
        
                      >
                        {isLoading && <Spinner />} {isSellRequestFeeUpdated ? 'Update' : isCancelled ? "Cancel Request" : isRejected ? "Reject Request" : (isSellRequestAccepted ? "Accept" : "Close Request")}
                      </button>
                    </div>
                  </div>
                </Modal.Footer>
              </Modal>

              </>


          
    );
};

export default SupportRequest;