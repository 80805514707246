import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import { OrderApiService } from "../../Service/OrderApiService";
import { Form, Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import Cookies from "universal-cookie";
import { useParams } from "react-router";
import { CookieService } from "../../Service/CookieService";
import UploadDocs from "../popups/uploadDocs";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import ContainerMessageBox from "./ContainerMessageBox";
import { CommonService } from "../../Service/CommonService";
import InventoryFileListTable from "./InventoryFileListTable";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import Constant from "../../Constant";
import { VisibilityOutlined } from "@mui/icons-material";
import StatusDropdown from "../Common/StatusDropdown";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import Spinner from "../Common/Spinner";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import EditRequest from "./EditOrderRequest";
import { HistoryStepper } from "../Common/HistoryStepper";
import AdminMessageBox from "./AdminMessageBox";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { MessageApiService } from "../../Service/MessageApiService";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import TrashIcon from "../../assets/images/icons/Trash.svg";
import BarrelSelectionPanel from "./BarrelSelectionPanel";
import DownLoadIcon from "../../assets/images/icons/DownLoadIcon.svg";
import SameInventoryOrders from "./SameInventoryOrders";

// Create a custom theme with adjusted breakpoints
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768, // Customizing the md breakpoint to start at 992px
      lg: 992,
      xl: 1200,
    },
  },
});

const ProductImage = styled("img")({
  maxWidth: "100%",
  border: "1px solid #D9D9D9", // 1px border for the image
  borderRadius: "10px",
});

const ProductCaption = styled(Typography)({
  textAlign: "center",
  marginTop: "8px",
});

const useStyles = makeStyles({
  root: {
    boxShadow: "none",
  },
  firstColumn: {
    minWidth: 115,
    width: "max-content",
    borderBottom: "none",
    color: "#333333",
    fontWeight: 600,
  },
  secondColumn: {
    width: "100%",
    borderBottom: "none",
    color: "#21252980",
  },
});

const OrderDetail: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const currentPath = location.pathname;
  const classes = useStyles();
  const [checked, setChecked] = React.useState([0]);
  const [addedCartItems, setCartItems] = React.useState<any>([]);
  const [orderDetails, setOrderDetails] = React.useState<any>([]);
  const [orderRequestDocs, setOrderRequestDocs] = useState<any[]>([]);
  const [historyData, setHistoryData] = useState<any[]>([]);
  const [show, setShow] = useState(false);
  const history = useHistory();
  const userId = new Cookies().get("userId");
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const userRole = CookieService.getCookie("userRole");
  const [totalPrice, setTotalPrice] = React.useState<number>(0);
  const statusDetailsRef = useRef({
    id: "",
    status: "",
    rejectionNote: "",
    trackNumber: "",
    ownershipTransferDate: "",
    ownerOrderStatus: "",
    ownerOrderRejectionNote: "",
  });
  const rejectionNote = useRef("");
  const trackNumber = useRef("");
  const ownershipTransferDate = useRef("");
  const handleShow = () => setShow(true);
  const [rejectionNoteValue, setRejectionNote] = useState<string>("");
  const [trackNumberValue, setTrackNumber] = useState<string>("");
  const [ownershipTransferDateValue, setOwnershipTransferDate] = useState<string>("");
  const [isApproved, setIsApproved] = useState(false);
  const [isBeforeCancel, setIsBeforeCancel] = useState(false);
  const [isAfterCancel, setIsAfterCancel] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string>("");
  const [isOpenEditSection, setOpenEditSection] = useState(false);
  const [isMessageBoxOpen, setMessageBoxOpen] = useState(false);
  const [orderItemId, setOrderItemId] = useState("");
  const [isStatusValid, setIsStatusValid] = useState(false);
  const [isItemRejected, setIsItemRejected] = useState(false);
  const [isOrderItemStatusChange, setIsOrderItemStatusChange] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [isSeller, setIsSeller] = useState<boolean>(false);
  const [relocationFlag, setRelocationFlag] = useState<boolean>(false);
  const handleViewClose = () => setViewDetails(false);
  const handleViewShow = () => setViewDetails(true);
  const [unreadGroupsCount, setUnreadGroupsCount] = React.useState<number>(0);
  const [unreadMessageCount, setUnreadMessageCount] = React.useState<number>(0);
  const [isNotEnoughBarrels, setIsNotEnoughBarrels] = React.useState<any>([]);
  const [isNotSelectedRequiredBarrel, setNotSelectedRequiredBarrel] = React.useState<any>([]);
  let dashboardPrefrence = "";
  const [viewBarrelSelection, setViewBarrelSelection] = useState(false);
  const handleCloseBarrelSelection = () => setViewBarrelSelection(false);
  const handleShowBarrelSelection = () => setViewBarrelSelection(true);

  const fetchData = async () => {
    try {
      fetchOrderRequestDetail();
      fetchOrderRequestDocs();
      fetchOrderRequestHistory();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchOrderRequestDetail = async () => {
    if (currentPath.includes("/view-as-buyer"))
      dashboardPrefrence = Constant.Profile.BUYER;
    else if (currentPath.startsWith("/view-as-owner"))
      dashboardPrefrence = Constant.Profile.OWNER;
    else dashboardPrefrence = CommonService.getDashboardPrefrence();

    const response: any = await OrderApiService.getItemsByOrderId(
      id,
      dashboardPrefrence
    );
    console.log("this is get orderItem by id" + response.data);
    setCartItems(response.data);
    if (response.data) setOrderDetails(response.data[0]);

    const isSeller = response.data.some((item) => {
      return (
        item.inventoryOwnerCompanyId === CommonService.getCompanyId() &&
        CommonService.isUser()
      );
    });

    const statuses = response.data.map((item) => item.ownerOrderStatus);

    let statusForSeller = "";

    if (
      (statuses.includes("ACCEPTED") || statuses.includes("ADMIN_ACCEPTED")) &&
      (statuses.includes("REJECTED") || statuses.includes("ADMIN_REJECTED"))
    ) {
      statusForSeller = "ACCEPTED_REJECTED";
    } else if (
      statuses.includes("ACTION_REQUIRED") ||
      statuses.includes("PENDING_REVIEW")
    ) {
      statusForSeller = "PENDING_REVIEW";
    } else if (
      statuses.includes("ACCEPTED") ||
      statuses.includes("ADMIN_ACCEPTED")
    ) {
      statusForSeller = "ACCEPTED";
    } else if (
      statuses.includes("REJECTED") ||
      statuses.includes("ADMIN_REJECTED")
    ) {
      statusForSeller = "REJECTED";
    }

    if (isSeller) {
      setOrderDetails((prevState) => {
        return { ...prevState, orderStatus: statusForSeller };
      });
    }

    const hasActionRequiredOrPendingReview = response.data.some((item) => {
      return (
        !item.isOrderItemDeleted && (item.ownerOrderStatus === "ACTION_REQUIRED" || item.ownerOrderStatus === "PENDING_REVIEW")
      );
    });

    const hasItemRejected = response.data
      .filter(item => !item.isOrderItemDeleted)
      .every(item => item.ownerOrderStatus === "REJECTED" || item.ownerOrderStatus === "ADMIN_REJECTED");   

    const relocationFlag = response.data.some(
      (item) =>
        item.isRelocationRequiredByOwner === true ||
        item.isRelocationRequiredByBuyer === true
    );

    setIsSeller(isSeller);
    setIsStatusValid(hasActionRequiredOrPendingReview);
    setIsItemRejected(hasItemRejected);
    setRelocationFlag(relocationFlag);
  };

  const fetchOrderRequestDocs = async () => {
    setIsLoading(true);
    const response: any = await OrderApiService.fetchOrderRequestDocs(id);
    setOrderRequestDocs(response.data);
    setIsLoading(false);
  };

  const fetchOrderRequestHistory = async () => {
    const response: any = await OrderApiService.fetchOrderRequestHistory(id);
    setHistoryData(response.data);
  };

  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    fetchData();
    if (CommonService.isAdmin()) getAdminUnreadMessageGroupsCount();
  }, [id]);

  // Effect to calculate sum whenever the items list changes
  useEffect(() => {
    const sum = addedCartItems.reduce(
      (acc: any, item: any) => acc + (item["totalPrice"] || 0),
      0
    );
    setTotalPrice(sum);

    let filters =
      addedCartItems.length > 0
        ? [
            `owner_id:!=${userId}`,
            `(inventory_id:!=${addedCartItems
              .map((item: { inventoryId: any }) => item.inventoryId)
              .join(" || inventory_id:=")})`,
          ]
        : [`owner_id:!=${userId}`];

    const filterValue = Array.isArray(filters)
      ? filters?.join(" && ").replace(/\\/g, "")
      : filters;

    setGlobalFilter(filterValue);
  }, [addedCartItems]);

  const routeBack = () => {
    const previousRoute = document.referrer || ""; // Get the referrer URL

    if (previousRoute.endsWith("master/dashboard")) history.goBack();
    else if (previousRoute.endsWith("user/my-warehouse")) history.goBack();
    else if (CommonService.isUser()) history.push("/user/active-order");
    else history.push("/master/active-order");
  };

  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  //formate the status
  function formatStatus(status?: string): string {
    let formattedStatus = "";
    if (status !== undefined) {
      formattedStatus = status
        ?.split(/[_\\]/)
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    }

    return formattedStatus;
  }

  //handle edit button
  const handleEditButtonClick = () => {
    if (isOpenEditSection) {
      setOpenEditSection(false);
    } else {
      setMessageBoxOpen(false);
      setOpenEditSection(true);
    }
  };

  //handle message box click
  const handleMessageBoxClick = () => {
    if (isMessageBoxOpen) {
      setMessageBoxOpen(false);
    } else {
      setOpenEditSection(false);
      setMessageBoxOpen(true);
    }
  };

  const handleAdminMessageClick = () => {
    setShowMessageBox((prev) => !prev);

    // Reset unread groups count when the message box is toggled
    setUnreadGroupsCount(
      unreadGroupsCount > 0 && unreadMessageCount > 0
        ? unreadGroupsCount - 1
        : unreadGroupsCount
    );
  };

  const handleStatusChange = async (newStatus: string, oldStatus: string) => {
    const orderStatus = newStatus?.replace(/ /g, "_");

    //create the object to send
    const sd = {
      id: id,
      companyId: CommonService.getCompanyId(),
      status: orderStatus.toUpperCase(),
      trackNumber: "",
      rejectionNote: "",
      ownershipTransferDate: "",
      quantity: 0,
      ownerOrderStatus: "",
      ownerOrderRejectionNote : "",
    };

    statusDetailsRef.current = sd;

    if (statusDetailsRef.current.status === Constant.OrderStatus.REJECTED) {
      setIsApproved(false);
      handleShow();
    } else if (statusDetailsRef.current.status === Constant.OrderStatus.OWNERSHIP_TRANSFERRED ||
      statusDetailsRef.current.status === Constant.OrderStatus.IN_TRANSIT
    ) {
      setIsApproved(true);
      handleShow();
    } else if (
      statusDetailsRef.current.status === Constant.OrderStatus.CANCELLED
    ) {
      if (["PENDING_REVIEW", "UNDER_REVIEW"].includes(oldStatus)) {
        setIsBeforeCancel(true);
        setIsAfterCancel(false);
      } else if (
        [
          "ACCEPTED",
          "IN_PROGRESS",
          "CONTRACT_SENT",
          "CONTRACT_EXECUTED",
          "INVOICE_SENT",
          "PAYMENT_RECEIVED",
        ].includes(oldStatus)
      ) {
        setIsAfterCancel(true);
        setIsBeforeCancel(false);
      }
      handleShow();
    } else if (
      [
        "UNDER_REVIEW",
        "ACCEPTED",
        "IN_PROGRESS",
        "CONTRACT_SENT",
        "CONTRACT_EXECUTED",
        "INVOICE_SENT",
        "PAYMENT_RECEIVED",
        "OWNERSHIP_TRANSFERRED",
        "CLOSED",
      ].includes(statusDetailsRef.current.status)
    ) {
      setIsApproved(false);
      setIsAfterCancel(false);
      setIsBeforeCancel(false);
      handleShow();
    } else {
      changeOrderStatus();
    }
  };

  const handleEditClick = (itemId: any) => {
    if (isOpenEditSection) {
      setOpenEditSection(false);
      clearArrays();
    } else {
      setMessageBoxOpen(false);
      setOpenEditSection(true);
      setOrderItemId(itemId);
    }
  };

  const handleOrderItemActiveStatus = async (itemId: any, activeStatus: boolean) => {
    await OrderApiService.updateOrderItemActiveStatus(itemId, activeStatus);
    if(activeStatus == true){
      CommonService.Toast.fire({
        title: "Order Item removed successfully",
        icon: "success",
      });
    }else if(activeStatus == false){
      CommonService.Toast.fire({
        title: "Order Item restored successfully",
        icon: "success",
      });
    }
    fetchData();
  };

  const dateInputRef = useRef(null);

  const handleDateClick = () => {
    if (dateInputRef.current) {
      dateInputRef.current?.showPicker(); // This will trigger the date picker to open
    }
  };

  const changeOrderStatus = async () => {
    if (
      statusDetailsRef.current.status === Constant.OrderStatus.REJECTED &&
      rejectionNoteValue.trim().length == 0
    ) {
      setError("Please enter rejection note");
      return;
    }

    if (statusDetailsRef.current.status === Constant.OrderStatus.REJECTED && rejectionNoteValue.trim().length > 500) {
      setError("Rejection note can not be more than 500 characters");
      return;
    }

    if (
      statusDetailsRef.current.status === Constant.OrderStatus.OWNERSHIP_TRANSFERRED &&
      ownershipTransferDateValue.trim().length === 0
    ) {
      setError("Please enter date of transfer");
      return;
    }

    if (
      statusDetailsRef.current.status === Constant.OrderStatus.IN_TRANSIT &&
      trackNumberValue.trim().length === 0
    ) {
      setError("Please enter truck number");
      return;
    }
    setIsLoading(true);

    try {
      if (
        statusDetailsRef.current.status === Constant.OrderStatus.REJECTED ||
        statusDetailsRef.current.status === Constant.OrderStatus.CANCELLED
      ) {
        statusDetailsRef.current.rejectionNote = rejectionNote.current;
      } else if (
        statusDetailsRef.current.status === Constant.OrderStatus.IN_TRANSIT
      ) {
        statusDetailsRef.current.trackNumber = trackNumber.current;
      } else if (statusDetailsRef.current.status === Constant.OrderStatus.OWNERSHIP_TRANSFERRED) {
        statusDetailsRef.current.ownershipTransferDate = ownershipTransferDate.current;
      }
      await OrderApiService.changeOrderStatus(statusDetailsRef.current);
      setIsLoading(false);
      CommonService.Toast.fire({
        title: "Order Request updated successfully",
        icon: "success",
      });
      if (
        statusDetailsRef.current.status === Constant.OrderStatus.IN_TRANSIT &&
        files.length > 0
      ) {
        uploadOrderRequestDocs();
      }
    } catch (error) {

      const errorMessage = error?.response?.data?.errorMessage ?? "";

      if (errorMessage.includes("Not enough barrels available for reservation for Order Items")) {
        setIsNotEnoughBarrels(error?.response?.data?.data ?? []);
        CommonService.Toast.fire({
          title: "Out of Stock, please change quantity",
          icon: "error",
        });
      }

      if (errorMessage.includes("Please select the required quantity")) {
        setNotSelectedRequiredBarrel(error?.response?.data?.data ?? []);
        CommonService.Toast.fire({
          title: "Please select the barrels to match the quantity",
          icon: "error",
        });
      }
    } finally {
      await fetchData();
      handleClose();
      setIsLoading(false);
    }
  };

  const clearArrays = () => {
    setIsNotEnoughBarrels([]);  // Clears isNotEnoughBarrels
    setNotSelectedRequiredBarrel([]);  // Clears isNotSelectedRequiredBarrel
  };

  const handleClose = () => {
    setShow(false);
    setRejectionNote("");
    setTrackNumber("");
    setOwnershipTransferDate("");
    setError("");
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (
      statusDetailsRef.current.status === Constant.OrderStatus.REJECTED ||
      statusDetailsRef.current.ownerOrderStatus === Constant.OrderStatus.ADMIN_REJECTED ||
      statusDetailsRef.current.status === Constant.OrderStatus.CANCELLED
    ) {
      setRejectionNote(event.target.value);
      setError("");
      rejectionNote.current = event.target.value;
    } else if ( statusDetailsRef.current.status === Constant.OrderStatus.IN_TRANSIT ) {
      setTrackNumber(event.target.value);
      setError("");
      trackNumber.current = event.target.value;
    } else if ( statusDetailsRef.current.status === Constant.OrderStatus.OWNERSHIP_TRANSFERRED ){
      setOwnershipTransferDate(event.target.value);
      setError("");
      ownershipTransferDate.current = event.target.value;
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDeleteFile = (indexToDelete: number) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToDelete)
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);

      // Clear the input value to allow selecting the same file again
      event.target.value = "";
    }
  };

  const uploadOrderRequestDocs = async () => {
    const formData = new FormData();
    let response;
    let OrderRequestDoc = {};

    if (files && Array.isArray(files)) {
      files.forEach(function (file: any) {
        formData.append("file", file);
      });
    }

    const fileNames = files.map((file) => file.name).join(", ");

    OrderRequestDoc = {
      orderRequestId: id,
      description: "uploaded " + fileNames + " document",
      userId: CommonService.getUserId(),
      title: fileNames,
      companyId: CommonService.getCompanyId(),
    };

    formData.append(
      "param",
      new Blob([JSON.stringify(OrderRequestDoc)], {
        type: "application/json",
      })
    );

    response = await OrderApiService.uploadOrderRequestDocs(formData);
    fetchOrderRequestDocs();
  };

  const downloadOrderRequestDOC = async (item: any) => {
    const requestDto = {
      id: item.id,
      siteId: orderDetails.siteId,
      companyId: item.companyId,
      fileName: item.fileName,
    };

    try {
      const response: any = await OrderApiService.downloadOrderRequestDOC(
        requestDto
      );
      // Assuming response.data is the blob data
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = item.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleOrderItemStatusChange = async (
    newStatus: string,
    orderItemId: any,
    inventoryId: any,
    inventoryTitle: any
  ) => {
    let updatedStatus;

    if (newStatus === "Send to Owner") updatedStatus = "Pending Review";
    else if (newStatus === "Accept for Owner") updatedStatus = "Admin Accepted";
    else if (newStatus === "Reject for Owner") updatedStatus = "Admin Rejected";
    else updatedStatus = newStatus;

    const status = updatedStatus?.replace(/ /g, "_").toUpperCase();

    const details = {
      id: orderItemId,
      orderId: id,
      inventoryId: inventoryId,
      inventoryTitle: inventoryTitle,
      status: status,
      rejectionNote: "",
      ownershipTransferDate: "",
      ownerOrderRejectionNote: rejectionNote.current,
      ownerOrderStatus: status,
      trackNumber: "",
      companyId: CommonService.getCompanyId(),
    };

    statusDetailsRef.current = details;
    if (
      statusDetailsRef.current.ownerOrderStatus === Constant.OrderStatus.REJECTED ||
      statusDetailsRef.current.ownerOrderStatus === Constant.OrderStatus.ADMIN_REJECTED
    ) {
      setIsOrderItemStatusChange(true); // to call method of order item status change
      handleShow();
    } else {
      changeOrderItemStatus();
    }
  };

  const changeOrderItemStatus = async () => {
    if (
      (statusDetailsRef.current.ownerOrderStatus === Constant.OrderStatus.REJECTED ||
        statusDetailsRef.current.ownerOrderStatus === Constant.OrderStatus.ADMIN_REJECTED) &&
      rejectionNoteValue.trim().length == 0
    ) {
      setError("Please enter rejection note");
      return;
    }

    setIsLoading(true);

    try {
      statusDetailsRef.current.ownerOrderRejectionNote = rejectionNote.current;

      await OrderApiService.updateOrderItemStatus(statusDetailsRef.current);
      setIsLoading(false);
      setIsOrderItemStatusChange(false);

      CommonService.Toast.fire({
        title: "Order Item updated successfully",
        icon: "success",
      });

      handleClose();
      fetchOrderRequestDetail();
    } catch (error) {
      setIsLoading(false);
      console.log("error occurred which api calling");
    }
  };

  const getAdminUnreadMessageGroupsCount = async () => {
    try {
      const response = await MessageApiService.getAdminMessageGroups(
        id,
        "ORDER_REQUEST"
      );

      setUnreadGroupsCount(response.data.unreadGroupsCount);
      setUnreadMessageCount(response.data.unreadMessageCount);
    } catch (error) {
      console.log("error fetching Admin Message Groups Count" + error);
    }
  };

  const columnsForDoc: GridColDef[] = [
    {
      field: "createdDate",
      headerName: "Submitted On",
      flex: 1,
      minWidth:140,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.createdDate ? row.createdDate : null;
        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.formatDateTime(dateTimeString);
        }
      },
      headerClassName: "table-header",
    },
    {
      field: "fileName",
      headerName: "File Name",
      flex: 1,
      minWidth:140,
      valueGetter: (value: any, row: any) => `${row.fileName || ""}`,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "user",
      headerName: "Submitted By",
      flex: 1,
      minWidth:140,
      valueGetter: (value: any, row: any) =>
        `${row.firstName || ""} ${row.lastName || ""}`,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth:140,
      sortable: false,
      width: 200,
      headerClassName: "table-header",

      renderCell: (params: any) => {
        //check file have .pdf extension or not
        const isPdf = params.row.fileName.toLowerCase().endsWith(".pdf");

        return (
          <div>
            {/* if file contains the .pdf extension then show the view button */}
            {isPdf && (
              <Tooltip title="View PDF" arrow>
                <IconButton
                  onClick={() =>
                    CommonService.showPdf(
                      params.row.id,
                      params.row.companyId,
                      orderDetails.siteId,
                      params.row.fileName,
                      Constant.RequestType.ORDER_REQUEST
                    )
                  }
                >
                  <VisibilityOutlined />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="Download document" arrow>
              <IconButton
                onClick={(event) => downloadOrderRequestDOC(params.row)}
              >
                {/* <CloudDownloadOutlinedIcon /> */}
                <img
                  className="cursor-pointer"
                  src={DownLoadIcon}
                  alt="EditIcon"
                />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="usersList position-relative pt-0">
        <div className="usersList__header--wrapper detail-page-header--wrapper d-flex p-3">
          {CommonService.isAdmin() && (
            <div className="usersList__header--inner d-flex justify-content-between gap-4">
              <div className="d-flex align-items-center gap-1">
                <div className="back__button me-1" onClick={routeBack}>
                  <IconButton
                    aria-label="ArrowBackIcon"
                    sx={{ backgroundColor: "#FFF" }}
                  >
                    <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
                  </IconButton>
                </div>

                <div className="header--title me-2">
                  <h1 className="title mb-0 text-nowrap">
                    Order Request Details
                    <i className="bi bi-heart-fill"></i>
                  </h1>
                </div>
              </div>
            
            {/* <div className="header--title d-flex flex-wrap me-auto mb-xxl-0 gap-xl-3">
              <div className="company__details me-4 me-xl-4 mb-0">
                <Typography
                  variant="h6"
                  sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                >
                  Request number
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    color: "#333333",
                    fontSize: { xl: "18px", xs: "16px" },
                    fontWeight: 700,
                  }}
                >
                  {orderDetails?.requestNumber}
                </Typography>
              </div>
              {!isSeller && (
                <>
                  <div className="company__details me-4 me-xl-4 mb-0">
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#333333",
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >
                      Company name
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        color: "#333333",
                        fontSize: { xl: "18px", xs: "16px" },
                        fontWeight: 700,
                      }}
                    >
                      {orderDetails?.companyName}
                    </Typography>
                  </div>
                  <div className="person__details me-4 me-xl-4 mb-0">
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#333333",
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >
                      Requested by
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        color: "#333333",
                        fontSize: "18px",
                        fontWeight: 700,
                      }}
                    >
                      {orderDetails?.firstName} {orderDetails?.lastName}
                    </Typography>
                  </div>
                </>
              )}
              <div className="time__details me-4 me-xl-4 mb-0">
                <Typography
                  variant="h6"
                  sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                >
                  Requested on
                </Typography>
                <Typography
                  variant="h3"
                  sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
                >
                  {!isSeller
                    ? CommonService.formatDateTime(orderDetails?.createdDate)
                    : CommonService.formatDateTime(
                        historyData[historyData.length - 1]?.histories[
                          historyData[historyData.length - 1].histories.length -
                            1
                        ]?.createdDate
                      )}
                </Typography>
              </div>
              {orderDetails?.trackNumber && !isSeller && (
                <div className="time__details me-4 me-xl-4 mb-0">
                  <Typography
                    variant="h6"
                    sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                  >
                    Track Number
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
                  >
                    {orderDetails?.trackNumber}
                  </Typography>
                </div>
              )}
            </div> */}
            </div>)}
          <div className="d-flex align-items-center flex-column flex-lg-row justify-content-lg-between w-100 gap-2 gap-lg-0">
          <div className="header--title d-flex flex-wrap me-auto mb-xxl-0 gap-xxl-3">
              <div className="company__details me-3 me-xl-4 mb-0">
                <Typography
                  variant="h6"
                  sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                >
                  Request number
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    color: "#333333",
                    fontSize: { xl: "18px", xs: "16px" },
                    fontWeight: 700,
                  }}
                >
                  #{orderDetails?.requestNumber}
                </Typography>
              </div>
              {CommonService.isAdmin() && (
                  <div className="company__details me-3 me-xl-4 mb-0">
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#333333",
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >
                      Company name
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        color: "#333333",
                        fontSize: { xl: "18px", xs: "16px" },
                        fontWeight: 700,
                      }}
                    >
                      {orderDetails?.companyName}
                    </Typography>
                  </div>
                )}
                {(CommonService.isAdmin() || !isSeller) && (
                  <div className="person__details me-3 me-xl-4 mb-0">
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#333333",
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >
                      Requested by
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        color: "#333333",
                        fontSize: "18px",
                        fontWeight: 700,
                      }}
                    >
                      {orderDetails?.firstName} {orderDetails?.lastName}
                    </Typography>
                  </div>
              )}
              <div className="time__details me-3 me-xl-4 mb-0">
                <Typography
                  variant="h6"
                  sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                >
                  Requested on
                </Typography>
                <Typography
                  variant="h3"
                  sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
                >
                  {!isSeller
                    ? CommonService.formatDateTime(orderDetails?.createdDate)
                    : CommonService.formatDateTime(
                        historyData[historyData.length - 1]?.histories[
                          historyData[historyData.length - 1].histories.length -
                            1
                        ]?.createdDate
                      )}
                </Typography>
              </div>
              {orderDetails?.trackNumber && !isSeller && (
                <div className="time__details me-3 me-xl-4 mb-0">
                  <Typography
                    variant="h6"
                    sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                  >
                    Track Number
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
                  >
                    {orderDetails?.trackNumber}
                  </Typography>
                </div>
              )}
              {orderDetails?.ownershipTransferDate && !isSeller && (
                <div className="time__details me-3 me-xl-4 mb-0">
                  <Typography
                    variant="h6"
                    sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                  >
                    Date of transfer
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
                  >
                    {orderDetails?.ownershipTransferDate}
                  </Typography>
                </div>
              )}
            </div>
          <div className="ms-auto">
            <div className="header--menu ms-auto mb-xxl-0">
              <div className="header--menu-items">
                {/* <div className="col-auto menu-items__btnGroup d-inline-flex mb-3"> */}
                <IconButton
                  aria-label="message"
                  size="small"
                  className="position-relative"
                  onClick={
                    CommonService.isAdmin()
                      ? handleAdminMessageClick
                      : handleMessageBoxClick
                  }
                  sx={{
                    borderRadius: "50%",
                    backgroundColor: isMessageBoxOpen ? "#97AEB8" : "#FFF",
                    color: isMessageBoxOpen ? "#FFF" : "#1C1B1F",
                    marginInline: "8px",
                    padding: "8px",
                  }}
                >
                  <MarkunreadOutlinedIcon />
                  {unreadGroupsCount != 0 && CommonService.isAdmin() && (
                    <div className="msg-count-wrapper">{unreadGroupsCount}</div>
                  )}
                  {showMessageBox && <ArrowDropUpIcon className="arrow-icon" />}
                </IconButton>
                {/* {showMessageBox && (
                  <div className="admin-message-box">
                    <div
                      className="admin-message-box-left-inner"
                      onClick={handleAdminMessageClick}
                    ></div>
                    <div className="admin-message-box-inner positio-relative">
                      <AdminMessageBox
                        selectedGroup={""}
                        requestId={id}
                        requestType={"ORDER_REQUEST"}
                        unreadGroupsCount={unreadGroupsCount}
                        setUnreadGroupsCount={setUnreadGroupsCount}
                        isSeller={isSeller}
                      />
                    </div>
                  </div>
                )} */}

                {/* {CommonService.isAdmin() && (<button onClick={handleEditButtonClick} className="border-0 bg-transparent"> <BorderColorIcon/></button>)} */}

                {/* Pending Review - User */}
                {([
                  "PENDING_REVIEW",
                  "UNDER_REVIEW",
                  "ACCEPTED",
                  "IN_PROGRESS",
                  "CONTRACT_SENT",
                  "CONTRACT_EXECUTED",
                  "INVOICE_SENT",
                  "PAYMENT_RECEIVED",
                  "OWNERSHIP_TRANSFERRED",
                  "IN_TRANSIT",
                  "REJECTED",
                ].includes(orderDetails?.orderStatus) ||
                  orderDetails?.orderStatus === "ACCEPTED_REJECTED") &&
                  CommonService.isUser() && (
                    <StatusDropdown
                      currentStatus={orderDetails?.orderStatus}
                      onStatusChange={(newStatus) =>
                        handleStatusChange(newStatus, orderDetails?.orderStatus)
                      }
                      disableCondition={
                        CommonService.isAdmin() ||
                        (!CommonService.isSameUser(orderDetails?.createdBy) && CommonService.isSameCompany(orderDetails?.companyId) && CommonService.getRole() === Constant.Role.USER) ||  
                        !CommonService.isSameCompany(orderDetails?.companyId) ||
                        [
                          "OWNERSHIP_TRANSFERRED",
                          "IN_TRANSIT",
                          "REJECTED",
                        ].includes(orderDetails?.orderStatus)
                      }
                      allowedLabels={["Cancelled"]}
                    />
                  )}

                {/* Pending Review or Under Review - Admin */}
                {(orderDetails?.orderStatus === "PENDING_REVIEW" ||
                  orderDetails?.orderStatus === "UNDER_REVIEW") &&
                  CommonService.isAdmin() && (
                    <StatusDropdown
                      currentStatus={formatStatus(orderDetails?.orderStatus)}
                      onStatusChange={(newStatus) =>
                        handleStatusChange(newStatus, orderDetails?.orderStatus)
                      }
                      disableCondition={
                        CommonService.isUser() ||
                        orderDetails?.orderStatus === "CANCELLED" ||
                        orderDetails?.orderStatus === "REJECTED"
                      }
                      allowedLabels={
                        isStatusValid
                          ? ["Under Review", "Rejected"]
                          : isItemRejected
                          ? ["Under Review", "Rejected"]
                          : ["Under Review", "Accepted", "Rejected"]
                      }
                    />
                  )}

                {/* Under Review - User (disabled) */}
                {/* {(orderDetails?.orderStatus === "UNDER_REVIEW" && CommonService.isUser()) && (
        <StatusDropdown
          currentStatus={formatStatus(orderDetails?.orderStatus)}
          onStatusChange={(newStatus) => handleStatusChange(newStatus, orderDetails?.orderStatus)}
          disableCondition={true}
          allowedLabels={[]}
        />
      )} */}

                {/* Accepted to Payment Received - Both User and Admin */}
                {[
                  "ACCEPTED",
                  "REJECTED",
                  "IN_PROGRESS",
                  "CONTRACT_SENT",
                  "CONTRACT_EXECUTED",
                  "INVOICE_SENT",
                  "PAYMENT_RECEIVED",
                ].includes(orderDetails?.orderStatus) &&
                  CommonService.isAdmin() && (
                    <StatusDropdown
                      currentStatus={formatStatus(orderDetails?.orderStatus)}
                      onStatusChange={(newStatus) =>
                        handleStatusChange(newStatus, orderDetails?.orderStatus)
                      }
                      disableCondition={
                        CommonService.isUser() ||
                        orderDetails?.orderStatus === "CANCELLED" ||
                        orderDetails?.orderStatus === "REJECTED"
                      }
                      allowedLabels={[
                        "In Progress",
                        "Contract Sent",
                        "Contract Executed",
                        "Invoice Sent",
                        "Payment Received",
                        "Ownership Transferred",
                        "Rejected",
                      ]}
                    />
                  )}

                {/* Accepted - Admin specific options */}
                {(orderDetails?.orderStatus === "OWNERSHIP_TRANSFERRED" || orderDetails?.orderStatus === "IN_TRANSIT") &&
                  CommonService.isAdmin() && (
                    <StatusDropdown
                      currentStatus={formatStatus(orderDetails?.orderStatus)}
                      onStatusChange={(newStatus) =>
                        handleStatusChange(newStatus, orderDetails?.orderStatus)
                      }
                      disableCondition={CommonService.isUser()}
                      allowedLabels={[...(relocationFlag ? ["In Transit"] : []), "Closed"]}
                    />
                  )}

                {/* Ownership Transferred or Cancelled - Both User and Admin (disabled) */}
                {(orderDetails?.orderStatus === "CANCELLED" ||
                  orderDetails?.orderStatus === "CLOSED") && (
                  <StatusDropdown
                    currentStatus={formatStatus(orderDetails?.orderStatus)}
                    onStatusChange={(newStatus) =>
                      handleStatusChange(newStatus, orderDetails?.orderStatus)
                    }
                    disableCondition={true}
                    allowedLabels={[]}
                  />
                )}

                {(orderDetails?.orderStatus === "REJECTED" ||
                  orderDetails?.orderStatus === "CANCELLED") && orderDetails?.orderRejectionNote && (
                  <Tooltip
                    title={`Rejection Note: ${orderDetails.orderRejectionNote}`}
                    arrow
                  >
                    <StickyNote2Icon
                      className="note-icon cursor-pointer"
                      style={{ color: "gray" }}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
          </div>
        </div>
        <div className="viewSampleRequest--wrapper viewOrderRequest--wrapper">
          <div
            className={`${
              isOpenEditSection
                ? "margin-right-400" // When only `isOpenEditSection` is true
                : isMessageBoxOpen
                ? "margin-right-400" // Only `isMessageBoxOpen` is true
                : "no-margin" // When none of the conditions are met
            }`}
          >
            <div className="row m-0">
              <div className="col-12 px-0">
                <div className="view-sample-request">
                  <div>
                    <div className="inventory-file-list-table pb-0">
                      <InventoryFileListTable
                        cartItems={addedCartItems}
                        onEditClick={handleEditClick}
                        onDeleteRestoreClick={handleOrderItemActiveStatus}
                        onOrderItemStatusChange={handleOrderItemStatusChange}
                        viewDetails={viewDetails}
                        handleViewClose={handleViewClose}
                        handleViewShow={handleViewShow}
                        isNotEnoughBarrels={isNotEnoughBarrels}
                        isNotSelectedRequiredBarrel={isNotSelectedRequiredBarrel}
                        clearArrays={clearArrays}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-xl-6 mb-3 mb-xl-0 ps-0 pe-xl-2 pe-0">
                <div className="orderDetail-footer-content-wrapper">
                  <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                    <h3 className="mb-0">List of Documents</h3>
                    <UploadDocs
                      fetchData={fetchData}
                      csvId={id}
                      uploadCSV={false}
                      requestType={"ORDER_REQUEST"}
                    />
                  </div>
                  <div
                    className="inventory-doc-table"
                    // style={{ height: 350, width: "100%" }}
                  >
                    <DataGrid
                      localeText={localeText}
                      rows={orderRequestDocs}
                      columns={columnsForDoc}
                      className="data-grid upload-doc-datagrid-table"
                      loading={isLoading}
                      // hideFooterPagination
                      hideFooter
                      disableColumnResize
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-6 mb-3 mb-xl-0 pe-0 ps-xl-2 ps-0">
                <div className="recentActivity">
                  <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                    <h3 className="mb-2">Recent Activity</h3>
                  </div>
                  <div className="history-stepper-wrapper">
                    <HistoryStepper historyData={historyData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(isOpenEditSection || isMessageBoxOpen) && (
            <div className="msgContainerBox">
              {isOpenEditSection && (
                <EditRequest
                  requestType="ORDER_REQUEST"
                  id={orderItemId}
                  refreshData={fetchData}
                  onEditClick={handleEditClick}
                />
              )}
              {isMessageBoxOpen && (
                <ContainerMessageBox
                  requestType={"ORDER_REQUEST"}
                  sellerCompanyId={
                    isSeller ? CommonService.getCompanyId() : null
                  }
                  isSeller={isSeller}
                />
              )}
            </div>
          )}
          {/* {viewBarrelSelection && <BarrelSelectionPanel handleCloseBarrelSelection={handleCloseBarrelSelection} />} */}
          {showMessageBox && (
                  <div className="admin-message-box">
                    <div
                      className="admin-message-box-left-inner"
                      onClick={handleAdminMessageClick}
                    ></div>
                    <div className="admin-message-box-inner positio-relative">
                      <AdminMessageBox
                        selectedGroup={""}
                        requestId={id}
                        requestType={"ORDER_REQUEST"}
                        unreadGroupsCount={unreadGroupsCount}
                        setUnreadGroupsCount={setUnreadGroupsCount}
                        isSeller={isSeller}
                      />
                    </div>
                  </div>
                )}
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        className="modal__wd modal__wd--submitSellReq"
      >
        <Modal.Header className="flex-column align-items-start">
          {isOrderItemStatusChange ? (
            <Modal.Title>Update Owner Status</Modal.Title>
          ) : (
            <Modal.Title>Update Order Request Status</Modal.Title>
          )}
          {isApproved && (<>
            {statusDetailsRef.current.status === Constant.OrderStatus.IN_TRANSIT && <p className="modal-text mt-2">
              Please Enter Track Number
            </p>}
            {statusDetailsRef.current.status === Constant.OrderStatus.OWNERSHIP_TRANSFERRED && <p className="modal-text mt-2">
              Please Enter Date of transfer
            </p>}
          </>)}
          {!isApproved && !isBeforeCancel && !isAfterCancel && statusDetailsRef.current.status != 'CLOSED' && (
            <p className="modal-text mt-2">
              Are you sure, you want to change status to{" "}
              {formatStatus(statusDetailsRef.current.status)}?
            </p>
          )}
          {isBeforeCancel && (
            <p className="modal-text mt-2">Are you sure, you want to cancel?</p>
          )}
          {isAfterCancel && (
            <p className="modal-text mt-2">
              Are you sure, you want to cancel? A cancellation charge may apply.
            </p>
          )}
          {statusDetailsRef.current.status === 'CLOSED' && (
            <p className="modal-text mt-2">
              Once the request is closed, it cannot be reopened. Are you sure you want to close it?
            </p>
          )}
        </Modal.Header>
        {["OWNERSHIP_TRANSFERRED", "IN_TRANSIT", "REJECTED", "CANCELLED", "ADMIN_REJECTED"].includes(
          statusDetailsRef.current.status
        ) && (
          <Modal.Body className="pb-1">
            <div className="form__inner">
              {isApproved && (<>
                <div className="form__inner">
                  {statusDetailsRef.current.status === Constant.OrderStatus.IN_TRANSIT && <>
                    <label className="modal-body__title mb-1">Track Number</label>
                    <Form.Control
                      className={`form-control ${error ? "is_invalid" : ""}`}
                      type="text"
                      value={trackNumberValue}
                      onChange={handleInputChange}
                      placeholder="Enter Tracking Number..."
                    /></>}

                  {statusDetailsRef.current.status === Constant.OrderStatus.OWNERSHIP_TRANSFERRED && <>
                    <label className="modal-body__title mb-1">Date of transfer</label>
                    <Form.Control
                      type="date"
                      placeholder="Birthdate"
                      value={ownershipTransferDateValue}
                      onChange={handleInputChange}
                      onClick={handleDateClick}
                      className={`form-control ${error ? "is_invalid" : ""}`}
                    /></>}
                  {error && <div className="error-message">{error}</div>}
                </div>
              </>)}

              {(!isApproved || isBeforeCancel || isAfterCancel) && (
                <form className="form__inner">
                  <textarea
                    required
                    className={`form-control ${error ? "is_invalid" : ""}`}
                    aria-label="With textarea"
                    value={rejectionNoteValue}
                    onChange={handleInputChange}
                    rows={8}
                    style={{ resize: "none" }}
                    placeholder="Enter text here..."
                  />
                   {/* <span className="ms-auto">{rejectionNoteValue.length}/500</span> */}
                  {error && <div className="error-message">{error}</div>}
                </form>
              )}

              {isApproved && statusDetailsRef.current.status === Constant.OrderStatus.IN_TRANSIT && (
                <div className="d-flex align-items-center h-100 mt-2">
                  <div className="create-ticket-attachement-wrapper d-flex flex-wrap align-items-center gap-1">
                    <IconButton
                      type="button"
                      aria-label="submit"
                      sx={{
                        transform: "rotate(45deg)",
                        padding: "8px",
                      }}
                      onClick={handleButtonClick}
                    >
                      <AttachFileIcon />
                    </IconButton>
                    <span
                      className="cursor-pointer"
                      onClick={handleButtonClick}
                    >
                      Upload Document
                    </span>
                    <div className="d-flex align-items-center gap-2 flex-wrap">
                      {files?.map((file, index) => (
                        <div
                          className="file__upload--info d-flex align-items-center mb-1 me-2"
                          key={index}
                        >
                          <div className="file-name me-3">{file.name}</div>
                          <div className="file-delete">
                            <img
                              src={TrashIcon}
                              alt="TrashIcon"
                              onClick={() => handleDeleteFile(index)}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                    <div style={{ position: "relative" }}>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        multiple
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
        )}
        <Modal.Footer
          className={`justify-content-center 
          ${
            ["OWNERSHIP_TRANSFERRED", "IN_TRANSIT", "REJECTED", "CANCELLED", "ADMIN_REJECTED"].includes(
              statusDetailsRef.current.status
            )
              ? ""
              : "bg-transparent"
          }`}
        >
          <div className="btn-group row d-flex justify-content-between w-100">
            <div className="col-6 ps-0">
              <button
                className="secondary-btn w-100"
                onClick={handleClose}
                disabled={isLoading}
              >
                No
              </button>
            </div>

            <div className="col-6 pe-0">
              <button
                className="primary-btn w-100"
                onClick={
                  isOrderItemStatusChange
                    ? changeOrderItemStatus
                    : changeOrderStatus
                }
                disabled={isLoading}
              >
                {isLoading && <Spinner />} 
                {["REJECTED", "ADMIN_REJECTED"].includes(statusDetailsRef.current?.status)
                  ? "Reject Request"
                  : statusDetailsRef.current?.status === "CANCELLED"
                    ? "Cancel Request"
                    : "Yes"}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderDetail;
