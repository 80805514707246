import React, { useState, useEffect } from "react";

import "bootstrap/dist/js/bootstrap.bundle.min"; // Ensure Bootstrap JS is imported

import "../Dynamic/FaqPage.scss";
import { Info } from "@mui/icons-material";
// import { IconButton } from "@mui/material";
import { Dropdown, ListGroup } from "react-bootstrap";
import User from "../popups/RejectUser";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";



const FAQHeader: React.FC = () => {






  return (
    //     <div className={ `header__banner bg-body-tertiary header__for-user`}>
    //       <div className="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center">

    //  sddadasdasddad
    //       </div>
    //     </div>  
    <header className="wd__header wd__header--user faq-header">
    <div className={`header__banner bg-body-tertiary`}>
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center">
        <div className="container">
          <div
            className="back__button me-3 d-flex align-items-center"
          >

            {/* <IconButton
              aria-label="ArrowBackIcon"
              sx={{
                backgroundColor: "#FFF",
                mb: 1,
                "&:hover": { backgroundColor: "#FFF" },
              }}
            // onClick={() => historyBack()}
            >
              <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
            </IconButton> */}

            {/* {(currentPath.startsWith("/user/user-detail")) && ( */}
            <h3 className="ms-2 fw-bold text-white">
              FAQs
            </h3>
            {/* )} */}


          </div>
        </div>
        {/* {userRole == "ADMIN" && (
              <div className="header--title">
                <h1 className="title text-white fs-2 fw-semibold mb-0">
                  My Inventory
                  <i className="bi bi-heart-fill"></i>
                </h1>
              </div>
            )} */}
      </div>
      {/* <ProfileBar /> */}
    </div>
    </header>
  );
};

export default FAQHeader;
