import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { CommonService } from "../../Service/CommonService";
import { InventoryApiService } from "../../Service/InventoryApiService";
import CKContent from "../Common/CKcontent";

const ViewInventoryListingDetails: React.FC = () => {

    const { id } = useParams<{ id: string }>();
    const [tableData, setTableData] = useState<any[]>([]);
    const [inventoryListingDetails, setInventoryListingDetails] = useState<any>([]);
    const [activeSection, setActiveSection] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [sortField, setSortField] = useState("productName"); // Default sort
    const [sortDirection, setSortDirection] = useState("asc"); // Default ascending
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });

    const [filter, setFilter] = useState<any>({
        productName: null,
        lotNo: null,
        barrelNumber: null,
        fillDate: null,
        ttbType: null,
        ttbClass: null,
        volume: null,
        row: null,
        depth: null,
        height: null
    });

    function handleShowDetailTable(section) {
        setActiveSection(prev => (prev === section ? null : section));
        fetchInventoryListingDetailsData(section, paginationModel.page, paginationModel.pageSize, filter, sortField, sortDirection);
    }
    useEffect(() => {
        getInventoryListingDetails();
    }, []);

    const getInventoryListingDetails = async () => {
        try {
            const response = await InventoryApiService.getInventoryListingDetailsRequest(id);
            setInventoryListingDetails(response?.data);
        } catch (error) {
            console.log("error occurred while api calling ");
        }
    }

    const fetchInventoryListingDetailsData = async (selectActiveSection: any, page: any, size: any, filter: any, sortField: any, sortDirection: any) => {
        const controller = new AbortController();
        const signal = controller.signal;

        if(selectActiveSection === '') {
            selectActiveSection = activeSection;
        }
        
        try {
            setIsLoading(true);
            setTableData([]);

            const response: any = await InventoryApiService.getInventoryListingDetailsByStatusRequest(id, selectActiveSection, page, size, filter, sortField, sortDirection, signal);
            
            setTableData(Object.values(response.data.content));
            setTotalCount(response.data.totalElements);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false);
        }
    };

    const handleFilterChange = (
            event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
            field: keyof any
        ) => {
            const value = event.target.value;
    
            const tempFilter = {
                ...filter,
                [field]: value,
            };
    
            setFilter(tempFilter);
            fetchInventoryListingDetailsData(
                activeSection,
                paginationModel.page,
                paginationModel.pageSize,
                tempFilter,
                sortField, sortDirection
            );
        };

    const CellWithTooltip = ({ value }: { value: string | number }) => {
        return (
            <Tooltip arrow title={value?.toString()}>
                <span>{value?.toString()}</span>
            </Tooltip>
        );
    };
    const columns: GridColDef[] = [
        {
            field: "productName",
            headerName: "Product Name",
            flex: 1,
            minWidth: 220,
            sortable: true,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{ width: "200px" }}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search"
                             defaultValue={filter.productName}
                             onChange={(event) => handleFilterChange(event, "productName")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
            renderCell: (params: any) => <CellWithTooltip value={params.value} />
        },
        {
            field: "lotNo",
            headerName: "Lot ID",
            flex: 1,
            minWidth: 180,
            sortable: true,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{ width: "180px" }}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search"
                            defaultValue={filter.lotNo}
                            onChange={(event) => handleFilterChange(event, "lotNo")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
            field: "barrelNumber",
            headerName: "Barrel Number",
            flex: 1,
            minWidth: 180,
            sortable: true,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{ width: "200px" }}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search"
                             defaultValue={filter.barrelNumber}
                             onChange={(event) => handleFilterChange(event, "barrelNumber")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
            renderCell: (params: any) => <CellWithTooltip value={params.value} />
        },
        {
            field: "fillDate",
            headerName: "Fill Date",
            headerClassName: "table-header border-0 my-2 h-100",
            minWidth: 139,
            flex: 1,
            sortable: true,
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
            valueGetter: (value: any, row: any) => {
                if (!row.fillDate) return "";
                return format(new Date(row.fillDate), "MMM dd, yyyy");
            },
        },  
        {
            field: "ttbType",
            headerName: "TTB Type",
            flex: 1,
            minWidth: 180,
            sortable: true,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{ width: "200px" }}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search"
                             defaultValue={filter.ttbType}
                             onChange={(event) => handleFilterChange(event, "ttbType")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
            renderCell: (params: any) => <CellWithTooltip value={params.value} />
        },
        {
            field: "ttbClass",
            headerName: "TTB Classs",
            flex: 1,
            minWidth: 180,
            sortable: true,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{ width: "200px" }}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search"
                             defaultValue={filter.ttbClass}
                             onChange={(event) => handleFilterChange(event, "ttbClass")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
            renderCell: (params: any) => <CellWithTooltip value={params.value} />
        },
        {
            field: "volume",
            headerName: "Volume",
            flex: 1,
            minWidth: 180,
            sortable: true,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{ width: "200px" }}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search"
                             defaultValue={filter.volume}
                             onChange={(event) => handleFilterChange(event, "volume")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
            renderCell: (params: any) => <CellWithTooltip value={params.value} />
        },
        {
            field: "row",
            headerName: "Row",
            flex: 1,
            minWidth: 140,
            sortable: true,
            headerClassName: "table-header border-0 my-2 h-100",
            renderCell: (params: any) => <CellWithTooltip value={params.value} />
        },
        {
            field: "depth",
            headerName: "Depth",
            flex: 1,
            minWidth: 140,
            sortable: true,
            headerClassName: "table-header border-0 my-2 h-100",
            renderCell: (params: any) => <CellWithTooltip value={params.value} />
        },
        {
            field: "height",
            headerName: "Height",
            flex: 1,
            minWidth: 140,
            sortable: true,
            headerClassName: "table-header border-0 my-2 h-100",
            renderCell: (params: any) => <CellWithTooltip value={params.value} />
        },
    ].filter(Boolean) as GridColDef[];

    const tableDataWithIds = tableData.map((rowData, index) => ({
        ...rowData,
    }));

    const routeBack = () => {
        history.goBack();
      };

    const onPaginationModelChange = (newPaginationModel) => {
        setPage(newPaginationModel.page);
        setPageSize(newPaginationModel.pageSize);

        fetchInventoryListingDetailsData("",newPaginationModel.page,newPaginationModel.pageSize,filter, sortField, sortDirection);
    };

    const handleSortChange = (sortModel) => {
        if (sortModel.length > 0) {
            const newSortField = sortModel[0].field;
            const newSortDirection = sortModel[0].sort; // "asc" or "desc" directly from DataGrid
            
            setSortField(newSortField);
            setSortDirection(newSortDirection);
        
            fetchInventoryListingDetailsData("",page, pageSize, filter, newSortField, newSortDirection);
        }
    };
      
    return (
        <>
            <div className="usersList position-relative pt-0">
                <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center p-3">
                    <div className="d-flex flex-wrap justify-content-between gap-4">
                        {CommonService.isAdmin() && (
                            <div className="d-flex align-items-center gap-1">
                                <><div className="back__button me-1" onClick={routeBack}>
                                    <IconButton
                                        aria-label="ArrowBackIcon"
                                        sx={{ backgroundColor: "#FFF" }}
                                    >
                                        <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
                                    </IconButton>
                                </div>
                                    <div className="header--title me-3">
                                        <h1 className="title mb-0">
                                            Inventory Details
                                            <i className="bi bi-heart-fill"></i>
                                        </h1>
                                    </div></>
                            </div>
                        )}
                    </div>
                </div>
                <div className="viewSampleRequest--wrapper view_request--wrapper view_sample-wrapper viewOrderRequest--wrapper viewServiceRequest--wrapper viewSupport-req inventory-listing-detail">
                   <div className="no-margin w-100">
                        <div className="view-sample-request m-0">
                            <div className="row m-0">
                                <div className="col-12 px-0">
                                    <div className="inventory-file-list-table" style={{minHeight:"calc(100vh - 175px)"}}>
                                        <div className="row m-0 h-100">
                                            <div className="col-xl-4 px-0">
                                                {CommonService.isAdmin() &&
                                                    inventoryListingDetails && inventoryListingDetails.length > 0 && (
                                                        <p className="content-text inventory-class-name" >
                                                            {inventoryListingDetails[0].ownerName || ""}
                                                        </p>
                                                )}

                                                {inventoryListingDetails && inventoryListingDetails.length > 0 && (
                                                    <div className="left-wrapper">
                                                        <div className="left-heading">{inventoryListingDetails[0].title}</div>
                                                        <div className="left-sub-heading">Description</div>
                                                        <div className="left-content-wrapper">
                                                        <CKContent 
                                                                    content={inventoryListingDetails[0].description}
                                                                    className="custom-class"
                                                                />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-xl-8 px-0">
                                                <div className="right-wrapper">
                                                    {inventoryListingDetails && inventoryListingDetails.length > 0 && (
                                                        <div className="row m-0">
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">
                                                                        Price/Barrel
                                                                    </p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text">
                                                                        ${inventoryListingDetails[0].price ? Number(inventoryListingDetails[0].price).toFixed(2) : "0.00"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">
                                                                        Fee/Barrel
                                                                    </p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text">
                                                                        {inventoryListingDetails[0].feeType === "PERCENTAGE"
                                                                            ? `${inventoryListingDetails[0].fee ? Number(inventoryListingDetails[0].fee).toFixed(2) : "0.00"}%`
                                                                            : `$${inventoryListingDetails[0].fee ? Number(inventoryListingDetails[0].fee).toFixed(2) : "0.00"}`}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">Age</p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text">
                                                                        {(() => {
                                                                            if (!inventoryListingDetails[0]?.fillDate) return "";

                                                                            const currentDate = new Date();
                                                                            const fillDate = new Date(inventoryListingDetails[0].fillDate);

                                                                            let years = currentDate.getFullYear() - fillDate.getFullYear();
                                                                            let months = currentDate.getMonth() - fillDate.getMonth();

                                                                            // Adjust for negative months
                                                                            if (months < 0) {
                                                                                years--;
                                                                                months += 12;
                                                                            }

                                                                            return `${years} years ${months} months`;
                                                                        })()}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">Lot IDs</p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <div className="content-text">
                                                                        <div className="lot-id-list-wrapper d-flex flex-nowrap gap-2 h-100 align-items-center">
                                                                            {inventoryListingDetails[0].lotNo?.split(", ").map((lot, index) => (
                                                                                <div key={index} className="lot-id-item px-2 py-1 border rounded">{lot}</div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">
                                                                        Created Date
                                                                    </p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text"> {new Date(inventoryListingDetails[0].createdDate).toLocaleDateString("en-US", {
                                                                        day: "2-digit",
                                                                        month: "2-digit",
                                                                        year: "numeric",
                                                                    }) || ""}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">
                                                                        Location
                                                                    </p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text">{inventoryListingDetails[0].location || "-"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">
                                                                        Rackhouse
                                                                    </p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text">{inventoryListingDetails[0].rackhouse || "-"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">
                                                                        Facility
                                                                    </p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text">{inventoryListingDetails[0].facility || "-"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">
                                                                        Mashbill
                                                                    </p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text">{inventoryListingDetails[0].mashBill || "-"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">
                                                                        DSP
                                                                    </p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text">{inventoryListingDetails[0].dsp || "-"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">
                                                                        Batch Name
                                                                    </p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text">{inventoryListingDetails[0].batchName || "-"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">
                                                                       Producer
                                                                    </p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text">{inventoryListingDetails[0].producer || "-"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div
                                                                    className={`content-wrapper cursor-pointer ${activeSection === "LISTED" ? "active" : ""}`}
                                                                    onClick={() => handleShowDetailTable("LISTED")}
                                                                >
                                                                    <p className="content-title">Available Barrels</p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text-status">
                                                                        {inventoryListingDetails[0]?.availableBarrels ?? 0}{" "}
                                                                        {inventoryListingDetails[0]?.availableBarrels === 1 || inventoryListingDetails[0]?.availableBarrels === 0 ? "Barrel" : "Barrels"}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div
                                                                    className={`content-wrapper cursor-pointer ${activeSection === "RESERVED" ? "active" : ""}`}
                                                                    onClick={() => handleShowDetailTable("RESERVED")}
                                                                >
                                                                    <p className="content-title">Reserved Barrels</p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text-status">
                                                                        {inventoryListingDetails[0]?.reservedBarrels ?? 0}{" "}
                                                                        {inventoryListingDetails[0]?.reservedBarrels === 1 || inventoryListingDetails[0]?.reservedBarrels === 0 ? "Barrel" : "Barrels"}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div
                                                                    className={`content-wrapper cursor-pointer ${activeSection === "SOLD" ? "active" : ""}`}
                                                                    onClick={() => handleShowDetailTable("SOLD")}
                                                                >
                                                                    <p className="content-title">Sold Barrels</p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text-status">
                                                                        {inventoryListingDetails[0]?.soldBarrels ?? 0}{" "}
                                                                        {inventoryListingDetails[0]?.soldBarrels === 1 || inventoryListingDetails[0]?.soldBarrels === 0 ? "Barrel" : "Barrels"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {activeSection &&
                                                        <div className="row m-0 mt-4">
                                                            <div className="col-12 px-0">
                                                                <div className="userTable inventory-listing-table-wrapper">
                                                                    <DataGrid
                                                                    autoHeight
                                                                        rows={tableDataWithIds}
                                                                        columns={columns}
                                                                        className="data-grid data-grid__myinventories"
                                                                        initialState={{
                                                                            pagination: {
                                                                                paginationModel: { page, pageSize },
                                                                            },
                                                                        }}
                                                                        pagination
                                                                        paginationModel={{page, pageSize }}
                                                                        paginationMode="server"
                                                                        sortingMode="server"
                                                                        onSortModelChange={handleSortChange}
                                                                        onPaginationModelChange={onPaginationModelChange}
                                                                        pageSizeOptions={[10, 20, 50, 100]}
                                                                        rowCount={totalCount}
                                                                        disableRowSelectionOnClick={true}
                                                                        loading={isLoading}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ViewInventoryListingDetails;
