import React, { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Service } from "../../Service/ApiService";
import { CommonService } from "../../Service/CommonService";
import Logo from "../Logo/Logo";
import Spinner from "../Common/Spinner";
import { IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useFormValidation from "../Dynamic/FormValidation";
import "../Dynamic/AddCompanyOrUser.scss"; 
import InputMask from 'react-input-mask';



const Register: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState<any[]>([]);
  const [companyName, setCompanyName] = useState<string>("");

  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    zipcode: "",
    roleId: "",
    userStatus: "PENDING_REVIEW",
    id: id,
    termsAccepted: false,
    barrelBroker: false,
    birthdate: "",
    streetAddress: "",
    companyId: null
  };

  const {
    formData,
    errors,
    handleChange,
    handleBlur,
    validateForm,
    setErrors,
    setFormData,
  } = useFormValidation(initialState);

  const getEmail = async () => {
    try{
      const response = await Service.getEmailById(id);
      const [email, companyName, companyId] = response.data.split(',');
      //for disabled company name when company id is there
      setCompanyName(companyName);
      setFormData((prevState) => ({
        ...prevState,
        email,
        companyName,
        companyId: companyId ? companyId : prevState.companyId,
      }));
    } catch (error) {
      console.log("error occurs which fetching the email");
    }
  }

  useEffect(() => {
    getEmail();
    getRole();
  }, []);

  const validateZipcode = async (zipcode: string): Promise<boolean> => {
    // setZipcodeLoading(true);
    try {
      const response = await Service.getCityAndState(zipcode);
      const { city, state } = response.data.zipCode;

      if (city && state) {
        setFormData((prevState) => ({
          ...prevState,
          city,
          state,
          zipcode,
        }));
        setErrors((prevErrors) => ({ ...prevErrors, zipcode: "" }));
        // setValidZipcode(true);
        return true;
      } else {
        setFormData((prevState) => ({
          ...prevState,
          city: "",
          state: "",
          zipcode,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          zipcode: "Please enter a valid zipcode",
        }));
        // setValidZipcode(false);
        return false;
      }
    } catch (error) {
      setFormData((prevState) => ({
        ...prevState,
        city: "",
        state: "",
        zipcode,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        zipcode: "Please enter a valid zipcode",
      }));
      // setValidZipcode(false);
      return false;
    } finally {
      // setZipcodeLoading(false);
    }
  };

  const handleZipcodeBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const zipcode = e.target.value;
    await validateZipcode(zipcode);
  };

  const getRole = async () => {
    const response = await Service.getAllRoles();
    if (response.data) {
      const roles = Array.isArray(response.data) ? response.data : [];
      setRoles(roles);
      const userRole = roles.find((res: any) => res.roleName === "ADMIN");
      if (userRole) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          roleId: userRole.id,
        }));
      }
    }
  };

  const dateInputRef = useRef(null);

  const handleDateClick = () => {
    if (dateInputRef.current) {
      dateInputRef.current?.showPicker(); // This will trigger the date picker to open
    }
  };

  const handleTermAccepted = () => {
    setFormData((prevState) => ({
      ...prevState,
      termsAccepted: prevState.termsAccepted ? false : true,
    }));
    if (errors.termsAccepted) {
      setErrors((prevState) => ({
        ...prevState,
        termsAccepted: "",
      }));
    }
  };

  const handleIsBarrelBroker = () => {
    setFormData((prevState) => ({
      ...prevState,
      isBarrelBroker: prevState.isBarrelBroker ? false : true,
    }));
  }

  const validateRequiredFields = async () => {
    let isValidationPass = true;

    const requiredFields = [
      { field: "firstName", error: "Please enter first name" },
      { field: "birthdate", error: "Please enter birthdate" },
      { field: "email", error: "Please enter email" },
      { field: "companyName", error: "Please enter company name" },
    ];

    requiredFields.forEach(({ field, error }) => {
      if (formData[field].length === 0 || errors[field]) {
        if(formData[field].length === 0) {
        setErrors((prevState) => ({ ...prevState, [field]: error }));
        }
        isValidationPass = false;
      }
    });

    if ( formData.zipcode?.length === 0 || errors.zipcode) {
      if(formData.zipcode?.length === 0) {
      setErrors((prevState) => ({
        ...prevState,
        zipcode: "Please enter zipcode",
      }));
    }
      isValidationPass = false;
    }  else {
      const result = await validateZipcode(formData.zipcode.trim());
      if(!result) {
        isValidationPass = false;
      }
    }

    //also check user accept term and conditions
    if (!formData.termsAccepted) {
      setErrors((prevState) => ({
        ...prevState,
        termsAccepted: "You must accept the Terms & Conditions and Privacy Policy"
      }));
      isValidationPass = false;
    }

    return isValidationPass;
  }

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if(!await validateRequiredFields()){
      setIsLoading(false);
      return;
    }

    // const isZipValid = await validateZipcode(formData.zipcode);
    // if (!isZipValid) {
    //   console.log("invalid zipcode");
    //   setIsLoading(false);
    //   return;
    // }

    try {

      
      const response = await Service.registerUser(formData);
      if (response.data) {
        setIsLoading(false);
        history.push("/successRegister");
      }
    } catch (error: any) {
      setIsLoading(false);
      CommonService.Toast.fire({
        title: error.response.data.errorMessage || "Registration failed",
        icon: "error",
      });
    }
  };

  const getMinAgeDate = () => {
    const today = new Date();
    const minAgeYear = today.getFullYear() - 21;
    return `${minAgeYear}-${String(today.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(today.getDate()).padStart(2, "0")}`;
  };

  const minAgeDate = getMinAgeDate();

  const routeBack = () => {
    history.goBack();
  };

  return (
    <>
      <header className="wd__header wd__header--user">
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container-fluid">
            <a className="navbar-brand" href="#">
              <Logo size={"200px"} />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </nav>
      </header>

      <div className="add-company-user-wrapper">
        <div className="d-flex align-items-center mb-3 px-3">
          <div className="back__button" onClick={routeBack}>
            <IconButton aria-label="ArrowBackIcon" sx={{ backgroundColor: "#FFF" }}>
              <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
            </IconButton>
          </div>
          <div className="title">
            <Typography variant="h1" component="h2" sx={{ ml: 2, fontWeight: 600, fontSize: "25px" }}>
              User Registration
            </Typography>
          </div>
        </div>

        <div className="add-company-user-form-wrapper p-0">
          <div className="login__form register__form mx-auto p-5">
            <form className="form__inner row d-flex" onSubmit={onSubmit}>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="firstName" className="form-label">First Name</label><span className="star-required text-danger">*</span>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="Enter first name"
                  value={formData.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${errors.firstName ? "is_invalid" : ""}`}
                />
                {errors.firstName && <div className="error-message">{errors.firstName}</div>}
              </div>

              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="lastName" className="form-label">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Enter last name"
                  value={formData.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${errors.lastName ? "is-invalid" : ""}`}
                />
              </div>

              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="email" className="form-label">Email Address</label><span className="star-required text-danger">*</span>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter email ID"
                  value={formData.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${errors.email ? "is_invalid" : ""}`}
                />
                {errors.email && <div className="error-message">{errors.email}</div>}
              </div>

              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                <InputMask
                 mask="(999) 999-9999"
                 value={formData.phoneNumber}
                 onChange={handleChange}>
              {(inputProps) => (
                <input
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Enter phone number"
                  className="form-control"
                  {...inputProps}
                  // className={`form-control ${errors.phoneNumber ? "is_invalid" : ""}`}
                />
              )}
                </InputMask>
              </div>

              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="companyName" className="form-label">Company Name</label><span className="star-required text-danger">*</span>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  placeholder="Enter company name"
                  value={formData.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${errors.companyName ? "is_invalid" : ""}`}
                  disabled={companyName !== ""}
                />
                {errors.companyName && <div className="error-message">{errors.companyName}</div>}
              </div>

              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="zipcode" className="form-label">Zip Code</label> <span className="star-required text-danger">*</span>
                <input
                  type="text"
                  id="zipcode"
                  name="zipcode"
                  placeholder="Enter zipcode"
                  value={formData.zipcode}
                  onChange={handleChange}
                  onBlur={handleZipcodeBlur}
                  className={`form-control ${errors.zipcode ? "is_invalid" : ""}`}
                />
                {errors.zipcode && <div className="error-message">{errors.zipcode}</div>}
                {formData.city && formData.state && (
                                <p className="mt-2">{formData.city + ", " + formData.state}</p>
                            )}
              </div>

              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="streetAddress" className="form-label">Street Address</label>
                <input
                  type="text"
                  id="streetAddress"
                  name="streetAddress"
                  placeholder="Enter street address"
                  value={formData.streetAddress}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  className={`form-control ${errors.streetAddress ? "is_invalid" : ""}`}
                />
                {/* {errors.streetAddress && <div className="error-message">{errors.streetAddress}</div>} */}
    
              </div>

              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="birthdate" className="form-label" >Birthdate (Must be 21+ years)</label><span className="star-required text-danger">*</span>
                <input
                  type="date"
                  id="birthdate"
                  name="birthdate"
                  placeholder="MM/DD/YYYY"
                  value={formData.birthdate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  max={minAgeDate}
                  // ref={dateInputRef} 
                  // onClick={handleDateClick}
                  className={`form-control ${errors.birthdate ? "is_invalid" : ""}`}
                />
                {errors.birthdate && <div className="error-message">{errors.birthdate}</div>}
              </div>

              <div className="col-12 mb-1">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="barrelBroker"
                    name="barrelBroker"
                    checked={formData.isBarrelBroker}
                    onChange={handleIsBarrelBroker}
                    style={{ cursor: 'pointer' }}
                  />
                  <label className="form-check-label" htmlFor="barrelBroker">
                    I am a Barrel Broker
                  </label>
                </div>
              </div>

              <div className="col-12 mb-3">
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="termsAccepted"
                    name="termsAccepted"
                    checked={formData.termsAccepted}
                    onChange={handleTermAccepted}
                    style={{ cursor: 'pointer' }}
                    className= {`form-check-input ${errors.termsAccepted ? "is-invalid" : ""}`}
                  />
                  <label className="form-check-label" htmlFor="termsAccepted">
                    I agree to{" "}
                    <a href="#" target="_blank" rel="noopener noreferrer">
                      Terms & Conditions
                    </a>{" "}
                    and{" "}
                    <a href="#" target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </a>
                  </label>
                </div>
                {errors.termsAccepted && <div className="error-message">{errors.termsAccepted}</div>}
              </div>

              <div className="col-auto ps-1">
                <button type="submit" className="btn primary-btn register-btn mb-2" disabled={isLoading}>
                  {isLoading && <Spinner />}Register
                </button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
