import React, { useEffect, useState } from "react";
import { TagsInput } from "react-tag-input-component";
import { Service } from "../../Service/ApiService";
import { CommonService } from "../../Service/CommonService";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from 'react-select';
import Spinner from "../Common/Spinner";
import { useForm } from "react-hook-form";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import "../popups/CommonPopup.scss";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Tooltip } from "@mui/material";
import { Form } from "react-bootstrap";
interface ChildProps {
  reloadTable: any;
}

// CSS for custom scrollbar
const styles = `
  .email-warning-list {
    width: 100%;
    max-width: 100%;
    background-color: #fff; /* Matches your background */
    height: 125px;
    overflow-y: auto;
  }

  .email-warning-list::-webkit-scrollbar {
    width: 4px; /* width of the scrollbar */
  }

  .email-warning-list::-webkit-scrollbar-thumb {
    background: #496673;
    border-radius: 10px;
  }

  .email-warning-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
`;

const InviteUser: React.FC<ChildProps> = ({ reloadTable }) => {
  const [show, setShow] = useState(false);
  const [emailList, setEmailList] = useState<string[]>([]);
  const [unSendEmailList, setUnSendEmailList] = useState<any[]>([]);
  const [isInvited, setIsInvited] = useState(false);
  const [invalidTags, setInvalidTags] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [CompaniesList, setCompaniesList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  // Inject the custom scrollbar styles into the DOM
  React.useEffect(() => {
    const styleTag = document.createElement("style");
    styleTag.textContent = styles;
    document.head.appendChild(styleTag);
    return () => {
      document.head.removeChild(styleTag);
    };
  }, []);

  useEffect(() => {
      fetchExistingCompanies();
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();

  const handleClose = () => {
    console.log("this is running");
    setEmailList([]);
    setSelectedCompany("");
    setIsInvited(false);
    setInvalidTags([]);
    setShow(false);
    clearErrors("emailList");
    clearErrors("selectedCompany");
    setUnSendEmailList([]);
  };

  const handleShow = () => {
    clearErrors("emailList");
    clearErrors("selectedCompany");
    setShow(true);
  };

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleAddEmails = (emails: string[]) => {
    console.log("inside the handle add emails");
    setEmailList(emails);
    const invalidEmails = emails.filter((email) => !validateEmail(email));
    setInvalidTags(invalidEmails);

    if (invalidEmails.length > 0) {
      setError("emailList", {
        type: "manual",
        message: "Please enter valid email addresses",
      });
    } else {
      clearErrors("emailList");
    }
  };

  const beforeAddValidate = (tag: string) => {
    console.log("inside the before add validate");
    const isValidEmail = validateEmail(tag);
    if (!isValidEmail) {
      setInvalidTags((prev) => [...prev, tag]);
      setError("emailList", {
        type: "manual",
        message: "Please enter valid email addresses",
      });
    } else {
      clearErrors("emailList");
    }
    return isValidEmail;
  };

  const tagClassName = (tag: string) => {
    return invalidTags.includes(tag) ? "invalid-tag" : "";
  };

  const sendInvite = async () => {
    let isValid = true;

    if (emailList.length === 0) {
      setError("emailList", {
        type: "manual",
        message: "Please enter at least one email address",
      });
      //return;
      isValid = false;
    } 
    
    if(selectedCompany === ""){
      setError("selectedCompany", {
        type: "manual",
        message: "Please select company name",
      });
      //return;
      isValid = false;
    } 
    
    if (!isValid) {
      return;
    } else {
      setUnSendEmailList([]);
      setIsLoading(true);
      try {
        // Use a default UUID for "New Company"; otherwise return actual UUID for other companies 
        const companyId = selectedCompany == "New Company" ? "00000000-0000-0000-0000-000000000000" : selectedCompany;
        const response: any = await Service.sendInvite(emailList, companyId, false);

        setIsLoading(false);
        setEmailList([]);
        if (response.data?.length == 0) setIsInvited(true);
        else setUnSendEmailList(response.data);

        reloadTable();
      } catch (error: any) {
        setIsLoading(false);
        CommonService.Toast.fire({
          title: error.response?.data?.error || "An error occurred",
          icon: "error",
        });
      }
    }
  };

  const removeEmailByIndex = (indexToRemove) => {
    setUnSendEmailList(
      unSendEmailList.filter((_, index) => index !== indexToRemove)
    );
  };

  // const fetchExistingCompanies = async () => {
  //   try {
  //     const response = await Service.fetchCompaniesList();
  //     setCompaniesList(response.data);
  //   } catch (error) {
  //     console.error("Error fetching companies:", error);
  //   }
  // };

  const fetchExistingCompanies = async () => {
    try {
      const response = await Service.fetchCompaniesList();
      
      // Create company options from API response
      const companiesFromAPI = response.data;
      
      // Set companies list with the "New Company" option added at the beginning
      setCompaniesList([
        {
          id: "New Company",
          companyName: "New Company (Add a new company)"
        },
        ...companiesFromAPI
      ]);
      
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  // const handleCompanySelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const selectedCompanyId = event.target.value;

  //   if (selectedCompanyId) {
  //     setSelectedCompany(selectedCompanyId);
  //     clearErrors("selectedCompany");
  //   } else {
  //     setSelectedCompany("");
  //   }
  // };

  const handleCompanySelect = (selectedCompanyId:any) => {
    // const selectedCompanyId = event.target.value;

    if (selectedCompanyId) {
      setSelectedCompany(selectedCompanyId);
      clearErrors("selectedCompany");
    } else {
      setSelectedCompany("");
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const email = event.target.value.trim();
    if (email) {
      const updatedEmails = [...emailList, email];
      handleAddEmails(updatedEmails);
      event.target.value = "";
    }
  };

  const companyOptions = CompaniesList.map((company: any) => ({
    value: company.id,
    label: company.companyName,
  }));

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontStyle: state.data.value === "New Company" ? 'italic' : 'normal',
      fontWeight: state.data.value === "New Company" ? 'bold' : 'normal',
    })
  };

  return (
    <>
      {/* <button className="white-outline-btn me-1" onClick={handleShow}>
        Invite User
      </button> */}
      <button className="primary-btn d-inline-flex me-1" onClick={handleShow}>
        <EmailOutlinedIcon sx={{ mr: 1 }} />
        Invite User
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        className="modal__wd modal__wd--submitSellReq user--modal__inviteUser"
      >
        <Modal.Header className="flex-column align-items-start">
          <Modal.Title>Invite user</Modal.Title>
          {!isInvited && (
            <p className="modal-text text-start">
              To add new users, enter their email addresses and press Enter
              after each email.
            </p>
          )}
          {isInvited && (
            <p className="modal-text text-start">
              Well done. New users have successfully been invited.
            </p>
          )}
        </Modal.Header>
        {!isInvited && (
          <form onSubmit={handleSubmit(sendInvite)}>
            <Modal.Body>
              <div className="modal-body__tags-input form__inner">
                  <h2 className="modal-body__title text-start">
                    Select Company <span className="star-required text-danger">*</span>
                  </h2>
                  {/* <Form.Select
                      className={`form-control d-flex justify-content-between text-start border border-light-subtle bg-white w-100 ${
                        errors.companySelected ? "is_invalid" : ""
                      }`}
                    id="selectedCompany"
                    value={selectedCompany}
                    onChange={handleCompanySelect}
                  >
                    <option value="">Select Company</option>
                    <option value="New Company"  style={{ fontStyle: 'italic', fontWeight: 'bold' }}>New Company (Add a new company)</option>
                    {CompaniesList.map((company) => (
                      <option key={company.id} value={company.id}>
                        {company.companyName}
                      </option>
                    ))}
                  </Form.Select> */}

<Select
      inputId="selectedCompany"
      className={`select-company-dropdown w-100 multi-select-dropdown ${
        errors.companySelected ? "is_invalid" : ""
      }`}
      classNamePrefix="company-select"
      options={companyOptions}
      value={companyOptions.find(option => option.value === selectedCompany)}
      onChange={(selectedOption) => handleCompanySelect(selectedOption.value)}
      components={{
        DropdownIndicator: () => <ExpandMoreRoundedIcon />,
        IndicatorSeparator: () => null, // Remove the default separator
      }}
      styles={customStyles}
      isSearchable
      placeholder="Select Company"
    />
                          
                  {errors.selectedCompany && (
                    <p className="error-message">
                      {errors.selectedCompany.message as string}
                    </p>
                  )}
              </div>

              <h2 className="modal-body__title text-start  mt-3">
                New users <span className="star-required text-danger">*</span>
              </h2>
              <div className="modal-body__tags-input form__inner">
                <TagsInput
                  value={emailList}
                  onChange={handleAddEmails}
                  beforeAddValidate={beforeAddValidate}
                  onBlur={(event) => handleBlur(event)}
                  // classNames={{ tag: tagClassName }}
                  // classNames={{tag: "form-control"}}
                  name="emails"
                  placeHolder="Enter emails"
                />
                {errors.emailList && (
                  <p className="error-message">
                    {errors.emailList.message as string}
                  </p>
                )}
              </div>

              {unSendEmailList && unSendEmailList.length > 0 && (
                <List className="email-warning-list">
                  {unSendEmailList.map((user, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        alignItems="flex-start"
                        sx={{ display: "flex", paddingInline: "0px" }}
                      >
                        <ListItemAvatar
                          sx={{
                            minWidth: "unset",
                            marginTop: 0,
                            marginRight: "2px",
                          }}
                        >
                          <WarningRoundedIcon sx={{ color: "#b10e1c" }} />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{
                            margin: 0,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          primary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                sx={{
                                  color: "#b10e1c",
                                  display: "inline",
                                  fontWeight: 600,
                                  wordBreak: "break-all",
                                }}
                              >
                                {user.email}
                              </Typography>
                              <Typography
                                component="span"
                                variant="body2"
                                sx={{
                                  color: "#b10e1c",
                                  display: "inline",
                                  fontWeight: 400,
                                  marginLeft: "4px",
                                }}
                              >
                                {user.isActive ? "this email already exists" : "this user is already in trash .. please restore "}.
                              </Typography>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Tooltip title="Remove" arrow>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  sx={{
                                    color: '#496673',
                                    display: 'inline',
                                    fontWeight: 400,
                                    marginLeft: '4px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => removeEmailByIndex(index)} // Remove by index on click
                                >
                                  <CloseRoundedIcon />
                                </Typography>
                              </Tooltip>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider
                        variant="inset"
                        component="li"
                        sx={{ marginLeft: 0 }}
                      />
                    </React.Fragment>
                  ))}
                </List>
              )}
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <div className="btn-group row d-flex justify-content-between w-100">
                <div className="col-6 ps-0">
                  <button
                    className="secondary-btn w-100"
                    onClick={handleClose}
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-6 pe-0">
                  <button
                    type="submit"
                    className="primary-btn w-100"
                    disabled={isLoading}
                  >
                    {isLoading && <Spinner />} Invite
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </form>
        )}
        {isInvited && (
          <Modal.Footer className="justify-content-center bg-transparent">
            <div className="btn-group row d-flex justify-content-center w-100">
              <div className="col-12">
                <button className="secondary-btn w-100" onClick={handleClose}>
                  Close
                </button>
              </div>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default InviteUser;
