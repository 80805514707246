import React, { useRef, useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import "../Dynamic/BarrelSelectionPanel.scss";
import SearchIcon from "@mui/icons-material/Search";
import DoneIcon from "@mui/icons-material/Done";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { Tab, Nav, Accordion } from 'react-bootstrap';
import FAQList from '../popups/FAQList';
import { Typography } from '@mui/material';
import initialFaqData from '../../assets/F&Q/UpdatedFAQList.json';

interface SupportFaqPanelProps {
    handleCloseFaq: () => void;
}
interface FAQItem {
    id: any;
    question: string;
    answer: string;
  }

interface FAQSection {
    section: string;
    items: FAQItem[];
  }
// function BarrelSelectionPanel({ handleCloseFaq })
const BarrelSelectionPanel: React.FC<SupportFaqPanelProps> = ({ handleCloseFaq }) => {
    const [faqData, setFaqData] = useState<FAQSection[]>(initialFaqData.FAQList);

    return (
        <>
            <div className="faq-offcanvas-wrapper">
                <div className='faq-panel-left' onClick={handleCloseFaq}></div>
                <div className='faq-panel-inner' >
                    <div className='header'>
                        <div className='header-inner d-flex justify-content-between align-items-start'>
                            <div>
                                <p className='main-heading mb-0'>FAQ</p>
                                <p className='contact-us-text mt-2 mb-0'>Contact us at <a href="mailto:support@whitedogtradingpost.com" >support@whitedogtradingpost.com</a>, if you have any questions.</p>
                            </div>
                            <div>
                                
                            <button onClick={handleCloseFaq} className='close-btn'><CloseIcon /></button>
                            </div>
                        </div>
                    </div>
                    <div className='faq-panel-body'>
                    {/* <FAQList/> */}
                        <div>
                            <div className='upload_csv--user__faq'>
                                <Accordion>
                                    {faqData.map((section) => (
                                        <div key={section.section}>
                                            {/* <Typography variant="h5" sx={{ marginTop: '20px' }}>{section.section}</Typography> */}
                                            <p className='section-heading'>{section.section}</p>
                                            {section.items.map((item) => (
                                                <Accordion.Item eventKey={item.id.toString()} key={item.id}>
                                                    <Accordion.Header>{item.question}</Accordion.Header>
                                                    <Accordion.Body>
                                                        {item.answer}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ))}
                                        </div>
                                    ))}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BarrelSelectionPanel
