import React, { Fragment, useEffect, useRef, useState } from 'react';
import { CurrentRefinements, Hits, InstantSearch, Pagination, RangeInput, RefinementList, SearchBox, SortBy, Configure, ToggleRefinement } from 'react-instantsearch';
import { createTypesenseAdapter, searchClient } from "../../typesenseAdapter.js";
import { ClearFilters } from '../Widgets/ClearFilters';
import '../../App.css'
import '../Dynamic/Typesense.scss';
import "../../sass/responsive.scss";
import { FacetDropdown } from '../Widgets/FacetDropdown';
import Cookies from 'universal-cookie';
import { useHistory, useLocation } from 'react-router';
import CurrencyFormatter from '../Static/CurrencyFormatter';
import { PriceRangeSlider } from './PriceRangeSlider.js';
import { AgeRangeSlider } from './AgeRangeSlider';
import '../Dynamic/Typesense.css';
import { Button, ButtonGroup, Spinner } from 'react-bootstrap';
import WhiskeyBarrel from '../../assets/images/products/WhiskyBarrel.png'
import OfferRequest from '../popups/OfferRequest';
import GridViewIcon from '@mui/icons-material/GridView';
import ListSharpIcon from '@mui/icons-material/ListSharp';
import { Container, Tooltip, Typography } from '@mui/material';
import Select from 'react-select';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { OrderApiService } from '../../Service/OrderApiService';
import SampleOrder from '../popups/SampleOrder';
import CloseIcon from "@mui/icons-material/Close";
import { CommonService } from '../../Service/CommonService';
import { RangeSlider } from '@adobe/react-spectrum';
import SearchContainer from './SearchContainer';
import HitsContainer from './HitsContainer';
import HitsTableContainer from './HitsTableContainer';

// import { useClearRefinements } from 'react-instantsearch';

const Typesense: React.FC = () => {
    const closeOnChange = () => window.innerWidth > 375;
    const location = useLocation();
    const currentPath = location.pathname;
    const [gridView, setView] = useState<boolean>(true);

    const [soldBarrels, setSoldBarrels] = useState([]);

    const [wishlistItems, setWishlistItems] = useState<any[]>([]);
    const userId = new Cookies().get('userId');
    const companyId = new Cookies().get('companyId');
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const collectionName = process.env.REACT_APP_TYPESENSE_COLLECTIONNAME; //Typesense collection name
    // const { refine, canRefine } = useClearRefinements();
    const getUserWishlist = async () => {
        try {
            const response: any = await OrderApiService.getUserWishlist(userId);
            setWishlistItems(response.data);
        } catch (error) {
            console.error('Error while fetching wishlist:', error);
        }
    };
    

    useEffect(() => {
        getUserWishlist();
    }, []);

    useEffect(() => {

        let filters = userId;
        if (currentPath === '/user/inventories') {
            filters = userId ? [`company_id:!=${companyId}`] : ['company_id'];
        } else if (currentPath === '/user/my-inventory') {
            filters = userId ? [`company_id:=${companyId}`] : ['company_id'];
        } else if (currentPath === '/user/wish-list') {
            filters = wishlistItems.length > 0 ? [`owner_id:!=${userId}`, `(inventory_id:${wishlistItems.join(' || inventory_id:')})`] : [`owner_id:!=${userId}  &&  inventory_id:null`];
            getAllSoldInventoryWishList();
        }

        // Generate dynamic filter string
        const filterValue = Array.isArray(filters) ? filters?.join(' && ').replace(/\\/g, '') : filters;
        setGlobalFilter(filterValue);

    }, [location.pathname, wishlistItems]);

    const switchView: any = (view: any) => {
        if (view == 'grid')
            setView(true);
        else
            setView(false);
    };

    const getAllSoldInventoryWishList = async() => {
        try {
            const response = await OrderApiService.getAllSoldInventoryWishList(CommonService.getUserId());
            setSoldBarrels(response.data);
        } catch (error) {
            console.error('Error while fetching barrel availability:', error);
            return false; // Return false in case of an error
        }
    };

    const removeFromWishList = async (itemId: string) => {
        const wishListRequest = {
            inventoryId: itemId,
            userId: userId,
        };

        try {
            const response: any = await OrderApiService.removeFromWishlist(wishListRequest);
            getUserWishlist();
        } catch (error) {
            console.error('Error while removing wishlist data:', error);
        }
    };

    const removeFromWishListById = async (wishListId: any) => {
        
        try {
            const response: any = await OrderApiService.removeFromWishlistById(wishListId);
            getAllSoldInventoryWishList();
        } catch (error) {
            console.error('Error while removing wishlist data:', error);
        }
    };

    // Transform refinement items using calculateAge
    function transformRefinementItems(items: any) {
      return items.map((item: any) => {

        // Remove underscores from the attribute name
        const attributeName = item.attribute.replace(/_/g, ' ');
        item.label = attributeName; // Directly modify the item
    
        if (attributeName === 'fill date timestamp') {
          return {
            ...item,
            refinements: item.refinements.map((refinement: any) => {
              // Match relational operators and numeric timestamp
              const match = refinement.label.match(/([<>=]*\s*)(\d+)/);
              if (match && match.length >= 3) {
                const prefix = refinement.operator; // Relational operator (e.g., <, >, <=, >=, ==)
                const timestamp = parseInt(match[2], 10); // Numeric timestamp value
                const { years, months } = CommonService.calculateAge(new Date(timestamp).toISOString());
    
                // Create labels for years and months
                const yearLabel = years > 1 ? `${years} years` : `${years} year`;
                const monthLabel = months > 1 ? `${months} months` : `${months} month`;
    
                // Preserve the operator and display the transformed label
                return {
                  ...refinement,
                  label: `${prefix}${yearLabel}, ${monthLabel}`
                };
              }
              return refinement; // Return unchanged if no match
            })
          };
        }

        return item; // ✅ Return item directly, not { item }
      });
    }
    
    const headerRef = useRef<HTMLDivElement>(null);
    const bodyRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const bodyTable = bodyRef.current;
        const headerTable = headerRef.current;

        const handleScroll = () => {
            if (headerTable && bodyTable) {
                headerTable.scrollLeft = bodyTable.scrollLeft;
            }
        };

        if (bodyTable) {
            bodyTable.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (bodyTable) {
                bodyTable.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    return (
        <div className="Typesense__container">

            {/* <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '350px' }}>
                <Spinner animation="border" variant="secondary" className="mt-3" />
                <Typography variant="subtitle1" sx={{ fontSize: '20px', fontWeight: 600, color: '#737376', marginTop: '12px' }}>
                    Product details is loading...
                </Typography>
            </Container> */}
            <InstantSearch searchClient={searchClient} indexName={collectionName}>
                <Configure filters={globalFilter} /> {/* Use the state variable directly here */}
                <div className="container">
                    
                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-lg-0">
                            <div className="Typesense__filters-wrapper d-flex flex-wrap justify-content-center align-items-center pt-4 pb-5 w-100">
                                <div className="filters--type d-flex flex-column">
                                    <div className="filter px-3 py-2 p-md-3">
                                        <SearchBox placeholder="Search for inventories... " />
                                    </div>
                                    {/* {<ClearFilters /> } */}
                                    <div className='d-flex justify-content-end px-3'>
                                    {/* <button className='clear-filter-btn'> <CloseIcon /> <span>Clear All</span></button> */}
                                    </div>
                                    <div className="filter filter--matches px-3 py-2 p-md-3">
                                        <div className="filter--title mb-2 d-flex align-items-center justify-content-between">
                                            <span>Matches</span>
                                            {<ClearFilters /> }
                                        </div>
                                        <CurrentRefinements transformItems={transformRefinementItems} />
                                    </div>

                                    <div className="filter filter--border filter--proof px-3 py-md-3 py-2">
                                        <div className="filter--title mb-2">
                                            Proof (proof)
                                        </div>
                                        <div className="spiritAge-range">
                                            <RangeInput attribute="proof" />
                                        </div>
                                    </div>
                                    <div className="filter filter--border filter--price py-md-3 px-3 py-2 pb-3">
                                        <div className="filter--title mb-2">
                                            Price ($)
                                        </div>
                                        <div className="">
                                            <PriceRangeSlider attribute="price" />
                                        </div>
                                    </div>

                                    <div className="filter filter--border filter--price py-md-3 px-3 py-2 pb-3">
                                        <div className="filter--title mb-2">
                                            Age
                                        </div>

                                        <div className="">
                                            <AgeRangeSlider attribute="fill_date_timestamp" />
                                        </div>
                                    </div>

                                    <div className="filter filter--border filter--price py-md-3 px-3 py-2">
                                        <div className="filter--title mb-2">
                                            Producers
                                        </div>
                                        <div className="">
                                            <RefinementList attribute="producer" searchablePlaceholder="Search Producer" />
                                        </div>
                                    </div>
                                    {/* </FacetDropdown> */}

                                    {/* <FacetDropdown
                                closeOnChange={closeOnChange}
                                buttonText={({ refinements }) => {
                                    const label = refinements[0]?.label.split(' > ').pop();
                                    return label ? `Origin (${label})` : `Origin`;
                                }}
                                classNames={{ root: 'my-BrandDropdown' }}> */}
                                    <div className="filter filter--border filter--price py-md-3 px-3 py-2">
                                        <div className="filter--title mb-2">
                                            Facilities
                                        </div>
                                        <div className="">
                                            <RefinementList attribute="facility" searchablePlaceholder="Search Facility" />
                                        </div>
                                    </div>
                                    {/* </FacetDropdown> */}
                                    <div className="filter filter--border filter--price py-md-3 px-3 py-2">
                                        <div className="filter--title mb-2">
                                            Mash bill
                                        </div>
                                        <div className="">
                                            <RefinementList
                                                attribute="mash_bill"
                                                searchablePlaceholder="Search Mashbill"
                                            />
                                        </div>
                                    </div>
                                    {/* <FacetDropdown
                                buttonText={({ refinements }) => {
                                    // const label = refinements[0]?.label.split(' > ').pop();
                                    const count = refinements.length;
                                    return count ? `Liquor (${count})` : `Liquor`;
                                }}
                                closeOnChange={closeOnChange}
                                classNames={{ root: 'my-BrandDropdown' }}> */}
                                    <div className="filter filter--border filter--price py-md-3 px-3 py-2">
                                        <div className="filter--title mb-2">
                                            TTB type
                                        </div>
                                        <div className="">
                                            <RefinementList attribute="ttb_type" searchablePlaceholder="Search TTB Type" />
                                        </div>
                                    </div>
                                    <div className="filter filter--border filter--price py-md-3 px-3 py-2">
                                        <div className="filter--title mb-2">
                                            State
                                        </div>
                                        <div className="">
                                            <RefinementList attribute="state" searchablePlaceholder="Search TTB Type" />
                                        </div>
                                    </div>
                                    <div className="filter filter--border filter--price py-md-3 px-3 py-2">
                                        {/* <div className="filter--title mb-2">
                                            Is Relocation Required?
                                        </div> */}
                                        <div className="cursor-pointer">
                                            <RefinementList attribute="is_relocation_required" transformItems={items =>
                                                items.map(item => ({
                                                    ...item,
                                                    label: item.label === 'true' ? 'Relocation required' : item.label === 'false' ? 'Relocation not required' : item.label
                                                }))
                                            } />
                                        </div>
                                    </div>

                                    <div className="filter filter--border filter--price py-md-3 px-3 py-2">
                                        {/* <div className="filter--title mb-2">
                                            Is Available for Offer?
                                        </div> */}
                                        <div className="cursor-pointer">
                                            <RefinementList attribute="is_make_an_offer" transformItems={items =>
                                                items.map(item => ({
                                                    ...item,
                                                    label: item.label === 'true' ? 'Available for offer' : item.label === 'false' ? 'Not available for offer' : item.label
                                                }))
                                            } />
                                        </div>
                                    </div>

                                    <div className="filter filter--border filter--price py-md-3 px-3 py-2">
                                        {/* <div className="filter--title mb-2">
                                            Is Available for Offer?
                                        </div> */}
                                        <div className="cursor-pointer">
                                            <RefinementList attribute="is_account_transfer" transformItems={items =>
                                                items.map(item => ({
                                                    ...item,
                                                    label: item.label === 'true' ? 'Account transfer required' : item.label === 'false' ? 'Account transfer not required' : item.label
                                                }))
                                            } />
                                        </div>
                                    </div>

                                    <div className="filter filter--border filter--price py-md-3 px-3 py-2">
                                        {/* <div className="filter--title mb-2">
                                            Is Available for Offer?
                                        </div> */}
                                        <div className="cursor-pointer">
                                            <RefinementList attribute="is_third_party_storage" transformItems={items =>
                                                items.map(item => ({
                                                    ...item,
                                                    label: item.label === 'true' ? 'Stored at third party storage' : item.label === 'false' ? 'Not stored at third party storage' : item.label
                                                }))
                                            } />
                                        </div>
                                    </div>

                                    {/* </FacetDropdown> */}

                                    
                                </div>

                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-9">
                            <div className="row d-flex justify-content-between align-items-center align-items-sm-end">
                                <div className="col-auto mb-2">
                                    <h3 className='table__heading'>Available Barrels</h3>
                                </div>
                                <div className="col-auto mb-2 ms-auto">
                                    <div className="view__type--select">
                                        <ButtonGroup>
                                            <Button variant="link"
                                                aria-label="View as" disabled
                                                className='d-none d-md-block'
                                            >
                                                View As
                                            </Button>
                                            <Button aria-label="Grid" className='ms-1 ms-sm-0'
                                                onClick={() => {
                                                    switchView("grid");
                                                }} style={{ backgroundColor: gridView === true ? '#0C3344' : '#D7D7D7' }} >
                                                <GridViewIcon style={{ color: gridView === true ? 'white' : 'inherit' }} />
                                            </Button>
                                            <Button
                                                aria-label="Table"
                                                onClick={() => {
                                                    switchView("table");
                                                }}
                                                style={{ backgroundColor: gridView === false ? '#0C3344' : '#D7D7D7' }} >
                                                <ListSharpIcon style={{ color: gridView === false ? 'white' : 'inherit' }} />
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            </div>
                            <hr className='my-1' />
                            <div className="inventory__table--container">
                            <SearchContainer children={''}/>
                                {!gridView && (
                                    <><HitsTableContainer/></>
                                )}
                                {/* {!gridView && (
                                )} */}
                                {gridView && (
                                    <><HitsContainer/></>
    )}
    {currentPath === '/user/wish-list' && (<SoldItem soldBarrelDetails={soldBarrels} gridView={gridView} removeFromWishList={removeFromWishListById} />)}
    {/* <SoldItem soldBarrelDetails={soldBarrels} /> */}

                                <Pagination />
                            </div>
                        </div>
                    </div>
                </div>

            </InstantSearch>
        </div>
    );
}


const SoldItem: React.FC<{ soldBarrelDetails: any[], gridView: boolean,removeFromWishList: (wishListId: any) => void }> = ({ soldBarrelDetails, gridView , removeFromWishList}) => {
    



    return (
      <div className='mt-2'>
       {soldBarrelDetails.length > 0 && (<> <h2 className='table__heading'>Sold Barrels</h2>
        
          
           {gridView && (<div className="ais-Hits">
              <div className="ais-Hits-list">
                {soldBarrelDetails.map((barrel, index) => (
                  <div className="ais-Hits-item" key={index}>
                    <div className="grid-view inventory__grid-view">
                      <div className="grid-item">
                        <div className="card bg-transparent">
                          <img
                            src={WhiskeyBarrel}
                            className="card-img-top"
                            alt="Barrel"
                          />
                          <div className="card-body">
                            <div className="card-title">
                                <div className='d-flex justify-content-between align-items-start'>
                              <h5>
                                {barrel.inventoryTitle}
                              </h5>
                              </div>
                            </div>
                            
                            <div className='d-flex justify-content-center position-relative'>
                            <span onClick={() => removeFromWishList(barrel?.id)} className='position-absolute top-0' style={{right:10}}>
                              <Tooltip title="Remove from Wishlist" arrow>
                                <FavoriteIcon sx={{ cursor: "pointer", color: "red" }}  />
                              </Tooltip>
                              </span>
                                  <div className="sold-out-label mb-3">Sold Out</div>
                                  
                                </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>)}
  
            {/* Table Section */}
            {!gridView && (<div className="table-container my-3">
              <div className="table--header">
                <table className="table table-striped mb-0">
                  <thead className="thead-dark">
                    <tr>
                      <th>
                        <div className="d-flex justify-content-between">
                          <span className="thead__heading">Spirit</span>
                          <SortBy
                            items={[
                              { label: "asc", value: "inventory/sort/ttb_type:asc" },
                              { label: "desc", value: "inventory/sort/ttb_type:desc" },
                            ]}
                            classNames={{
                              root: "MyCustomSortBy",
                              select: "MyCustomSortBySelect MyCustomSortBySelect--subclass",
                            }}
                          />
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between">
                          <span className="thead__heading text-nowrap">Price $</span>
                          <SortBy
                            items={[
                              { label: "asc", value: "inventory/sort/price:asc" },
                              { label: "desc", value: "inventory/sort/price:desc" },
                            ]}
                          />
                        </div>
                      </th>
                      
                    </tr>
                  </thead>
                </table>
              </div>
  
              <div className="table--body">
                <table className="table table-striped table-bordered mb-0">
                  <tbody>
                  <div className='ais-Hits'>
                  <div className='ais-Hits-list'>
                    {soldBarrelDetails.map((record, index) => (
                        <div className='ais-Hits-item' key={index}>
                              <table className="table inventory__table--data table-hover align-middle mb-0">
                              <tbody className="thead-dark">
                      <tr key={index} className="border-bottom border-0 border-secondary-subtle">
                        <td style={{maxWidth:"175px"}}>
                          <div className="d-flex flex-column">
                            <span>{record?.inventoryTitle}</span>
                            {/* <span className="text-body-tertiary text-nowrap" style={{textOverflow:"ellipsis", overflowX:"hidden"}}>{record.inventoryTitle}</span> */}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex flex-column">
                          <div className="sold-out-label">Sold Out</div>
                            {/* <span className="text-body-tertiary">Per Barrel</span> */}
                          </div>
                        </td>
                        {/* <td>
                          <div className="d-flex flex-column">
                            <span>{record.spiritAge}</span>
                          </div>
                        </td> */}
                        {/* <td>
                          <div className="d-flex flex-column">
                            <span>{record.proof}</span>
                            <span className="text-body-tertiary">Proof</span>
                          </div>
                        </td> */}
                        <td>
                          <div className="d-flex justify-content-end">
                            <div className="manageWishList me-2" onClick={() => removeFromWishList(record?.id)}>
                              <Tooltip title="Remove from Wishlist" arrow>
                                <FavoriteIcon sx={{ cursor: "pointer", color: "red" }} />
                              </Tooltip>
                              {/* <Tooltip title="Add to Wishlist" arrow>
                                <FavoriteBorderIcon sx={{ cursor: "pointer", color: "#D9D9D9" }} />
                              </Tooltip> */}
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                      </table>
                      </div>
                    ))}
                    </div>
                    </div>
                  </tbody>
                </table>
              </div>
            </div>)}
          </>
        )}
      </div>
    );
  };
  
export default Typesense;