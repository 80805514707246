import React from 'react';
import SearchIcon from "@mui/icons-material/Search";
import "../Dynamic/AllNotification.scss";

function AllNotification() {
    return (
        <>
            <div className='notification-wrapper'>
                <div className='container'>
                    <div className='notification-header'>
                        <h3 className='heading'>Notification</h3>
                        <div className="search-input-wrapper position-relative">
                            {/* Search Input */}
                            <SearchIcon
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "15px",
                                    transform: "translateY(-50%)",
                                }}
                            />
                            <input
                                type="text"
                                placeholder="Search"
                                className="form-control flex-grow"
                            />
                        </div>
                    </div>
                    <div className='notification-list-wrapper'>
                        <div className='notification-list-item-wrapper'>
                            <h4 className='notification-item-heading'>
                                Order Request Received.
                            </h4>
                            <p className='notification-msg'>
                                Received order request for Bourn Bourn Whisky by Alex. Received order request for Bourn Bourn Whisky by Alex.
                            </p>
                            <div className='notification-time'>
                                <span>
                                    June 21, 2024
                                </span>
                                <span>|</span>
                                <span>
                                    10:20AM
                                </span>
                            </div>
                        </div>
                        <div className='notification-list-item-wrapper read-notification'>
                            <h4 className='notification-item-heading'>
                                Order Request Received.
                            </h4>
                            <p className='notification-msg'>
                                Received order request for Bourn Bourn Whisky by Alex. Received order request for Bourn Bourn Whisky by Alex.
                            </p>
                            <div className='notification-time'>
                                <span>
                                    June 21, 2024
                                </span>
                                <span>|</span>
                                <span>
                                    10:20AM
                                </span>
                            </div>
                        </div>
                        <div className='text-center my-3'>
                            <button type="submit" className="btn primary-btn">Load More..</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllNotification