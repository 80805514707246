import React, { useState, useEffect } from "react";
import initialFaqData from "../../assets/F&Q/UpdatedFAQList.json";
import { Accordion } from "react-bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min"; // Ensure Bootstrap JS is imported
import EastIcon from '@mui/icons-material/East';
import "../Dynamic/FaqPage.scss";
import FAQHeader from "../Common/FAQHeader";
import Footer from "../Common/Footer";

interface FAQItem {
    id: string | number;
    question: string;
    answer: string;
}

interface FAQSection {
    section: string;
    items: FAQItem[];
}

const FAQ: React.FC = () => {
    const [faqData, setFaqData] = useState<FAQSection[]>(initialFaqData.FAQList);
    const [activeSection, setActiveSection] = useState<string>("");

    useEffect(() => {
        if (faqData.length > 0) {
            const firstSectionId = faqData[0].section.toLowerCase().replace(/\s+/g, "-");
            setActiveSection(firstSectionId);
        }
    }, [faqData]); // Runs once when faqData is available

    useEffect(() => {
        const handleScroll = () => {
            let closestSection = "";
            let closestDistance = window.innerHeight; // Start with max possible distance

            faqData.forEach((section) => {
                const sectionId = section.section.toLowerCase().replace(/\s+/g, "-");
                const sectionElement = document.getElementById(sectionId);

                if (sectionElement) {
                    const rect = sectionElement.getBoundingClientRect();
                    const distanceFromTop = Math.abs(rect.top - 50); // Distance from 50px

                    // Find the section closest to 50px from the top
                    if (rect.top >= 0 && distanceFromTop < closestDistance) {
                        closestSection = sectionId;
                        closestDistance = distanceFromTop;
                    }
                }
            });

            if (closestSection && closestSection !== activeSection) {
                setActiveSection(closestSection);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [faqData, activeSection]);

    const handleSmoothScroll = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        sectionId: string
    ) => {
        event.preventDefault();
        const targetSection = document.getElementById(sectionId);
        if (targetSection) {
            window.scrollTo({
                top: targetSection.offsetTop - 50, // Offset for sticky header
                behavior: "smooth",
            });
        }
    };

    return (
        <>
            <FAQHeader />
            <div className="faq-wrapper">
                <div className="container">
                    <div className="row m-0">
                        {/* Sidebar Navigation */}
                        <div className="col-lg-3 aside-section">
                            <ul className="faq-list position-sticky" id="faq-list">
                                {faqData.map((section) => {
                                    const sectionId = section.section.toLowerCase().replace(/\s+/g, "-");
                                    return (
                                        <li className={`faq-list-item ${activeSection === sectionId ? "active" : ""}`} key={section.section}>
                                            <a
                                                href={`#${section.section.toLowerCase().replace(/\s+/g, "-")}`}
                                                className="faq-list-item-link"
                                                onClick={(e) => handleSmoothScroll(e, sectionId)}
                                            >
                                                <span>{section.section}</span>
                                                <EastIcon />
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>

                        {/* FAQ Content Section */}
                        <div className="col-lg-9 detail-section">
                            <div className="faq-detail-inner" data-bs-spy="scroll" data-bs-target="#faq-list" data-bs-smooth-scroll="true" tabIndex={0}>
                                {faqData.map((section) => (
                                    <div className="faq-section-wrapper" key={section.section} id={section.section.toLowerCase().replace(/\s+/g, "-")}>
                                        <p className="section-heading">{section.section}</p>
                                        <Accordion>
                                            {section.items.map((item) => (
                                                <Accordion.Item eventKey={item.id.toString()} key={item.id}>
                                                    <Accordion.Header>{item.question}</Accordion.Header>
                                                    <Accordion.Body>{item.answer}</Accordion.Body>
                                                </Accordion.Item>
                                            ))}
                                        </Accordion>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default FAQ;
