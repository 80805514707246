import { ReactNode, useEffect, useState } from "react";
import { Configure, Hits, useInstantSearch } from "react-instantsearch";
import { useHistory, useLocation } from "react-router";
import { CommonService } from "../../Service/CommonService";
import WhiskeyBarrel from '../../assets/images/products/WhiskyBarrel.png'
import CurrencyFormatter from "../Static/CurrencyFormatter";
import {  Tooltip } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { OrderApiService } from "../../Service/OrderApiService";
import Cookies from "universal-cookie";

interface SearchContainerProps {
  children?: ReactNode;
}

const HitsContainer: React.FC<SearchContainerProps> = ({ children }) => {
  const { status } = useInstantSearch();
  const userId = new Cookies().get('userId');
  const [wishlistItems, setWishlistItems] = useState<any[]>([]);
  const [soldBarrels, setSoldBarrels] = useState([]);
  const [showHits, setShowHits] = useState(false);
  const collectionName = process.env.REACT_APP_TYPESENSE_COLLECTIONNAME;
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const location = useLocation();
  const currentPath = location.pathname;
  const companyId = new Cookies().get('companyId');
  
  useEffect(() => {
    getUserWishlist();
}, []);

  const addToWishList = async (itemId: string, productName: string) => {
    const wishListRequest = {
        inventoryId: itemId,
        userId: userId,
        inventoryTitle: productName
    };

    try {
        const response: any = await OrderApiService.addToWishlist(wishListRequest);
        getUserWishlist();
    } catch (error) {
        console.error('Error while adding wishlist data:', error);
    }
};

const getUserWishlist = async () => {
  try {
      const response: any = await OrderApiService.getUserWishlist(userId);
      setWishlistItems(response.data);
  } catch (error) {
      console.error('Error while fetching wishlist:', error);
  }
};

const removeFromWishList = async (itemId: string) => {
  const wishListRequest = {
      inventoryId: itemId,
      userId: userId,
  };

  try {
      const response: any = await OrderApiService.removeFromWishlist(wishListRequest);
      getUserWishlist();
  } catch (error) {
      console.error('Error while removing wishlist data:', error);
  }
};

const getAllSoldInventoryWishList = async() => {
    try {
        const response = await OrderApiService.getAllSoldInventoryWishList(CommonService.getUserId());
        setSoldBarrels(response.data);
    } catch (error) {
        console.error('Error while fetching barrel availability:', error);
        return false; // Return false in case of an error
    }
};

  useEffect(() => {

    if(status !== "loading" && status !== "stalled")
      setShowHits(true);
    else
      setShowHits(false);
  
  }, [status]);

  useEffect(() => {

    let filters = userId;
    if (currentPath === '/user/inventories') {
        filters = userId ? [`company_id:!=${companyId}`] : ['company_id'];
    } else if (currentPath === '/user/my-inventory') {
        filters = userId ? [`company_id:=${companyId}`] : ['company_id'];
    } else if (currentPath === '/user/wish-list') {
        filters = wishlistItems.length > 0 ? [`owner_id:!=${userId}`, `(inventory_id:${wishlistItems.join(' || inventory_id:')})`] : [`owner_id:!=${userId}  &&  inventory_id:null`];
        getAllSoldInventoryWishList();
    }

    // Generate dynamic filter string
    const filterValue = Array.isArray(filters) ? filters?.join(' && ').replace(/\\/g, '') : filters;
    setGlobalFilter(filterValue);

}, [location.pathname, wishlistItems]);

  return showHits ? (

    <Hits
      hitComponent={(props) => (
        <GridHitItem
          {...props}
          addToWishList={addToWishList}
          removeFromWishList={removeFromWishList}
          wishlistItems={wishlistItems}
          soldBarrelDetails={soldBarrels}
        />
      )}
      classNames={{
        emptyRoot: "my-empty-class",
      }}
    />
  
  ) : (
    <div></div> // Or a loader if preferred
  );
  
};

const GridHitItem: React.FC<{ hit: any, addToWishList: (itemId: string, productName:string) => void, removeFromWishList: (itemId: string) => void, wishlistItems: any[], soldBarrelDetails:any[] }> = ({ hit, addToWishList, removeFromWishList, wishlistItems,soldBarrelDetails }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const history = useHistory();
  const [isInWishlist, setIsInWishlist] = useState(wishlistItems.includes(hit.inventory_id));
  const { years, months } = CommonService.calculateAge(hit.fill_date_timestamp);

  const handleRowClick = () => {
      // Redirect to another page with the hit id
      history.push(`/user/product-details/${hit.id}`);
  };

  const manageWishList = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      isInWishlist ? removeFromWishList(hit.inventory_id) : addToWishList(hit.inventory_id,hit.product_name);
      setIsInWishlist(prev => !prev);
  };

  useEffect(() => {
      console.log("these are my sold barrels" + soldBarrelDetails);
  }, []);


  

  return (

      <>
          <div className="grid-view inventory__grid-view" onClick={handleRowClick}>
              <div className="grid-item" >
                  <div className="card bg-transparent">
                      <img src={WhiskeyBarrel} className="card-img-top" alt="..." />
                      <div className="card-body">
                          <div className="card-title">
                              <h5>
                                  {hit.product_name}
                                  {/* <span>{hit.mash_bill}</span> */}
                              </h5>
                          </div>
                          <div className="card-text d-flex justify-content-between">
                              <div className='grid-cell'>
                                  <CurrencyFormatter amount={hit.price} locale="en-US" currency="USD" />&nbsp;
                                  <span>/ Barrel</span>
                              </div>
                              {(currentPath === '/user/inventories' || currentPath === '/user/wish-list') && (
                                  <div className='grid-cell' onClick={manageWishList}>
                                      {isInWishlist ? (
                                          <Tooltip title="Remove from Wishlist" arrow>
                                              <FavoriteIcon sx={{ cursor: 'pointer', color: 'red' }} />
                                          </Tooltip>
                                      ) : (
                                          <Tooltip title="Add to Wishlist" arrow>
                                              <FavoriteBorderIcon sx={{ cursor: 'pointer', color: '#D9D9D9' }} />
                                          </Tooltip>
                                      )}
                                  </div>

                              )}
                          </div>
                          <div className='details'>
                              <div className='grid-cell'>
                              <span>{years} years {months} months</span>
                                  {/* <span>{hit.fill_date_timestamp}</span> */}
                                  {/* <span>Years Old</span> */}
                              </div>
                          </div>
                          <div className='details'>
                              <div className='grid-cell'>
                                  <span>{hit.mash_bill}</span>
                              </div>
                          </div>
                          <div className='details'>
                              <div className='grid-cell'>
                                  <span>{hit.proof} {hit.proof_unit.toLowerCase()
                                      .split(' ')
                                      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
                                      .join(' ')}</span>
                              </div>
                          </div>
                        
                      </div>
                  </div>

              </div>
          </div>


      </>
  );
}

export default HitsContainer;
