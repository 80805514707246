import { ReactNode, useEffect, useRef, useState } from "react";
import { CurrentRefinements, Hits, Pagination, RangeInput, RefinementList, SearchBox, SortBy, useInstantSearch } from "react-instantsearch";


interface SearchContainerProps {
  children: ReactNode;
}

const SearchContainer: React.FC<SearchContainerProps> = ({ children }) => {

    const { results, status } = useInstantSearch();

    // useEffect(() => {
    //     console.log("------------loading---------------")
    //     console.log(status)
    //     if(status === "loading"){

    //     }
    // }, [status]);

    return (
        <div className={status === "loading" || status === "stalled" ? "loading-root" : ""}>
        
      </div>
      );
      
}

export default SearchContainer;