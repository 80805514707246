import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { CommonService } from "../../Service/CommonService";
import { CookieService } from "../../Service/CookieService";
import { InventoryApiService } from "../../Service/InventoryApiService";
import "../Dynamic/InventoryCSV.scss";

const InventoryListing: React.FC = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const [isLoading, setIsLoading] = useState(false);
    // Define the localeText object based on the isLoading flag
    const localeText = {
        noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
    };
    const history = useHistory();
    const [search, setSearch] = useState("");
    const userRole = CookieService.getCookie("userRole");
    const { id } = useParams<{ id: string }>();
    const params =
        id === undefined && CommonService.isUser()
            ? CommonService.getCompanyId()
            : id;
    const [tableData, setTableData] = useState<any[]>([]);
    const [inventoryCsvWithDocs, setInventoryCsvWithDocs] = useState<any>(null);
    const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
    const [service, setService] = useState<string>("");
    const [totalCount, setTotalCount] = useState(0);
    const controllerRef = useRef<AbortController | null>(null);
    const [activeButton, setActiveButton] = useState<string>("listed");
    const activeButtonRef = useRef(activeButton);
    const [sortField, setSortField] = useState("title"); // Default sort
    const [sortDirection, setSortDirection] = useState("asc"); // Default ascending
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    //const [paginationModel, setPaginationModel] = useState({
        //page: 0,
        //pageSize: 10,
    //});
    const initialFilter = {
        title: null,
        ownerName: null,
        price: null,
        fee: null,
        availableBarrels: null,
        reservedBarrels: null,
        soldBarrels: null,
        fillDate: null,
        facility: null,
        lotNo: null
    };
    const [filter, setFilter] = useState<any>(initialFilter);

    useEffect(() => {
        fetchInventoryListingData(
            page,
            pageSize,
            filter,
            sortField, sortDirection
        );
    }, []);

    const fetchInventoryListingData = async (page: any, size: any, filter: any, sortField: any, sortDirection: any) => {
        const controller = new AbortController();
        const signal = controller.signal;

        try {
            setIsLoading(true);
            setTableData([]);
            if(activeButtonRef.current?.toLowerCase() === "listed".toLowerCase()) {
                const responseListed: any = await InventoryApiService.getActiveInventoryListings(params, page, size, filter, sortField, sortDirection, signal);
            
                setTableData(Object.values(responseListed.data.content));
                setTotalCount(responseListed.data.totalElements);
                setIsLoading(false);
            } else {
                const responseSold: any = await InventoryApiService.getActiveSoldInventoryListings(params, page, size, filter, sortField, sortDirection, signal);
            
                const rowsWithIds = responseSold.data.content.map((item: any, index: number) => ({
                    ...item,
                    id: item.id || index // Use existing id if available, otherwise fallback to index
                }));

                setTableData(rowsWithIds); // Set the table data with unique ids
                setTotalCount(responseSold.data.totalElements);
                setIsLoading(false);
            }
            
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false);
        }
    };

    const handleFilterChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        field: keyof any
    ) => {
        const value = event.target.value;

        const tempFilter = {
            ...filter,
            [field]: value,
        };

        setFilter(tempFilter);
        fetchInventoryListingData(
            page,
            pageSize,
            tempFilter,
            sortField, sortDirection
        );
    };

    //add tooltip
    const CellWithTooltip = ({ value }: { value: string | number }) => {
        return (
            <Tooltip arrow title={value?.toString()} sx={{ zIndex: 1 }} disableFocusListener disableTouchListener>
                <span>{value?.toString()}</span>
            </Tooltip>
        );
    };

    const listedColumns: GridColDef[] = [
        {
            field: "title",
            headerName: "Inventory",
            flex: 1,
            sortable: true,
            minWidth: 280,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{ width: "200px" }}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search"
                            defaultValue={filter.title}
                            onChange={(event) => handleFilterChange(event, "title")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
            renderCell: (params: any) => <CellWithTooltip value={params.value} />
        },
        ...(CommonService.isAdmin()
            ? [
                {
                    field: "ownerName",
                    headerName: "Company Name",
                    flex: 1,
                    sortable: true,
                    minWidth: 165,
                    headerClassName: "table-header border-0 my-2 h-100",
                    renderCell: (params: any) => <CellWithTooltip value={params.value} />,
                },
            ]
            : []),
        {
            field: "price",
            headerName: "Price/Barrel",
            flex: 1,
            sortable: true,
            minWidth: 140,
            headerClassName: "table-header border-0 my-2 h-100",
            valueGetter: (value: any, row: any) => row.price ? `$${parseFloat(row.price).toFixed(2)}` : "$0.00",
            renderCell: (params: any) => <CellWithTooltip value={params.value} />
        },
        {
            field: "fee",
            headerName: "Commission Fee/Barrel",
            headerClassName: "table-header border-0 my-2 h-100",
            flex: 1,
            minWidth: 192,
            valueGetter: (value: any, row: any) => {
                if (!row) return "$0.00";
            
                const fee = row.fee ?? 0; 
                const feeType = row.feeType ?? "FLAT";
            
                return feeType === "PERCENTAGE" ? `${parseFloat(fee).toFixed(2)}%` : `$${parseFloat(fee).toFixed(2)}`;
            },
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
            field: "availableBarrels",
            headerName: "Available Barrels",
            flex: 1,
            sortable: true,
            minWidth: 140,
            headerClassName: "table-header border-0 my-2 h-100",
            valueGetter: (value: any, row: any) => {
                const count = row.availableBarrels ?? 0;
                return `${count} ${count === 1 || count === 0 ? "Barrel" : "Barrels"}`;
            },
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
            field: "reservedBarrels",
            headerName: "Reserved Barrels",
            flex: 1,
            sortable: true,
            minWidth: 140,
            headerClassName: "table-header border-0 my-2 h-100",
            valueGetter: (value: any, row: any) => {
                const count = row.reservedBarrels ?? 0;
                return `${count} ${count === 1 || count === 0 ? "Barrel" : "Barrels"}`;
            },
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
            field: "soldBarrels",
            headerName: "Sold Barrels",
            flex: 1,
            sortable: true,
            minWidth: 140,
            headerClassName: "table-header border-0 my-2 h-100",
            valueGetter: (value: any, row: any) => {
                const count = row.soldBarrels ?? 0;
                return `${count} ${count === 1 || count === 0 ? "Barrel" : "Barrels"}`;
            },
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
            field: "fillDate",
            headerName: "Age",
            flex: 1,
            sortable: true,
            minWidth: 157,
            headerClassName: "table-header border-0 my-2 h-100",
            valueGetter: (value: any, row: any) => {
                const currentDate = new Date();
                const fillDate = new Date(row.fillDate); // Use the fillDate field

                // Calculate the difference in years and months
                let years = currentDate.getFullYear() - fillDate.getFullYear();
                let months = currentDate.getMonth() - fillDate.getMonth();

                // Adjust for negative months (if current month is earlier than the fill month)
                if (months < 0) {
                    years--;
                    months += 12;
                }

                // Format the result as 'X years Y months'
                return `${years} years ${months} months`;
            },
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
            field: "facility",
            headerName: "Facility",
            flex: 1,
            sortable: true,
            minWidth: 165,
            headerClassName: "table-header border-0 my-2 h-100",
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
            field: "lotNo",
            headerName: "Lot IDs",
            flex: 1,
            sortable: false,
            minWidth: 250,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{ width: "180px" }}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search"
                            defaultValue={filter.lotNo}
                            onChange={(event) => handleFilterChange(event, "lotNo")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
            // renderCell: (params: any) => <CellWithTooltip value={params.value} />,
            renderCell: (params: any) => {
                const lotIds = params.value ? params.value.split(", ") : []; // Split into array
            
                return (
                    <div className="lot-id-list-wrapper d-flex flex-nowrap gap-2 h-100 align-items-center overflow-auto">
                        {lotIds.map((lot, index) => (
                            <div key={index} className="lot-id-item px-2 py-1 border rounded">
                                {/* <CellWithTooltip value={lot} /> */}
                                {lot}
                            </div>
                        ))}
                    </div>
                );
            }                    
        },
    ].filter(Boolean) as GridColDef[];

    const soldColumns: GridColDef[] = [
        {
            field: "title",
            headerName: "Inventory",
            flex: 1,
            sortable: true,
            minWidth: 280,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{ width: "200px" }}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search"
                            defaultValue={filter.title}
                            onChange={(event) => handleFilterChange(event, "title")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
            renderCell: (params: any) => <CellWithTooltip value={params.value} />
        },
        ...(CommonService.isAdmin()
            ? [
                {
                    field: "ownerName",
                    headerName: "Company Name",
                    flex: 1,
                    sortable: true,
                    minWidth: 165,
                    headerClassName: "table-header border-0 my-2 h-100",
                    renderCell: (params: any) => <CellWithTooltip value={params.value} />,
                },
            ]
            : []),
        {
            field: "price",
            headerName: "Price/Barrel",
            flex: 1,
            sortable: true,
            minWidth: 140,
            headerClassName: "table-header border-0 my-2 h-100",
            valueGetter: (value: any, row: any) => row.price ? `$${parseFloat(row.price).toFixed(2)}` : "$0.00",
            renderCell: (params: any) => <CellWithTooltip value={params.value} />
        },
        {
            field: "fee",
            headerName: "Commission Fee/Barrel",
            headerClassName: "table-header border-0 my-2 h-100",
            flex: 1,
            minWidth: 192,
            valueGetter: (value: any, row: any) => {
                if (!row) return "$0.00";
            
                const fee = row.fee ?? 0; 
                const feeType = row.feeType ?? "FLAT";
            
                return feeType === "PERCENTAGE" ? `${parseFloat(fee).toFixed(2)}%` : `$${parseFloat(fee).toFixed(2)}`;
            },
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
            field: "soldBarrels",
            headerName: "Total Barrels",
            flex: 1,
            sortable: true,
            minWidth: 140,
            headerClassName: "table-header border-0 my-2 h-100",
            valueGetter: (value: any, row: any) => {
                const count = row.soldBarrels ?? 0;
                return `${count} ${count === 1 || count === 0 ? "Barrel" : "Barrels"}`;
            },
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
            field: "fillDate",
            headerName: "Age",
            flex: 1,
            sortable: true,
            minWidth: 157,
            headerClassName: "table-header border-0 my-2 h-100",
            valueGetter: (value: any, row: any) => {
                const currentDate = new Date();
                const fillDate = new Date(row.fillDate); // Use the fillDate field

                // Calculate the difference in years and months
                let years = currentDate.getFullYear() - fillDate.getFullYear();
                let months = currentDate.getMonth() - fillDate.getMonth();

                // Adjust for negative months (if current month is earlier than the fill month)
                if (months < 0) {
                    years--;
                    months += 12;
                }

                // Format the result as 'X years Y months'
                return `${years} years ${months} months`;
            },
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
            field: "facility",
            headerName: "Facility",
            flex: 1,
            sortable: true,
            minWidth: 165,
            headerClassName: "table-header border-0 my-2 h-100",
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
            field: "lotNo",
            headerName: "Lot IDs",
            flex: 1,
            sortable: false,
            minWidth: 250,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{ width: "180px" }}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search"
                            defaultValue={filter.lotNo}
                            onChange={(event) => handleFilterChange(event, "lotNo")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
            // renderCell: (params: any) => <CellWithTooltip value={params.value} />,
            renderCell: (params: any) => {
                const lotIds = params.value ? params.value.split(", ") : []; // Split into array
            
                return (
                    <div className="lot-id-list-wrapper d-flex flex-nowrap gap-2 h-100 align-items-center overflow-auto">
                        {lotIds.map((lot, index) => (
                            <div key={index} className="lot-id-item px-2 py-1 border rounded">
                                {/* <CellWithTooltip value={lot} /> */}
                                {lot}
                            </div>
                        ))}
                    </div>
                );
            }                    
        },
    ].filter(Boolean) as GridColDef[];

    const handleRowClick = (params) => {
        const linkPrefix = userRole === "SUPER_ADMIN" ? "/master" : "/user";
        if(activeButtonRef.current?.toLowerCase() === "listed".toLowerCase()) {
            history.push(`${linkPrefix}/view-inventory-listing-details/${params.row.id}`);
        } else {
            history.push(`${linkPrefix}/view-sold-inventory-listing-details/${params.row.id}`);
        }
        
    }

    // Map filtered rows with unique IDs
    const tableDataWithIds = tableData.map((rowData, index) => ({
        ...rowData,
    }));

    const handleBack = () => {
        if (currentPath.startsWith("/master/inventory-listing")) {
          history.push(`/master/dashboard`);
        } else {
          history.goBack();
        }
        
      };

    const onPaginationModelChange = (newPaginationModel) => {
        setPage(newPaginationModel.page);
        setPageSize(newPaginationModel.pageSize);
    
    // Fetch using the latest sorting values
    fetchInventoryListingData(newPaginationModel.page, newPaginationModel.pageSize, filter, sortField, sortDirection);
    };

    const handleSortChange = (sortModel) => {
        if (sortModel.length > 0) {
            const newSortField = sortModel[0].field;
            const newSortDirection = sortModel[0].sort; // "asc" or "desc" directly from DataGrid
            
            setSortField(newSortField);
            setSortDirection(newSortDirection);
        
            fetchInventoryListingData(page, pageSize, filter, newSortField, newSortDirection);
        }
    };  

    return (
        <div className="usersList pt-0" style={{ paddingBottom: CommonService.isAdmin() ? 53 : "" }}>
            <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center p-3">
                {CommonService.isAdmin() && (<IconButton
                    onClick={handleBack}
                    aria-label="ArrowBackIcon"
                    sx={{
                        backgroundColor: "#FFF",
                        "&:hover": { backgroundColor: "#FFF" },
                    }}
                >
                    <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
                </IconButton>)}
                {CommonService.isAdmin() && (<div className="header--title me-2">
                    <h1 className="title mb-0 ms-2">
                        Total Inventory
                    </h1>
                </div>)}
                <div className="header--menu ms-auto mb-2">
                    <div className="header--menu-items row d-flex justify-content-end">
                        <div className="col-auto mb-2">
                            {/* <SearchBar search={search} setSearch={setSearch} /> */}
                        </div>
                    </div>
                </div>
                <div className="ms-auto mb-2">
            <div
              className="btn-group"
              role="group"
              aria-label="Basic mixed styles example"
            >
              <button
                className={`white-outline-btn d-inline-flex justify-content-between align-items-center ms-1 rounded-end-0 py-0
                    ${activeButtonRef.current === "listed" ? "bg-white" : ""}`}
                onClick={() => {
                  activeButtonRef.current = "listed";
                  setActiveButton("listed");
                  setFilter(initialFilter);
                  fetchInventoryListingData(
                    page,
                    pageSize,
                    initialFilter,
                    "title", sortDirection,
                  );
                }}
              >
                Listed
              </button>
              <button
                className={`white-outline-btn d-inline-flex justify-content-between align-items-center rounded-start-0
                    ${
                      activeButtonRef.current === "sold"
                        ? "bg-white"
                        : ""
                    }`}
                onClick={() => {
                  activeButtonRef.current = "sold";
                  setActiveButton("sold");
                  setFilter(initialFilter);
                  fetchInventoryListingData(
                    page,
                    pageSize,
                    initialFilter,
                    "title", sortDirection,
                  );
                }}
                style={{ paddingBlock: 12 }}
              >
                Sold
              </button>
            </div>
          </div>
            </div>
            <div className="user-table-container">
                <div className="userTable inventory-listing-table-wrapper">
                    <DataGrid
                        rows={tableDataWithIds}
                        columns={activeButtonRef.current === "sold" ? soldColumns : listedColumns}
                        className="data-grid data-grid__myinventories"
                        initialState={{
                            pagination: {
                                paginationModel: { page, pageSize },
                            },
                        }}
                        pagination
                        paginationModel={{ page, pageSize }}
                        paginationMode="server"
                        sortingMode="server"
                        //paginationModel={paginationModel}
                        onSortModelChange={handleSortChange}
                        onPaginationModelChange={onPaginationModelChange}
                        pageSizeOptions={[10, 20, 50, 100]}
                        rowCount={totalCount}
                        onRowClick={handleRowClick}
                        disableRowSelectionOnClick={true}
                        loading={isLoading}
                    />
                </div>
            </div>
        </div>
    );
};

export default InventoryListing;