import { DataGrid, GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import "../Dynamic/DisplayGrid.scss";
import { Box, Skeleton } from "@mui/material";
import { useState } from "react";

interface ChildProps {
  tableData: any;
  columns: any;
  localeText: any;
  title: any;
  totalRows: number; // Total number of rows for server-side pagination
  onPageChange: (paginationModel: GridPaginationModel) => void; // Callback to fetch data
  isLoading: boolean; // Loading state for API call
  paginationModel: GridPaginationModel; // Add this prop
  onSortChange: (sortModel: GridSortModel) => void;
}

export const DisplayGrid: React.FC<ChildProps> = ({
  tableData,
  columns,
  localeText,
  title,
  totalRows,
  onPageChange,
  isLoading,
  paginationModel,
  onSortChange,
}) => {
  const toTitleCase = (str: string) => {
    return str
      ?.replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  const processedTableData = tableData.map((row: any, index: number) => ({
    id: row.id || `temp-id-${index}`, // Ensure each row has a unique id
    ...row
  }));

  // Handle pagination change
  const handlePaginationChange = (paginationModel: GridPaginationModel) => {
    onPageChange(paginationModel); // Notify the parent component
  };

  const handleSortingChange = (sortModel: GridSortModel) => {
    onSortChange(sortModel); 
  };

  return (
    <div className="row mt-5">
      <div className="d-flex justify-content-between mb-3">
        <h5 className="mb-0">
          <b>{toTitleCase(title)}</b>
        </h5>
        {/* <Button className='p-0' variant="link" onClick={() => route("/master/messages")}>View all</Button> */}
      </div>
      <div className="company-dashboard-data-grid">
        <Box sx={{ height: 350, width: "100%", overflowX: "auto" }}>
              <DataGrid
                localeText={localeText}
                rows={processedTableData}
                columns={columns}
                className="data-grid"
                pageSizeOptions={[10, 20, 50, 100]}
                paginationModel={paginationModel} // Use the prop instead of initialState
                paginationMode="server" // Enable server-side pagination
                sortingMode="server"
                onSortModelChange={handleSortingChange}
                rowCount={totalRows} // Total rows available on the server
                onPaginationModelChange={handlePaginationChange}
                loading={isLoading} // Show loader while fetching data
                pagination
                disableRowSelectionOnClick={true}
                disableColumnResize
              />
        </Box>
      </div>
    </div>
  );
};
