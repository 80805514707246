import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Dashboard from "../Dynamic/Dashboard";
// import Footer from './Footer';
import Header from "./Header";
import Register from "../Static/Register";
import Inventory from "../Dynamic/Inventory";
import Cookies from "universal-cookie";
import Typesense from "../Dynamic/Typesense";
import InventoryCSV from "../Dynamic/InventoryCSV";
import ViewCSV from "../Dynamic/ViewCSV";
import OrderDetail from "../Dynamic/OrderDetail";
import ProductDetail from "../Dynamic/ProductDetail";
import MyCart from "../Dynamic/MyCart";
import LoginHistory from "../Dynamic/LoginHistory";
import ActiveOrder from "../Dynamic/ActiveOrder";
import SampleRequest from "../Dynamic/SampleRequest";
import OfferRequest from "../Dynamic/OfferRequest";
import ContainerMessageBox from "../Dynamic/ContainerMessageBox";
import UserList from "../Dynamic/UserList";
import ViewSellRequest from "../Dynamic/ViewSellRequest";
import GroupMessageBox from "../Dynamic/GroupMessageBox";
import CompanyList from "../Dynamic/CompanyList";
import WarehouseCompanyDashboard from "../Dynamic/WarehouseCompanyDashboard";
import ViewSampleRequest from "../Dynamic/ViewSampleRequest";
import ViewOfferRequest from "../Dynamic/ViewOfferRequest";
import AddCompanyOrUser from "../Dynamic/AddCompanyOrUser";
import MyInventories from "../Dynamic/MyInventories";
import CompareInventory from "../Dynamic/CompareInventory";
import MyRequests from "../Dynamic/MyRequests";
import ViewOrEditProfile from "../Static/Profile/ViewOrEditProfile";
import UATbelt from "./UATbelt";
import DuplicateInventories from "../Dynamic/DuplicateInventories";
import ManagePermissions from "../Dynamic/ManagePermissions";
import ManageRoles from "../Dynamic/ManageRoles";
import AddSupportRequest from "../Dynamic/AddSupportRequest";
import SupportRequest from "../Dynamic/SupportRequest";
import ViewSupportRequest from "../Dynamic/ViewSupportRequest";
import InventoryListing from "../Dynamic/InventoryListing";
import ViewInventoryListingDetails from "../Dynamic/ViewInventoryListingDetails";
import ViewSoldInventoryListingDetails from "../Dynamic/ViewSoldInventoryListingDetails";

const Layout = () => {
  const [userRole, setUserRole] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserRole = () => {
      const cookies = new Cookies();
      const role = cookies.get("userRole");
      setUserRole(role);
    };

    fetchUserRole();
  }, []); // Empty dependency array ensures this effect runs only once

  if (userRole === null) {
    // Render loading indicator while fetching user role
    return <div>Loading...</div>;
  }

  return (
    <div>
      <UATbelt />
      <Header />
      <Switch>
        <Route exact path="/master/my-profile/:id">
          <ViewOrEditProfile />
        </Route>

        <Route exact path="/master/manage-requests">
          <MyRequests />
        </Route>

        <Route exact path="/master/dashboard">
          {/* {userRole === 'SUPER_ADMIN' ? <Dashboard /> : <Redirect to="/inventory" />} */}
          <Dashboard />
        </Route>

        <Route exact path="/master/user-list">
          <UserList />
        </Route>

        <Route exact path="/master/inventory">
          <MyInventories />
        </Route>

        <Route exact path="/master/available-barrel">
          <MyInventories />
        </Route>

        <Route exact path="/master/reserved-barrel">
          <MyInventories />
        </Route>

        <Route exact path="/master/inventory-listing">
          <InventoryListing />
        </Route>

        <Route exact path="/master/my-warehouse/inventory-listing/:id">
          <InventoryListing />
        </Route>

        <Route exact path="/master/view-inventory-listing-details/:id">
          <ViewInventoryListingDetails />
        </Route>

        <Route exact path="/master/view-sold-inventory-listing-details/:id">
          <ViewSoldInventoryListingDetails />
        </Route>

        <Route exact path="/master/inventory-sell-requests">
          <InventoryCSV />
        </Route>

        <Route exact path="/master/inventory-sell-requests/:id">
          <InventoryCSV />
        </Route>

        <Route exact path="/master/product-details/:id">
          <ProductDetail />
        </Route>

        <Route exact path="/master/view-csv/:id">
          <ViewCSV />
        </Route>

        <Route exact path="/master/view-sell-request/:id">
          <ViewSellRequest />
        </Route>

        <Route exact path="/master/order-detail/:id">
          <OrderDetail />
        </Route>

        <Route exact path="/master/login-history">
          {}
          <LoginHistory />
        </Route>

        <Route exact path="/master/active-order">
          <ActiveOrder />
        </Route>

        <Route exact path="/master/my-warehouse/active-order/:id">
          <ActiveOrder />
        </Route>

        <Route exact path="/master/sample-request">
          <SampleRequest />
        </Route>

        <Route exact path="/master/view-sample-request/:id">
          <ViewSampleRequest />
        </Route>

        <Route exact path="/master/my-warehouse/sample-request/:id">
          <SampleRequest />
        </Route>

        <Route exact path="/master/inventory-listing/:id">
          <SampleRequest />
        </Route>

        <Route exact path="/master/offer-request">
          <OfferRequest />
        </Route>

        <Route exact path="/master/view-offer-request/:id">
          <ViewOfferRequest />
        </Route>

        <Route exact path="/master/my-warehouse/offer-request/:id">
          <OfferRequest />
        </Route>

        <Route exact path="/master/messages">
          <GroupMessageBox />
        </Route>

        <Route exact path="/master/company-list">
          <CompanyList />
        </Route>

        <Route exact path="/master/company-dashboard/:id">
          <WarehouseCompanyDashboard />
        </Route>

        <Route exact path="/master/add-user">
          <AddCompanyOrUser type={"user"} />
        </Route>

        <Route exact path="/master/add-company">
          <AddCompanyOrUser type={"company"} />
        </Route>

        <Route exact path="/master/my-warehouse/user-list/:id">
          <UserList />
        </Route>

        <Route exact path="/master/inventory-change-requests">
          <MyInventories />
        </Route>

        <Route exact path="/master/compare-inventory/:id">
          <CompareInventory />
        </Route>

        <Route exact path="/master/user-detail/:id">
          <ViewOrEditProfile />
        </Route>

        <Route exact path="/master/my-warehouse/inventory/:id">
          <MyInventories />
        </Route>

        <Route exact path="/master/my-warehouse/available-for-sell/:id">
          <MyInventories />
        </Route>

        <Route exact path="/master/my-warehouse/not-available-for-sell/:id">
          <MyInventories />
        </Route>

	      <Route exact path="/master/duplicate-inventory">
          <DuplicateInventories/>
        </Route>

        <Route exact path= "/master/manage-permissions/:id">
        <ManagePermissions/>
        </Route>

        <Route exact path = "/master/manage-roles">
        <ManageRoles/>
        </Route>

        <Route exact path="/master/company-dashboard-as-buyer/:id">
          <WarehouseCompanyDashboard />
        </Route>

        <Route exact path="/master/company-dashboard-as-owner/:id">
          <WarehouseCompanyDashboard />
        </Route>
        
        <Route exact path="/master/customer-service">
          <SupportRequest />
        </Route>

        <Route exact path="/master/add-customer-service-request">
          <AddSupportRequest />
        </Route>
        
        <Route exact path="/master/view-customer-service-request/:id">
          <ViewSupportRequest />
        </Route>

        <Route exact path="/master/edit-customer-service-request/:id">
          <AddSupportRequest />
        </Route>

        <Route path="/">
          {userRole === "SUPER_ADMIN" ? (
            <Redirect to="master/dashboard" />
          ) : (
            <Redirect to="user/inventories" />
          )}
        </Route>
      </Switch>
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
