import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { Service } from "../../Service/ApiService";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  VisibilityOutlined,
  EditOutlined,
  DeleteOutlined,
  SendOutlined,
  Iso,
} from "@mui/icons-material";
import { CommonService } from "../../Service/CommonService";
import "../Dynamic/UserList.scss";
import InviteUser from "../popups/InviteUser";
import React from "react";
import SingleApprove from "../popups/SingleApprove";
import SingleReInvite from "../popups/SingleReInvite";
import SingleReject from "../popups/SingleReject";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import DeleteUser from "../popups/DeleteUser";
import Tooltip from "@mui/material/Tooltip";
import SearchBar from "../Static/SearchBar";
import { isDebuggerStatement } from "typescript";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import SplitButton from "react-bootstrap/SplitButton";
import { useHistory, useLocation, useParams } from "react-router";
import StatusDropdown from "../Common/StatusDropdown";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import InfoIcon from "@mui/icons-material/Info";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { CookieService } from "../../Service/CookieService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import Constant from "../../Constant";
import Spinner from "../Common/Spinner";
import RestoreUser from "../popups/RestoreUser";
import ReplayIcon from '@mui/icons-material/Replay';

const UserList = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { id } = useParams<{ id: string }>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [reviewUserCount, setReviewCount] = useState(0);
  const [search, setSearch] = useState("");
  let approved: any[] = [];
  const approvedUserRef = useRef(approved);
  const deletedUserRef = useRef(approved);
  let rejected: any[] = [];
  const rejectedUserRef = useRef(rejected);
  const disableAction = useRef(false);
  const isNeedReviewClicked = useRef(false);
  const selectedRows = useRef(approved);
  const [selectedRowsFromTable, setSelectedRowsFromTable] = useState<any[]>([]);
  const [selectionRowsFromTable, setSelectionRowsFromTable] = useState<any[]>([]);
  const history = useHistory();
  const [activeUserCount, setActiveUserCount] = useState(0);
  const [deleteUserCount, setDeleteUserCount] = useState(0);
  const [rejectedUsersCount, setRejectedUserCount] = useState(0);
  const isRejectedUserClicked = useRef(false);
  const isActiveUserClicked = useRef(false);
  const isDeletedUserClicked = useRef(false);

  const [forceOpen, setForceOpen] = useState(false);
  const [isSingleClickMode, setIsSingleClickMode] = useState(false);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const controllerRef = useRef<AbortController | null>(null);
  const [sortField, setSortField] = useState("CreatedModifiedDate"); // Default sort
  const [sortDirection, setSortDirection] = useState("asc"); // Default ascending
  const [filter, setFilter] = useState<any>({
    firstName: null,
    email: null,
    roleName: null,
    phoneNumber: null,
    birthdate: null,
    ecommCompanyName: null,
    requestedBy: null,
    location: null,
    userStatus: null,
  });

  const toTitleCase = (str: string) => {
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  const reloadTable = () => {
    if (isActiveUserClicked.current) {
      fetchApprovedUserData(page, pageSize, filter, sortField, sortDirection);
      fetchDeleteUsersCount();
    } else if (isNeedReviewClicked.current) {
      showSubmittedAndInvitedUsers(true, page, pageSize, filter, sortField, sortDirection);
      fetchRejectedUsersCount();
      fetchActiveUsersCount();
    }else if (isDeletedUserClicked.current) {
      fetchDeletedUserData(page, pageSize, filter, sortField, sortDirection);
      fetchActiveUsersCount();
    } else {
      showRejectedUsers(true, page, pageSize, filter, sortField, sortDirection);
    }
  };

  const setOpenPopUp = () => {
    setIsSingleClickMode(false);
    setForceOpen(false);
  };

  const showSubmittedAndInvitedUsers = (
    isReload: boolean,
    page: any,
    size: any,
    filter: any,
    sortField: any, 
    sortDirection: any
  ) => {
    if (!isReload) {
      isNeedReviewClicked.current = !isNeedReviewClicked.current;
    }
    if (isNeedReviewClicked.current) {
      const controller = new AbortController();
      const signal = controller.signal;
      try {
        isActiveUserClicked.current = false;
        isRejectedUserClicked.current = false;
        isDeletedUserClicked.current = false;

        setIsLoading(true);
        setTableData([]);
        // Cancel the previous request if any
        if (controllerRef.current) {
          controllerRef.current.abort();
        }

        // Store the current controller to cancel the request if needed
        controllerRef.current = controller;

        Service.getSubmittedUsers(
          CommonService.isUser()
            ? CommonService.getCompanyId()
            : currentPath.includes("/master/my-warehouse/user-list")
            ? id
            : null,
          page,
          size,
          filter,
          sortField,
          sortDirection,
          signal
        )
          .then((response) => {
            let c: any = [];
            c = response.data.content;
            setTotalCount(response.data.totalElements);
            setTableData(c);
            setReviewCount(response.data.totalElements);
          })
          .catch((error) => {
            if (error.response?.status === 401) {
              // CommonService.unAuthorizedError();
            } else {
              // Process the response data
              //CommonService.Toast.fire({
              //icon: "error",
              //title: "error.response.data.error",
              //});
            }
          });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    } else {
      console.log("called approved user : ", approvedUserRef.current);
      fetchApprovedUserData(page, pageSize, filter, sortField, sortDirection);
    }
  };

  const showActiveUsers = (isReload: boolean) => {
    //write the logic for show active users
    if (!isActiveUserClicked.current) {
      isRejectedUserClicked.current = false;
      isNeedReviewClicked.current = false;
      isActiveUserClicked.current = true;
      isDeletedUserClicked.current = false;
      fetchApprovedUserData(page, pageSize, filter, sortField, sortDirection);
    }
  };

  const showDeletedUsers = (isReload: boolean) => {
    //write the logic for show active users
    if (!isDeletedUserClicked.current) {
      isRejectedUserClicked.current = false;
      isNeedReviewClicked.current = false;
      isActiveUserClicked.current = false;
      isDeletedUserClicked.current = true;
      fetchDeletedUserData(page, pageSize, filter, sortField, sortDirection);
    }
  };

  const showRejectedUsers = (
    isReload: boolean,
    page: any,
    size: any,
    filter: any,
    sortField: any, 
    sortDirection: any
  ) => {
    if (!isReload) {
      isRejectedUserClicked.current = !isRejectedUserClicked.current;
    }
    if (isRejectedUserClicked.current) {
      const controller = new AbortController();
      const signal = controller.signal;
      try {
        isActiveUserClicked.current = false;
        isNeedReviewClicked.current = false;
        isDeletedUserClicked.current = false;
        setIsLoading(true);
        setTableData([]);
        // Cancel the previous request if any
        if (controllerRef.current) {
          controllerRef.current.abort();
        }

        // Store the current controller to cancel the request if needed
        controllerRef.current = controller;

        Service.getAllRejectedUsers(
          CommonService.isUser()
            ? CommonService.getCompanyId()
            : currentPath.includes("/master/my-warehouse/user-list")
            ? id
            : null,
          page,
          size,
          filter,
          sortField,
          sortDirection,
          signal
        )
          .then((response) => {
            let c: any = [];
            c = response.data[0].content;
            setTotalCount(response.data[0]?.totalElements);
            setTableData(c);
            setRejectedUserCount(response.data[0]?.totalElements);
          })
          .catch((error) => {
            console.log("error occurred while api calling");
          });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    } else {
      console.log("called rejected user : ", rejectedUserRef.current);
      fetchApprovedUserData(page, pageSize, filter, sortField, sortDirection);
    }
  };

  //redirect to the add user page
  const handleAddUser = () => {
    if (CommonService.isAdmin()) history.push("/master/add-user");
    else history.push("/user/add-user");
  };

  const fetchApprovedUserData = async (page: any, size: any, filter: any, sortField: any, sortDirection: any) => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      setIsLoading(true);
      setTableData([]);
      // Cancel the previous request if any
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      // Store the current controller to cancel the request if needed
      controllerRef.current = controller;
      isActiveUserClicked.current = true;

      const response = await Service.getAllApprovedUsers(
        CommonService.isUser()
          ? CommonService.getCompanyId()
          : currentPath.includes("/master/my-warehouse/user-list")
          ? id
          : null,
        page,
        size,
        filter,
        sortField, sortDirection,
        signal
      );
      approved = Object.values(response.data.content); // Convert object to array
      setTotalCount(response.data.totalElements);
      setActiveUserCount(response.data.totalElements);
      approvedUserRef.current = approved;
      // Access updated value of approvedUser using approvedUserRef.current
      if (approved) {
        setTableData(approvedUserRef.current);
      }
      Service.getSubmittedUsersCount(
        CommonService.isUser()
          ? CommonService.getCompanyId()
          : currentPath.includes("/master/my-warehouse/user-list")
          ? id
          : null
      ).then((response) => {
        setReviewCount(response.data);
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchDeletedUserData = async (page: any, size: any, filter: any, sortField: any, sortDirection: any) => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      setIsLoading(true);
      setTableData([]);
      // Cancel the previous request if any
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      // Store the current controller to cancel the request if needed
      controllerRef.current = controller;
      isDeletedUserClicked.current = true;

      const response = await Service.getAllDeletedUsers(
        CommonService.isUser()
          ? CommonService.getCompanyId()
          : currentPath.includes("/master/my-warehouse/user-list")
          ? id
          : null,
        page,
        size,
        filter,
        sortField,
        sortDirection,
        signal
      );
      approved = Object.values(response.data.content); // Convert object to array
      setTotalCount(response.data.totalElements);
      setDeleteUserCount(response.data.totalElements);
      deletedUserRef.current = approved;
      // Access updated value of approvedUser using approvedUserRef.current
      if (approved) {
        setTableData(deletedUserRef.current);
      }
      Service.getSubmittedUsersCount(
        CommonService.isUser()
          ? CommonService.getCompanyId()
          : currentPath.includes("/master/my-warehouse/user-list")
          ? id
          : null
      ).then((response) => {
        setReviewCount(response.data);
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchActiveUsersCount = async () => {
    try {
      const response = await Service.getActiveUsersCount(
        CommonService.isUser()
          ? CommonService.getCompanyId()
          : currentPath.includes("/master/my-warehouse/user-list")
          ? id
          : null
      );
      setActiveUserCount(response.data);
    } catch (error) {
      console.error("Error fetching counts:", error);
    }
  };

  const fetchDeleteUsersCount = async () => {
    try {
      const response = await Service.getDeleteUsersCount(
        CommonService.isUser()
          ? CommonService.getCompanyId()
          : currentPath.includes("/master/my-warehouse/user-list")
          ? id
          : null
      );
      setDeleteUserCount(response.data);
    } catch (error) {
      console.error("Error fetching counts:", error);
    }
  };

  const fetchCompanyUserData = async () => {
    const response: any = await Service.fetchCompanyUserData(
      CommonService.isUser()
        ? CommonService.getCompanyId()
        : currentPath.includes("/master/my-warehouse/user-list")
        ? id
        : null
    );
    setTableData(response.data);
  };

  const fetchRejectedUsersCount = async () => {
    try {
      const response = await Service.getRejectedUsersCount(
        CommonService.isUser()
          ? CommonService.getCompanyId()
          : currentPath.includes("/master/my-warehouse/user-list")
          ? id
          : null
      );
      setRejectedUserCount(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRestoreUser = () => {
    // setIsLoading(true);
    // if (deleteUser.length) {
    //     Service.removeUserProfile(deleteUser)
    //           .then(response => {
    //             setIsLoading(false);
    //             CommonService.Toast.fire({
    //               title: "User deleted successfully !",
    //               icon: 'success',
    //             });
    //             reloadTable();
    //           })
    //           .catch(error => {
    //             setIsLoading(false);
    //             console.log(error);
    //           });
    // }
    // setIsLoading(false);
    // handleClose();
  };

  useEffect(() => {
    // if (
    //   currentPath === "/master/my-warehouse/user-list/" + id ||
    //   currentPath === "/user/my-warehouse/user-list/" + id ||
    //   currentPath === "/user/user-list"
    // )
    // fetchCompanyUserData();
    fetchApprovedUserData(page, pageSize, filter, sortField, sortDirection);
    fetchRejectedUsersCount();
    fetchDeleteUsersCount();
  }, []);

  const handleVisibilityClick = (event: any, params: any) => {
    // Logic to handle the click event for Visibility button
    event.stopPropagation(); // Prevent event from bubbling up to the row
    CommonService.Toast.fire({
      title: "View icon clicked",
    });
  };

  //add tooltip
  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    field: keyof any
  ) => {
    const value = event.target.value;

    // Prepare a temporary filter object with the updated field value
    const tempFilter = {
      ...filter, // Spread existing filter state
      [field]:
        (field === "roleName" || field === "userStatus") && value === ""
          ? null
          : value, // Update field only if the condition is met
    };

    // Update the filter state
    setFilter(tempFilter);

    // Load the service requests with updated filters
    if (isActiveUserClicked.current) {
      fetchApprovedUserData(page, pageSize, tempFilter, sortField, sortDirection);
    } else if (isNeedReviewClicked.current) {
      showSubmittedAndInvitedUsers(true, page, pageSize, tempFilter, sortField, sortDirection);
    } else if (isDeletedUserClicked.current) {
      fetchDeletedUserData(page, pageSize, tempFilter, sortField, sortDirection);
    }else {
      showRejectedUsers(true, page, pageSize, tempFilter, sortField, sortDirection);
    }
  };

  const commonColumns: GridColDef[] = [
    {
      field: "firstName",
      headerName: "User",
      flex: 1,
      minWidth: 200,
      sortable: true,
      valueGetter: (value: any, row: any) => {
        const firstName = row.firstName || "";
        const lastName = row.lastName || "";
        return firstName || lastName ? `${firstName} ${lastName}` : "-";
      },
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
          <Typography
            variant="body2"
            sx={{ color: "#6F6F6F", fontSize: "14px" }}
          >
            {params.colDef.headerName}
          </Typography>
          <div className="position-relative" style={{ width: 170 }}>
            <input
              type="text"
              className="form-control border mt-1"
              style={{ textIndent: "24px", boxShadow: "none" }}
              placeholder="Search"
              //value={filter.firstName}
              defaultValue={filter.firstName}
              onChange={(event) => handleFilterChange(event, "firstName")}
              onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
            />
            <SearchIcon
              sx={{
                color: "#6F6F6F",
                position: "absolute",
                top: "50%",
                left: "8px",
                transform: "translateY(-50%)",
              }}
            />
          </div>
        </div>
      ),
      renderCell: (params: any) => <div>{params.value}</div>,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
      sortable: true,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
          <Typography
            variant="body2"
            sx={{ color: "#6F6F6F", fontSize: "14px" }}
          >
            {params.colDef.headerName}
          </Typography>
          <div className="position-relative" style={{ width: 170 }}>
            <input
              type="text"
              className="form-control border mt-1"
              style={{ textIndent: "24px", boxShadow: "none" }}
              placeholder="Search"
              //value={filter.email}
              defaultValue={filter.email}
              onChange={(event) => handleFilterChange(event, "email")}
              onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
            />
            <SearchIcon
              sx={{
                color: "#6F6F6F",
                position: "absolute",
                top: "50%",
                left: "8px",
                transform: "translateY(-50%)",
              }}
            />
          </div>
        </div>
      ),
      renderCell: (params) => <CellWithTooltip value={params.value || "-"} />,
    },
    {
      field: "roleName",
      headerName: "Role",
      flex: 1,
      minWidth: 200,
      sortable: true,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <>
          <div>
            <Typography
              variant="body2"
              sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
              {params.colDef.headerName}
            </Typography>
            <div className="position-relative" style={{ width: 170 }}>
              <Form.Select
                style={{
                  cursor: "pointer",
                  borderBottomColor: "#D1D1D1",
                  marginTop: "4px",
                }}
                value={filter.roleName}
                className="form-control mb-1"
                onChange={(event) => handleFilterChange(event, "roleName")}
                onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
              >
                <option value="">Select Role</option>
                <option value="ADMIN">Admin</option>
                <option value="USER">User</option>
              </Form.Select>
            </div>
          </div>
        </>
      ),
      renderCell: (params) => (
        <CellWithTooltip value={CommonService.formatRoleName(params.value)} />
      ),
    },
    {
      field: "phoneNumber",
      headerName: "Phone",
      flex: 1,
      minWidth: 200,
      sortable: true,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
          <Typography
            variant="body2"
            sx={{ color: "#6F6F6F", fontSize: "14px" }}
          >
            {params.colDef.headerName}
          </Typography>
          <div className="position-relative" style={{ width: 170 }}>
            <input
              type="text"
              className="form-control border mt-1"
              style={{ textIndent: "24px", boxShadow: "none" }}
              placeholder="Search"
              //value={filter.phoneNumber}
              defaultValue={filter.phoneNumber}
              onChange={(event) => handleFilterChange(event, "phoneNumber")}
              onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
            />
            <SearchIcon
              sx={{
                color: "#6F6F6F",
                position: "absolute",
                top: "50%",
                left: "8px",
                transform: "translateY(-50%)",
              }}
            />
          </div>
        </div>
      ),
      renderCell: (params) => <CellWithTooltip value={params.value || "-"} />,
    },
    {
      field: "birthdate",
      headerName: "Birth Date",
      flex: 1,
      minWidth: 200,
      sortable: true,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
          <Typography
            variant="body2"
            sx={{ color: "#6F6F6F", fontSize: "14px" }}
          >
            {params.colDef.headerName}
          </Typography>
          <input
            type="date"
            className="form-control border mt-1"
            style={{ boxShadow: "none" }}
            id="birthdate"
            name="birthdate"
            placeholder="MM/DD/YYYY"
            //value={filter.birthdate}
            defaultValue={filter.birthdate}
            onChange={(event) => handleFilterChange(event, "birthdate")}
            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
          />
        </div>
      ),
      renderCell: (params) => <CellWithTooltip value={params.value || "-"} />,
      valueGetter: (value: any, row: any) => {
        const dateTime = row.birthdate ? new Date(row.birthdate) : null;

        // Format Date and time
        if (dateTime) {
          const options: any = {
            month: "short", // Abbreviated month name (e.g., "Jul")
            day: "numeric", // Day of the month (e.g., "16")
            year: "numeric", // Full year (e.g., "2024")
          };
          return dateTime.toLocaleString("en-US", options);
        } else {
          return "";
        }
      },
    },
    ...(CommonService.isAdmin()
      ? [
          {
            field: "ecommCompanyName",
            headerName: "Company Name",
            flex: 1,
            minWidth: 200,
            sortable: true,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
              <div>
                <Typography
                  variant="body2"
                  sx={{ color: "#6F6F6F", fontSize: "14px" }}
                >
                  {params.colDef.headerName}
                </Typography>
                <div className="position-relative" style={{ width: 170 }}>
                  <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    //value={filter.ecommCompanyName}
                    defaultValue={filter.ecommCompanyName}
                    onChange={(event) =>
                      handleFilterChange(event, "ecommCompanyName")
                    }
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                  />
                  <SearchIcon
                    sx={{
                      color: "#6F6F6F",
                      position: "absolute",
                      top: "50%",
                      left: "8px",
                      transform: "translateY(-50%)",
                    }}
                  />
                </div>
              </div>
            ),
            renderCell: (params) => (
              <CellWithTooltip value={params?.row?.ecommCompanyName || "-"} />
            ),
          },
        ]
      : []),
    {
      field: "requestedBy",
      //headerName: "Requested By",
      headerName: "Created By",
      flex: 1,
      minWidth: 200,
      sortable: true,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
          <Typography
            variant="body2"
            sx={{ color: "#6F6F6F", fontSize: "14px" }}
          >
            {params.colDef.headerName}
          </Typography>
          <div className="position-relative" style={{ width: 170 }}>
            <input
              type="text"
              className="form-control border mt-1"
              style={{ textIndent: "24px", boxShadow: "none" }}
              placeholder="Search"
              //value={filter.requestedBy}
              defaultValue={filter.requestedBy}
              onChange={(event) => handleFilterChange(event, "requestedBy")}
              onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
            />
            <SearchIcon
              sx={{
                color: "#6F6F6F",
                position: "absolute",
                top: "50%",
                left: "8px",
                transform: "translateY(-50%)",
              }}
            />
          </div>
        </div>
      ),
      renderCell: (params) => (
        <CellWithTooltip value={params?.row?.requestedBy || "-"} />
      ),
    },
    // {
    //   field: "siteId",
    //   headerName: "Site Id",
    //   headerClassName: "table-header",
    //   flex: 1,
    //   renderCell: (params) => <CellWithTooltip value={params?.value || '-'} />,
    // },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      minWidth: 200,
      sortable: true,
      valueGetter: (value: any, row: any) => {
        const city = row?.city;
        const state = row?.state;
        const zipcode = row?.zipcode;
        // Check if city exists and add comma
        const cityWithComma = city ? city + ", " : "";
        // Check if state exists and add space
        const stateWithSpace = state ? state + " " : "";
        return `${cityWithComma}${stateWithSpace}${zipcode || ""}`;
      },
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
          <Typography
            variant="body2"
            sx={{ color: "#6F6F6F", fontSize: "14px" }}
          >
            {params.colDef.headerName}
          </Typography>
          <div className="position-relative" style={{ width: 170 }}>
            <input
              type="text"
              className="form-control border mt-1"
              style={{ textIndent: "24px", boxShadow: "none" }}
              placeholder="Search"
              //value={filter.location}
              defaultValue={filter.location}
              onChange={(event) => handleFilterChange(event, "location")}
              onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
            />
            <SearchIcon
              sx={{
                color: "#6F6F6F",
                position: "absolute",
                top: "50%",
                left: "8px",
                transform: "translateY(-50%)",
              }}
            />
          </div>
        </div>
      ),
      renderCell: (params) => <CellWithTooltip value={params.value || "-"} />,
    },
    ...(isRejectedUserClicked.current || isNeedReviewClicked.current
      ? [
          {
            field: "userStatus",
            headerName: "Status",
            flex: 1,
            minWidth: 200,
            sortable: true,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
              <>
                <div>
                  <Typography
                    variant="body2"
                    sx={{ color: "#6F6F6F", fontSize: "14px" }}
                  >
                    {params.colDef.headerName}
                  </Typography>
                  <div className="position-relative" style={{ width: 170 }}>
                    <Form.Select
                      style={{
                        cursor: "pointer",
                        borderBottomColor: "#D1D1D1",
                        marginTop: "4px",
                      }}
                      value={filter.userStatus}
                      className="form-control mb-1"
                      onChange={(event) =>
                        handleFilterChange(event, "userStatus")
                      }
                      onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                    >
                      <option value="">Select Status</option>
                      <option value="INVITED">Invited</option>
                      <option value="PENDING_REVIEW">Pending Review</option>
                      <option value="REJECTED">Rejected</option>
                    </Form.Select>
                  </div>
                </div>
              </>
            ),
            renderCell: (params) => (
              <div>
                <StatusDropdown
                  currentStatus={params.row.userStatus}
                  onStatusChange={(newStatus) => {}}
                  disableCondition={
                    CommonService.isUser() || CommonService.isAdmin()
                  }
                  allowedLabels={[]}
                />
                {params.row.userStatus === "REJECTED" && (
                  <Tooltip
                    title={`Rejection Note: ${params.row.rejectionNote}`}
                    arrow
                  >
                    <StickyNote2Icon
                      className="note-icon cursor-pointer"
                      style={{ color: "gray" }}
                    />
                  </Tooltip>
                )}
              </div>
            ),
          },
        ]
      : []),
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 200,
      headerClassName: "table-header border-0 my-2 h-100",
      renderCell: (params: any) => (
        <div onClick={(e) => e.stopPropagation()}>
          {isDeletedUserClicked.current && CommonService.isAdmin() && (
            <Tooltip title="Restore User" arrow>
              <IconButton onClick={() => handleRowClick(params)}>
                <ReplayIcon sx={{ transform: "rotate(-45deg)", fill: "#333333" }} />
              </IconButton>
            </Tooltip>
          )}
          {params.row.userStatus !== "INVITED" &&
            !isDeletedUserClicked.current &&
            (params.row.userStatus !== "PENDING_REVIEW" ||
              CommonService.isUser()) && (
              <Tooltip title="View user" arrow>
                <IconButton onClick={() => handleProfile(params.row.id)}>
                  <VisibilityOutlined />
                </IconButton>
              </Tooltip>
            )}
   {params.row.userStatus === "INVITED"  && (
            !isDeletedUserClicked.current && (
            // <IconButton onClick={(event) => handleResendClick(event, params)}>
            //     < SendOutlined />
            // </IconButton>
            <SingleReInvite
              reInvitedUser={params.row.email}
              companyId={params.row.companyId}
            />
    ))}
          {params.row.userStatus === "PENDING_REVIEW" &&
          !isDeletedUserClicked.current &&
            CommonService.isAdmin() && (
              // <IconButton onClick={(event) => handleApproveClick(event, params)}>
              //                         <FontAwesomeIcon icon={faUserCheck} />
              //                     </IconButton>
              <SingleApprove
                approveUser={[params.row]}
                displayIcon={true}
                reloadTable={reloadTable}
              />
            )}
          {params.row.userStatus === "PENDING_REVIEW" &&
          !isDeletedUserClicked.current &&
            CommonService.isAdmin() && (
              // <IconButton onClick={(event) => handleRejectClick(event, params)}>
              //     <FontAwesomeIcon icon={faUserTimes} />
              // </IconButton>
              <SingleReject
                rejectUser={[params.row]}
                displayIcon={true}
                reloadTable={reloadTable}
              />
            )}
               </div>
      ),
    },
  ];

  const userStatusColumn: GridColDef = {
    field: "userStatus",
    headerName: "Status",
    flex: 1,
    minWidth: 200,
    headerClassName: "table-header",
    renderCell: (params) => (
      <div>
        <StatusDropdown
          currentStatus={params.row.userStatus}
          onStatusChange={(newStatus) => {}}
          disableCondition={CommonService.isUser() || CommonService.isAdmin()}
          allowedLabels={[]}
        />
        {params.row.userStatus === "REJECTED" && (
          <Tooltip title={`Rejection Note: ${params.row.rejectionNote}`} arrow>
            <StickyNote2Icon
              className="note-icon cursor-pointer"
              style={{ color: "gray" }}
            />
          </Tooltip>
        )}
      </div>
    ),
  };

  // const columns: GridColDef[] = [
  //   ...commonColumns,
  //   ...(isNeedReviewClicked.current ? conditionalColumns : []),
  // ];

  const columns: GridColDef[] = useMemo(() => {
    if (isRejectedUserClicked.current || isNeedReviewClicked.current) {
      // Insert the userStatus column after the "location" column
      const locationIndex = commonColumns.findIndex(
        (col) => col.field === "location"
      );
      if (locationIndex !== -1) {
        return [
          ...commonColumns.slice(0, locationIndex + 1),
          userStatusColumn,
          ...commonColumns.slice(locationIndex + 1),
        ];
      }
    }

    return commonColumns;
  }, [isNeedReviewClicked.current, isRejectedUserClicked.current]);

  const handleRowClick = (params) => {
    console.log("this is my current status " + params.row.userStatus);

    //row clickable only when status is not invited and pending_review
    if (isDeletedUserClicked.current) {
      // if (selectionModel.length <= 0) {
        console.log("why");
        let singleSelectedRow = [];
        singleSelectedRow.push(params.row);
        setIsSingleClickMode(true);
        setSelectedRowsFromTable(singleSelectedRow);
      // } else setIsSingleClickMode(false);
      setForceOpen(true);
    } else if (params.row.userStatus !== "INVITED")
      handleProfile(params.row.id);
  };

  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);

  const handleSelectionModelChange = (newSelection: GridRowId[]) => {
    setSelectionModel(newSelection);
    const newSelectedRows = newSelection.map((id: GridRowId) => {
      return tableData.find((row: any) => row.id === id); // Assuming each row object has an 'id' property
    });
    setSelectionRowsFromTable(newSelectedRows);
    selectedRows.current = newSelectedRows;
    let flag: any = false;
    flag = selectedRows.current.some((user) => user.userStatus === "INVITED");
    disableAction.current = flag;
  };

  const [isLoading, setIsLoading] = useState(false);
  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  // Filter rows based on search query
  // const filteredRows = tableData?.filter(
  //   (row) =>
  //     row.email?.toLowerCase().includes(search?.toLowerCase()) ||
  //     row.companyName?.toLowerCase().includes(search?.toLowerCase()) ||
  //     row.firstName?.toLowerCase().includes(search?.toLowerCase()) ||
  //     row.lastName?.toLowerCase().includes(search?.toLowerCase()) ||
  //     row.phoneNumber?.toLowerCase().includes(search?.toLowerCase()) ||
  //     (row.firstName + " " + row.lastName)
  //       .toLowerCase()
  //       .includes(search?.toLowerCase())
  // );

  // Map filtered rows with unique IDs
  const tableDataWithIds = tableData?.map((rowData, index) => ({
    ...rowData,
  }));

  //redirect to the profile page
  const handleProfile = (id: any) => {
    CommonService.isAdmin()
      ? history.push("/master/user-detail/" + id)
      : history.push("/user/user-detail/" + id);
  };

  const handleBack = () => {
    console.log(currentPath);
    if (currentPath.startsWith("/master/my-warehouse/user-list/")) {
      history.push(`/master/company-dashboard/` + id);
    } else history.push(`/master/dashboard`);
  };

  const onPaginationModelChange = (newPaginationModel) => {
    setPage(newPaginationModel.page);
    setPageSize(newPaginationModel.pageSize);

    if (isActiveUserClicked.current) {
      fetchApprovedUserData(newPaginationModel.page, newPaginationModel.pageSize, filter, sortField, sortDirection);
    } else if (isDeletedUserClicked.current) {
      fetchDeletedUserData(newPaginationModel.page, newPaginationModel.pageSize, filter, sortField, sortDirection);
    } else if (isRejectedUserClicked.current) {
      showRejectedUsers(true, newPaginationModel.page, newPaginationModel.pageSize, filter, sortField, sortDirection);
    } else if (isNeedReviewClicked.current) {
      showSubmittedAndInvitedUsers(true, newPaginationModel.page, newPaginationModel.pageSize, filter, sortField, sortDirection);
    }
  };

  const handleSortChange = (sortModel) => {
    if (sortModel.length > 0) {
        const newSortField = sortModel[0].field;
        const newSortDirection = sortModel[0].sort; // "asc" or "desc" directly from DataGrid
        
        setSortField(newSortField);
        setSortDirection(newSortDirection);
    
        if (isActiveUserClicked.current) {
          fetchApprovedUserData(page, pageSize, filter, newSortField, newSortDirection);
        } else if (isDeletedUserClicked.current) {
          fetchDeletedUserData(page, pageSize, filter, newSortField, newSortDirection);
        } else if (isRejectedUserClicked.current) {
          showRejectedUsers(true, page, pageSize, filter, newSortField, newSortDirection);
        } else if (isNeedReviewClicked.current) {
          showSubmittedAndInvitedUsers(true, page, pageSize, filter, newSortField, newSortDirection);
        }
    }
  };

  return (
    <>
      <div className="usersList">
        <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center mb-2">
        <div className="d-flex align-items-center">
          {CommonService.isAdmin() && (
            <IconButton
              onClick={handleBack}
              aria-label="ArrowBackIcon"
              sx={{
                backgroundColor: "#FFF",
                mb: 1,
                "&:hover": { backgroundColor: "#FFF" },
              }}
            >
              <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
            </IconButton>
          )}
          {CommonService.isAdmin() && (
            <div className="header--title mb-2 me-2">
              <h1 className="title mb-0 ms-2">
                Users <i className="bi bi-heart-fill"></i>
              </h1>
            </div>
          )}
          </div>
          <div className="header--menu ms-auto mb-2">
            <div className="header--menu-items row d-flex">
              {/* <div className="col-auto input-container menu-items__search d-inline-flex mb-2 position-relative">
                    <input type="search" id="gsearch" name="gsearch" />
                    <img src={SearchIcon} alt="SearchIcon" className="icon position-absolute top-50 start-50 translate-middle" />
                </div> */}

              {/* <input placeholder="Search something..." className="input" name="text" type="text"></input> */}
               {selectionModel.length > 0 && !isDeletedUserClicked.current && !isRejectedUserClicked.current && ( 
              <div className="col-auto mb-2">
                <DeleteUser deleteUser={selectionModel} reloadTable={reloadTable} />
                {/* <SearchBar search={search} setSearch={setSearch} /> */}
              </div>
             )} 
              {/* <img src="../images/img_vert_line.svg" alt="vertline_one" className="h-[35px] md:w-full" /> */}
              
              <div className="col-auto menu-items__btnGroup btnGroup-border d-inline-flex mb-2 flex-wrap gap-2 border-end-0">
              
                {CommonService.isAdmin() && (
                  <InviteUser reloadTable={reloadTable} />
                )}

                <button
                  className="primary-btn d-inline-flex"
                  onClick={handleAddUser}
                >
                  <AddSharpIcon />
                  Add User
                </button>

                <div
                  className="btn-group user-activity-btn-grp gap-2 gap-md-0 flex-wrap"
                  role="group"
                  aria-label="Basic mixed styles example"
                >
                  <button
                    className={`white-outline-btn d-inline-flex justify-content-between align-items-center ms-1 pe-1 ${
                      isActiveUserClicked.current ? "bg-white" : ""
                    }`}
                    onClick={() => {
                      showActiveUsers(false);
                    }}
                  >
                    Active
                    <span
                      className={`review--number d-inline-flex align-items-center justify-content-center rounded-circle ms-2 ${
                        isActiveUserClicked.current
                          ? "bg-secondary-subtle"
                          : "bg-white"
                      }`}
                    >
                      {activeUserCount}
                    </span>
                  </button>

                  <button
                    className={`white-outline-btn d-inline-flex justify-content-between align-items-center pe-1 ${
                      isDeletedUserClicked.current ? "bg-white" : ""
                    }`}
                    onClick={() => {
                      showDeletedUsers(false);
                    }}
                  >
                    Deleted
                    <span
                      className={`review--number d-inline-flex align-items-center justify-content-center rounded-circle ms-2 ${
                        isDeletedUserClicked.current
                          ? "bg-secondary-subtle"
                          : "bg-white"
                      }`}
                    >
                      {deleteUserCount}
                    </span>
                  </button>

                  <button
                    className={`white-outline-btn d-inline-flex justify-content-between align-items-center pe-1  ${
                      isNeedReviewClicked.current ? "bg-white" : ""
                    }`}
                    onClick={() => {
                      showSubmittedAndInvitedUsers(false, page, pageSize, filter, sortField, sortDirection);
                    } }
                  >
                    {CommonService.isAdmin() ? "Need Review" : "Pending"}
                    <span
                      className={`review--number d-inline-flex align-items-center justify-content-center rounded-circle ms-2 ${
                        isNeedReviewClicked.current
                          ? "bg-secondary-subtle"
                          : "bg-white"
                      }`}
                    >
                      {reviewUserCount}
                    </span>
                  </button>

                  <button
                    className={`white-outline-btn d-inline-flex justify-content-between align-items-center pe-1  ${
                      isRejectedUserClicked.current ? "bg-white" : ""
                    }`}
                    onClick={() => {
                      showRejectedUsers(false, page, pageSize, filter, sortField, sortDirection);
                    } }
                  >
                    Rejected
                    <span
                      className={`review--number d-inline-flex align-items-center justify-content-center rounded-circle ms-2 ${
                        isRejectedUserClicked.current
                          ? "bg-secondary-subtle"
                          : "bg-white"
                      }`}
                    >
                      {rejectedUsersCount}
                    </span>
                  </button>
                </div>
              </div>
              {CommonService.isAdmin() &&
                tableDataWithIds?.length > 0 &&
                (isRejectedUserClicked.current ||
                  isActiveUserClicked.current ||
                  isNeedReviewClicked.current) && (
                  <>
                    
                  </>
                )}

              {CommonService.isAdmin() &&
                tableDataWithIds?.length > 0 &&
                isDeletedUserClicked.current && (
                  <>
                    <div className="col-auto menu-items__btnGroup d-inline-flex mb-2">
                      <RestoreUser
                        openPopUp={forceOpen}
                        isSingleClickMode={isSingleClickMode}
                        selectionModel={selectionRowsFromTable}
                        restoreUser={selectedRowsFromTable}
                        reloadTable={reloadTable}
                        setOpenPopUp={setOpenPopUp}
                      />
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      <div className="user-table-container">
        <div className="userTable">
          <DataGrid
            rows={tableDataWithIds}
            columns={commonColumns}
            className="data-grid"
            initialState={{
              pagination: {
                  paginationModel: { page, pageSize },
              },
            }}
            pagination
            paginationModel={{page, pageSize }}
            paginationMode="server"
            sortingMode="server"
            disableRowSelectionOnClick={true}
            rowCount={totalCount}
            pageSizeOptions={[10, 20, 50, 100]}
            localeText={localeText}
            checkboxSelection
            rowSelectionModel={selectionModel}
            onSortModelChange={handleSortChange}
            onPaginationModelChange={onPaginationModelChange}
            onRowSelectionModelChange={handleSelectionModelChange}
            onRowClick={handleRowClick}
            loading={isLoading} />
        </div>
      </div>
    </div>
    </>
  );
};

export default UserList;
