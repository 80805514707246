import React from 'react';
import { Typography } from "@mui/material"
import footerLogo from "../../assets/images/logo-horizontal-white-dog.svg"
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { Form } from "react-bootstrap";
import XIcon from '@mui/icons-material/X';
import facebook from "../../assets/images/icons/Facebook_logo.svg"
import FacebookFilled from "../../assets/images/icons/FacebookFilled.svg"

function LoginFooter() {
  return (
    <div className="footer-outer-wrapper">
        <div className="footer-wrapper">
            <div className="container">
                <div className="footer">
                   
                    <div className="row d-flex justify-content-center justify-content-lg-between align-items-center g-2">
                        
                        <div className="col-auto  me-1 me-lg-5 me-xl-1 px-0">
                            <div className="footer-right-part d-flex align-items-center flex-wrap justify-content-center">
                                <span className="text-nowrap me-2 ">
                                    <Typography variant="body2" sx={{ color: '#737376', fontSize: '14px', fontWeight: "600" }}>
                                    Home
                                    </Typography>
                                </span>
                                <span className="text-nowrap mx-2 ">
                                    <Typography variant="body2" sx={{ color: '#737376', fontSize: '14px', fontWeight: "600" }}>
                                    About
                                    </Typography>
                                </span>
                                <span className="text-nowrap mx-2 ">
                                    <Typography variant="body2" sx={{ color: '#737376', fontSize: '14px', fontWeight: "600" }}>
                                    Storage
                                    </Typography>
                                </span>
                                <span className="text-nowrap mx-2 ">
                                    <Typography variant="body2" sx={{ color: '#737376', fontSize: '14px', fontWeight: "600" }}>
                                    Aged Spirits
                                    </Typography>
                                </span>
                                <span className="text-nowrap mx-2 ">
                                    <Typography variant="body2" sx={{ color: '#737376', fontSize: '14px', fontWeight: "600" }}>
                                    New Fill
                                    </Typography>
                                </span>
                                <span className="text-nowrap mx-2 ">
                                    <Typography variant="body2" sx={{ color: '#737376', fontSize: '14px', fontWeight: "600" }}>
                                    Contact
                                    </Typography>
                                </span>
                            </div>
                        </div>
                        <div className="col-auto  ms-1 ms-lg-5 ms-xl-1 px-0">
                        <div className="footer-right-part d-flex align-items-center justify-content-center">
                                <div className="me-2">
                                    <Typography variant="body2" sx={{ color: '#B7A577', fontSize: '14px', fontWeight: 700 }}>
                                        FOLLOW US
                                    </Typography>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                    <span style={{backgroundColor: '#71828A', borderRadius:"50%", padding:"2px", height:"35px", width:"35px", display:"flex", alignItems:"center", justifyContent:"center"}}> <img src={FacebookFilled} alt="footerLogo" height={25}/></span>
                                    <span style={{backgroundColor: '#71828A', borderRadius:"50%", padding:"2px", height:"35px", width:"35px", display:"flex", alignItems:"center", justifyContent:"center"}}> <XIcon sx={{ color:"#FDFCFA", marginInline: '4px' }} /></span>
                                    <span style={{backgroundColor: '#71828A', borderRadius:"50%", padding:"2px", height:"35px", width:"35px", display:"flex", alignItems:"center", justifyContent:"center"}}> <InstagramIcon sx={{ color:"#FDFCFA", marginInline: '4px' }} /></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 px-0">
                            
                            <span className="d-flex justify-content-center align-items-center flex-wrap text-white gap-1 gap-md-3">
                                <Typography variant="body2" sx={{ color: '#737376', fontSize: '14px', fontWeight: "400" }}>
                                © White Dog Trading Post. All rights reserved. 
                                </Typography>
                                <div className='d-flex align-items-center flex-wrap gap-3'>
                                <Typography variant="body2" sx={{ color: '#737376', fontSize: '14px', fontWeight: "400" }}>
                                Terms
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#737376', fontSize: '14px', fontWeight: "400" }}>
                                Privacy
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#737376', fontSize: '14px', fontWeight: "400" }}>
                                Cookie Policy
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#737376', fontSize: '14px', fontWeight: "400" }}>
                                Accessibility
                                </Typography>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
  )
}

export default LoginFooter