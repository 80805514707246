import axios, { AxiosInstance, AxiosResponse } from 'axios';
import Cookies from 'universal-cookie';
import { CommonService } from './CommonService';


const cookies = new Cookies();

// Create an Axios instance without baseURL
const axiosInstance: AxiosInstance = axios.create();

// Add a request interceptor to attach the token to each request
axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve token from cookies
    const token = cookies.get('authToken');

    // If token exists, add it to the Authorization header
    if (token && config.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle 401 (Unauthorized) responses
axiosInstance.interceptors.response.use(
  (response) => {
    // If response is successful, return it directly
    return response;
  },
  (error) => {

    // If response status is 401 (Unauthorized)
    if (error.response && error.response.status === 401) {
      console.log('Unauthorized access detected. Handle it here.');
      if (window.location.pathname !== '/login') {
        window.location.href = '/login';
      }
      CommonService.Toast.fire({
        title: 'Session Expired',
        icon: 'error',
      });
    }

    // Pass the error to the next catch block
    return Promise.reject(error);
  }
);

// Define a generic type for API response data
type ApiResponse<T> = Promise<AxiosResponse<T>>;

// Define methods for making HTTP requests
const AxiosApiService = {
  // GET method
  get: <T>(url: string): ApiResponse<T> => {
    return axiosInstance.get<T>(url);
  },

  getWithParams: <T>(url: string ,params : any): ApiResponse<T> => {
    return axiosInstance.get<T>(url,params);
  },

  postWithSearchParams: <T>(url: string, params: any, data: any, config: any = {}): ApiResponse<T> => {
    return axiosInstance.post<T>(url, data, {...config, params} );
  },

  // POST method
  post: <T>(url: string, data: any): ApiResponse<T> => {
    return axiosInstance.post<T>(url, data);
  },

  // PUT method
  put: <T>(url: string, data: any): ApiResponse<T> => {
    return axiosInstance.put<T>(url, data);
  },

  // DELETE method
  delete: <T>(url: string): ApiResponse<T> => {
    return axiosInstance.delete<T>(url);
  },

  // DELETE method
  getZip: <T>(url: string): ApiResponse<T> => {
    return axiosInstance.get<T>(url, { responseType: 'blob' });
  },

  postBlob: <T>(url: string, body: any): ApiResponse<T> => {
    return axiosInstance.post<T>(url, body, { responseType: 'blob' });
  },

  postBlobWithSearchParams: <T>(url: string, params: any, data: any, config: any = {}): ApiResponse<T> => {
    return axiosInstance.post<T>(url, data, { ...config, params, responseType: 'blob' });
  },

  getBlobWithSearchParams: <T>(url: string, params?: Record<string, any>): ApiResponse<T> => {
    return axiosInstance.get<T>(url, { 
      responseType: 'blob',
      params // Pass query parameters if provided
    });
},

  getBlob: <T>(url: string): ApiResponse<T> => {
    return axiosInstance.get<T>(url, { 
      responseType: 'blob' 
    });
  }
};

export default AxiosApiService;
