import { ReactNode, useEffect, useRef, useState } from "react";
import {  Hits, SortBy, useInstantSearch } from "react-instantsearch";
import { useHistory, useLocation } from "react-router";
import { CommonService } from "../../Service/CommonService";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import {  Tooltip } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { OrderApiService } from "../../Service/OrderApiService";
import Cookies from "universal-cookie";
import SampleOrder from "../popups/SampleOrder";

interface SearchContainerProps {
  children?: ReactNode;
}

const HitsTableContainer: React.FC<SearchContainerProps> = ({ children }) => {
  const { status } = useInstantSearch();
  const userId = new Cookies().get('userId');
  const [wishlistItems, setWishlistItems] = useState<any[]>([]);
  const [soldBarrels, setSoldBarrels] = useState([]);
  const [showHits, setShowHits] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const collectionName = process.env.REACT_APP_TYPESENSE_COLLECTIONNAME;
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const location = useLocation();
  const currentPath = location.pathname;
  const companyId = new Cookies().get('companyId');
  
  useEffect(() => {

    let filters = userId;
    if (currentPath === '/user/inventories') {
        filters = userId ? [`company_id:!=${companyId}`] : ['company_id'];
    } else if (currentPath === '/user/my-inventory') {
        filters = userId ? [`company_id:=${companyId}`] : ['company_id'];
    } else if (currentPath === '/user/wish-list') {
        filters = wishlistItems.length > 0 ? [`owner_id:!=${userId}`, `(inventory_id:${wishlistItems.join(' || inventory_id:')})`] : [`owner_id:!=${userId}  &&  inventory_id:null`];
        getAllSoldInventoryWishList();
    }

    // Generate dynamic filter string
    const filterValue = Array.isArray(filters) ? filters?.join(' && ').replace(/\\/g, '') : filters;
    setGlobalFilter(filterValue);

}, [location.pathname, wishlistItems]);

const getAllSoldInventoryWishList = async() => {
    try {
        const response = await OrderApiService.getAllSoldInventoryWishList(CommonService.getUserId());
        setSoldBarrels(response.data);
    } catch (error) {
        console.error('Error while fetching barrel availability:', error);
        return false; // Return false in case of an error
    }
};

  useEffect(() => {
    getUserWishlist();
}, []);

  const addToWishList = async (itemId: string, productName: string) => {
    const wishListRequest = {
        inventoryId: itemId,
        userId: userId,
        inventoryTitle: productName
    };

    try {
        const response: any = await OrderApiService.addToWishlist(wishListRequest);
        getUserWishlist();
    } catch (error) {
        console.error('Error while adding wishlist data:', error);
    }
};

const getUserWishlist = async () => {
  try {
      const response: any = await OrderApiService.getUserWishlist(userId);

      setWishlistItems(response.data);
  } catch (error) {
      console.error('Error while fetching wishlist:', error);
  }
};

const removeFromWishList = async (itemId: string) => {
  const wishListRequest = {
      inventoryId: itemId,
      userId: userId,
  };

  try {
      const response: any = await OrderApiService.removeFromWishlist(wishListRequest);
      getUserWishlist();
  } catch (error) {
      console.error('Error while removing wishlist data:', error);
  }
};

  useEffect(() => {

    if(status !== "loading" && status !== "stalled")
      setShowHits(true);
    else
      setShowHits(false);
  
  }, [status]);

  return showHits ? (

    <div className="table-container">
    <div className="table--header" ref={headerRef}>
        <table className="table table-striped mb-0">
            <thead className="thead-dark">
                <tr>
                    <th>
                        <div className='d-flex justify-content-between'>
                            <span className='thead__heading'>Spirit</span>
                            <SortBy
                                items={[
                                    { label: 'asc', value: `${collectionName}/sort/ttb_type:asc` },
                                    { label: 'desc', value: `${collectionName}/sort/ttb_type:desc` },
                                ]}
                                classNames={{
                                    root: 'MyCustomSortBy',
                                    select: 'MyCustomSortBySelect MyCustomSortBySelect--subclass',
                                }}
                            />
                        </div>
                    </th>

                    <th>
                        <div className='d-flex justify-content-between'>
                            <span className='thead__heading text-nowrap'>Price $</span>
                            <SortBy
                                items={[
                                    { label: 'asc', value: `${collectionName}/sort/price:asc` },
                                    { label: 'desc', value: `${collectionName}/sort/price:desc` },
                                ]}
                            />
                        </div>
                    </th>
                    <th>
                        <div className='d-flex justify-content-between'>
                            <span className='thead__heading text-nowrap'>Spirit Age</span>
                            <SortBy
                                items={[
                                    { label: 'asc', value: `${collectionName}/sort/fill_date_timestamp:asc` },
                                    { label: 'desc', value: `${collectionName}/sort/fill_date_timestamp:desc` },
                                ]}
                            />
                        </div>
                    </th>
                    <th>
                        <div className='d-flex justify-content-between'>
                            <span className='thead__heading text-nowrap'>Proof</span>
                            <SortBy
                                items={[
                                    { label: 'asc', value: `${collectionName}/sort/proof:asc` },
                                    { label: 'desc', value: `${collectionName}/sort/proof:desc` },
                                ]}
                            />
                        </div>
                    </th>
                    <th>
                        <div className='d-flex justify-content-between'>
                            <span className='thead__heading'>Action</span>
                        </div>
                    </th>
                </tr>
            </thead>
        </table>
    </div>
    <div className="table--body" ref={bodyRef}>
        <table className="table table-striped table-bordered mb-0">
            <tbody>
                <Hits style={{ cursor: 'pointer' }} classNames={{
        emptyRoot: "my-empty-class",
      }} hitComponent={(props) => (<HitItem {...props} addToWishList={addToWishList} removeFromWishList={removeFromWishList} wishlistItems={wishlistItems} />)} />
            </tbody>
        </table>
    </div>
</div>

  ) : (
    <div></div> // Or a loader if preferred
  );
  
};


const HitItem: React.FC<{ hit: any, addToWishList: (itemId: string, productName: string) => void, removeFromWishList: (itemId: string) => void, wishlistItems: any[] }> = ({ hit, addToWishList, removeFromWishList, wishlistItems }) => {
    const location = useLocation();
    const currentPath = location.pathname;
    const history = useHistory();
    const [isInWishlist, setIsInWishlist] = useState(wishlistItems.includes(hit.inventory_id));
    const [description, setDescription] = useState("");

    useEffect(() => {
        console.log("hit item");
    }, []);

    const handleBuyNowClick = () => {
        // Redirect to another page with the hit id
        console.log(hit.id);
        history.push(`/user/product-details/${hit.id}`);
    };

    const manageWishList = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        isInWishlist ? removeFromWishList(hit.inventory_id) : addToWishList(hit.inventory_id,hit.productName);
        setIsInWishlist(prev => !prev);
    };

    const stopPropagation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    };

    const { years, months } = CommonService.calculateAge(hit.fill_date_timestamp);

    return (

        <>
            <table className="table inventory__table--data table-hover align-middle mb-0">
                <tbody className="thead-dark">
                    <tr className='border-bottom border-secondary-subtle' onClick={handleBuyNowClick}>
                        <td>
                            <div className='d-flex flex-column'>
                                <span>{hit.ttb_type}</span>
                                <span className='text-body-tertiary' title={hit.mash_bill}>
  {hit.mash_bill.length > 18 ? `${hit.mash_bill.substring(0, 18)}...` : hit.mash_bill}
</span>
                            </div>
                        </td>
                        {/* <td>
                            <div className='d-flex flex-column'>
                                <span>{hit.facility}</span>
                                <span className='text-body-tertiary'>{hit.state}</span>
                            </div>
                        </td> */}
                        <td>
                            <div className='d-flex flex-column'>
                                <CurrencyFormatter amount={hit.price} locale="en-US" currency="USD" />
                                <span className='text-body-tertiary'>Per Barrel</span>
                            </div>
                        </td>
                        <td>
                            <div className='d-flex flex-column'>
                                <span>{years} years {months} months</span>
                                {/* <span>{hit.fill_date}</span> */}
                            </div>
                        </td>
                        <td>
                            <div className='d-flex flex-column'>
                                <span>{hit.proof}</span>
                                <span className='text-body-tertiary'>{hit.proof_unit.toLowerCase()
                                    .split(' ')
                                    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(' ')}</span>
                            </div>
                        </td>
                        {(currentPath === '/user/inventories' || currentPath === '/user/wish-list') && (
                            <td>
                                <div className="d-flex align-items-center">
                                    <div className='manageWishList me-2' onClick={manageWishList}>
                                        {isInWishlist ? (
                                            <Tooltip title="Remove from Wishlist" arrow>
                                                <FavoriteIcon sx={{ cursor: 'pointer', color: 'red' }} />
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Add to Wishlist" arrow>
                                                <FavoriteBorderIcon sx={{ cursor: 'pointer', color: '#D9D9D9' }} />
                                            </Tooltip>
                                        )}
                                    </div>
                                    <div className="request__sample ms-1" onClick={stopPropagation}>
                                        <SampleOrder
                                            id={hit.id}
                                            inventoryName={hit.product_name}
                                            text={false}
                                        />
                                    </div>
                                </div>
                            </td>
                        )}
                    </tr>
                </tbody>
            </table>
        </>
    );
}

export default HitsTableContainer;
