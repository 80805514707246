
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Service } from '../../Service/ApiService';
import { CommonService } from '../../Service/CommonService';
import Spinner from '../Common/Spinner';
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

interface ChildProps {
    deleteUser: any;
    reloadTable: any;
}

const DeleteUser: React.FC<ChildProps> = ({ deleteUser, reloadTable }) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedUserName, setSelectedUserName] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (deleteUser.length) {
            let c = deleteUser.map((user: { email: any; }) => { return user.email })
            setSelectedUserName(c);
        }
    }, [deleteUser]);

    const handleSubmit = () => {
        setIsLoading(true);
        if (deleteUser.length) {
            Service.removeUserProfile(deleteUser,true)
                  .then(response => {
                    setIsLoading(false);
                    CommonService.Toast.fire({
                      title: "User deleted successfully !",
                      icon: 'success',
                    });
                    reloadTable();
                  })
                  .catch(error => {
                    setIsLoading(false);
                    console.log(error);
                  });
        }
        setIsLoading(false);
        handleClose();
    }

    return (
        <>
        {/*<button onClick={handleShow} className="white-outline-btn d-inline-flex justify-content-between align-items-center ms-1">
                  <DeleteOutlinedIcon sx={{ me: 2, color: "#1010104D" }} />
                  <button  disabled={deleteUser.length === 0} style={{border:'none', background:'transparent',
                cursor: deleteUser.length === 0 ? 'not-allowed' : 'pointer'
            }}> Delete </button>
                  <span className="review--number text-secondary d-inline-flex align-items-center justify-content-center rounded-circle bg-white ms-2">
                    {deleteUser.length}
                  </span>
                </button>*/}

            <button className="primary-btn d-inline-flex me-1" onClick={handleShow}>
                <DeleteOutlinedIcon sx={{ me: 2 }} /> Delete
                    <span className="review--number text-secondary d-inline-flex align-items-center justify-content-center rounded-circle bg-white ms-2">
                        {deleteUser.length}
                  </span>
            </button>
            <Modal show={show} onHide={handleClose} className='modal__wd modal__wd--submitSellReq'>
                <Modal.Header className='flex-column align-items-start'>
                    <Modal.Title>Delete User</Modal.Title>
                    <p className="modal-text">
                        Are you sure, you want to delete this user?
                    </p>
                </Modal.Header>
                <Modal.Footer className='justify-content-center bg-transparent'>
                    <div className="btn-group row d-flex justify-content-between w-100">
                        <div className="col-6 ps-0">
                            <Button className="secondary-btn w-100" onClick={handleClose} disabled={isLoading}>
                                No
                            </Button>
                        </div>
                        <div className="col-6 pe-0">
                            <Button className="primary-btn w-100" onClick={handleSubmit} disabled={isLoading}>
                              {isLoading && <Spinner/>}  Yes
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DeleteUser;