import { Redirect, Route } from "react-router-dom";
import  Cookies from 'universal-cookie';
import { CommonService } from "../Service/CommonService";
import { CookieService } from "../Service/CookieService";
import { Service } from "../Service/ApiService";

import notificationWebSocketService from "../Websocket/notificationWebSocketService";

const PrivateUserRoute = (props:any) => {
  const cookies = new Cookies();
  const token = cookies.get('authToken');

  const isAuthenticated = async () => {

    //check user is authenticated or not
    if(token!== null && token!== undefined && CommonService.isUser()) {
        return true;
    }

    //logout user
    try{
        await Service.logout();

    } catch(error){
        console.log(error);
    }

    //remove the token from cookies and disconnect the web socket connection
    CookieService.removeAuthToken();
    notificationWebSocketService.disconnect();
    
    return false;

  }

  return <>{isAuthenticated() ? <Route {...props} /> : (<Redirect to="/login" />)}</>;
};

export default PrivateUserRoute;